import styled from "styled-components";
import { Button } from 'semantic-ui-react'

export const Container = styled.header`
  width: calc(100% - 45px);
  height: 60px;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.1);
  background: #fff;
  display: flex;
  justify-content: space-between;
  top: 0;  
  z-index: 1000;
  padding: 10px;
  position: fixed;
  
  @media (min-width: 300px) and (max-width: 1024px) {
    position: fixed;
    height: 45px;
    width: 100%;
  }

  .hamburger-inner, 
  .hamburger-inner:before, 
  .hamburger-inner:after{
    background : #000 !important;
    height: 2px;
  }

  h1 {
    font-size: ${props => props.theme.fontSize.large};
    align-self: center;
    margin-bottom: 0;
  }

  @media (max-width: 1024px) {
    h1 {
      font-size: ${props => props.theme.fontSize.medium};
      margin-left: 50px;
    }
  }


  ${props => props.menuAberto && `width: calc(100% - 350px);`}
`



export const LogoContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100px;
  height: 70px;
  margin: 2.5px 0;
  position: absolute;
  right: ${props => props.isMenuOpen ? '360px' : '50px'};
  top: 0;
  overflow: hidden;

  @media (min-width: 300px) and (max-width: 1024px) {
    left: 10px;
    height: 40px;
    width: 40px;
  }
  
  img {
    max-width: 100%;
    max-height: 100%;
  }
`

export const ContainerDireito = styled.div`
  display: flex;
  align-items: center;
`

export const PontoContainer = styled.button`
  border: none; 
  background: #fff;
  cursor: pointer;
  display: flex;
  gap: 3px;

  span:first-child {
    font-weight: bold;
  }

  @media (min-width: 300px) and (max-width: 1024px) {
    display: none;
  }
`


export const MenuButton = styled(Button)`
  background: #fff !important;
  font-size: ${props => props.theme.fontSize.large} !important;
  font-weight: bold;
  display: none !important;
  color: #000 !important;
  padding:0  !important;

  @media (min-width: 300px) and (max-width: 1024px) {
    display: block !important;
  }

`