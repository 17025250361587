import React, { useState } from "react";
import Alert from "../components/alert/Alert";

import { ToastContainer, toast } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';

export const ShowMessageContext = React.createContext({
  show: () => { }
});

export const ShowMessageProvider = (props) => {
  const [showAlert, setShowAlert] = useState(false);
  const [textAlert, setTextAlert] = useState("");

  function show(text, error) {
    if (error)
      toast.error(text, { autoClose: 10000 });
    else
      toast.success(text, { autoClose: 10000 })
    setShowAlert(true);
    setTextAlert(text);
  }

  function close() {
    setShowAlert(false);
    setTextAlert("");
  }

  return (
    <ShowMessageContext.Provider value={{ show }}>
      {showAlert && <ToastContainer />}
      {props.children}
    </ShowMessageContext.Provider >
  )
}