import React, { useEffect, useState } from 'react';
import { Container, Header, HeaderOptionsContainer, LabelContainer, ListContainer, ListItem, TitleContainer } from './style';
import { Icon } from 'semantic-ui-react'
import { FilterButton } from '../style';
import aux from '../../../utils/auxiliar';

export default function TotalizerPicker({ 
  closeModal, 
  onClick, 
  totalizers, 
  showDatePicker, 
  showFilterModal, 
  filteredDate 
}){

  const [animate, setAnimate] = useState(false);

  useEffect(() => {
    setAnimate(true);
  }, []);

  function handleItemClick(item){
    onClick(item);
    closeModal();
  }

 
  return (
    <Container animate={animate}>
      <Header>
        

        <HeaderOptionsContainer>
          <p>{filteredDate||aux.getToday()}</p>
          <FilterButton onClick={showFilterModal}>
            <Icon name={'filter'}/>
          </FilterButton>

          <FilterButton onClick={showDatePicker}>
            <Icon name={'calendar'}/>
          </FilterButton>
        </HeaderOptionsContainer>
      </Header>

      <TitleContainer>
        <h2>Monitoramento de </h2>
        <h1>Funcionários</h1>
      </TitleContainer>

      
      <ListContainer>
        {totalizers.map(item => (
          <ListItem onClick={() => handleItemClick(item.enumApp)}> 
            <h3>{item.total}</h3> 
            <p>{item.descricao}</p>
            <Icon name={'chevron right'}/>
          </ListItem>
        ))}
      </ListContainer>
    </Container>
  )
}