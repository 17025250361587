import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  border-radius: 2px;
  padding: 0 5px;
  background: var(--azul);
  color: #fff;
  p {
    margin: 0;
  }

  :hover {
    cursor: pointer;
    opacity: .5;
  }
  
`

export const IconContainer = styled.a`
 i {
    color: #fff;
  }
`