import styled from "styled-components";
import { DefaultCard, OptionCard } from "../../global/style";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 25px; 
  width: 100%;
  padding: 0 30px;
`

export const TotalizersContainer = styled.div`
  display: flex;
  align-items: flex-end;
  grid-gap: 10px;
  justify-content: center;
  height: 125px;

  z-index: 999;
  background: #f3f5f9;
  @media(max-width: 1440px){
    width: 100%;
  }

  @media(max-width: 920px){
    display: none;
  }
`


export const Totalizer = styled(OptionCard)`
  width: 350px;

  h1 {
    margin-bottom: 5px;
  }

  p {
    margin: 0;
  }

  a.topRight {
    position: absolute;
    top: 5px;
    right: 5px;
    color: #fff;
    text-decoration: none;
  }

 
`

export const ListContainer = styled.ul`
  list-style-type: none;
  display: grid;
  grid-template-columns: ${props => props.numberOfItems > 2? 'repeat(3, 1fr)': `repeat(${props.numberOfItems}, 1fr)`};
  grid-gap: 20px;
  justify-items: center;
  align-items: center;
  padding: 0;
  width: 100%;

  @media(max-width: 1440px){
    grid-template-columns: 1fr 1fr;
  }

  @media(max-width: 920px){
    width: 630px;
    grid-template-columns: 1fr;
  }

  @media(max-width: 645px){
    width: 100%;
  }

`

export const CardContainer = styled.li`
  width: 100%;

  @media(max-width: 920px){
    width: 100%;
  }
  
`

export const Card = styled(DefaultCard)`
  height: 510px;
  padding: 0 10px;
  h6 {
    margin: 15px 0 10px;
    font-weight: 500;
    font-size: ${props => props.theme.fontSize.smallernormal};
  }
`


export const FilterContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`


export const OptionsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 30px;

  @media(max-width: 1440px){
    justify-content: center;
  }

  @media(max-width: 920px){
    width: 100%;

    > div, button {
      display: none;
    }
  }
`

export const SearchInput = styled.input`
  width: 450px;
  padding: 5px 10px;
  align-self: flex-end;
  @media(max-width: 920px){
    width: 630px;
    align-self: center;
  }
`

export const FilterButton = styled.button`
  border: none;
  background: none;
`

export const MobileTotalizerContainer = styled.div`
  display: none;
  width: 100%;
  max-width: 630px;
  align-items: center;

  @media(max-width: 920px){
    display: flex;
    flex-direction: column;

  }
`

