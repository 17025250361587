import React, { useEffect, useState, useRef, useCallback } from 'react';
import APIMonitoramento from '../../API/APIMonitoramento';
import { OptionCard } from '../../global/style';
import { CardContainer, Container, FilterButton, FilterContainer, ListContainer, MobileTotalizerContainer, OptionsContainer, SearchInput, Totalizer, TotalizersContainer } from './style';
import { orderBy } from "lodash"
import { Icon } from 'semantic-ui-react'

import Card from './card';
import Spinner from '../../components/spinner/Spinner';
import Loading from '../../components/loading/Loading';
import FilterModal from './filtermodal';
import DatePickerModal from './datepickermodal';
import FilterLabel from '../../components/filterLabel';
import TotalizerPicker from './totalizerpicker';


export default function Monitoramento(){
  const [monitoringList, setMonitoringList] = useState([]);
  const [totalizers, setTotalizers] = useState([]);
  const [companyList, setCompanyList] = useState([]);

  const [originalList, setOriginalList] = useState([]);
  const [listToShow, setListToShow] = useState([]);
  const [paginatedList, setPaginedList] = useState([]);
  const [nextPageToShow, setNextPageToShow] = useState(0);

  const [filteredCompany, setFilteredCompany] = useState(null);
  const [filteredDate, setFilteredDate] = useState(null);

  const [selectedStatus, setSelectedStatus] = useState(0);

  const [isPageLoading, setIsPageLoading] = useState(false);
  const [isListLoading, setIsListLoading] = useState(false);

  const [isFilterModalVisible, setIsFilterModalVisible] = useState(false);
  const [isDatePickerModalVisible, setIsDatePickerModalVisible] = useState(false);
  const [isTotalizerPickerVisible, setIsTotalizerPickerVisible] = useState(false);
  const colorOptions = ['#2991D6','#438496','#d1701c','#DC3545', '#18a4cb', '#5e35dc', "#5d4fa9"];


  const ref = useRef();

  useEffect(() => {
    loadTotalizers();
    
    window.addEventListener('scroll', handleScroll);
    window.addEventListener('resize', handleResize)

    return () => window.removeEventListener('scroll', handleScroll);

  }, []);


  useEffect(() => {
    if(selectedStatus) loadList(selectedStatus);
  }, [selectedStatus]);


  useEffect(() => {
     if(nextPageToShow && paginatedList[nextPageToShow]) showNextPage();
  }, [nextPageToShow]);


  useEffect(() => {
    if (totalizers.length > 0){
      loadTotalizers(filteredDate);
    } 
  }, [filteredCompany]);

  useEffect(() => {
    if (totalizers.length > 0){
      loadTotalizers(filteredDate);
    } 
  }, [filteredDate]);


  useEffect(() => {

  }, [totalizers])


  async function loadTotalizers(dateToFilter){
    try{
      setIsPageLoading(true);

      let result;
      if(monitoringList.length > 0 && !dateToFilter){
        result = monitoringList;
      }else{
        result = await APIMonitoramento.getListaMonitoramento(dateToFilter);
      }

      if(result.err){
        return;
      }


      const groupedList = groupMonitoringListById(result, 'qtd', 'enumApp');
      const orderedList = orderBy(groupedList, ['total'], ['desc']);

      for(const [index, item] of orderedList.entries()){
        item.color = colorOptions[index];
      }
      setTotalizers(orderedList);
      
      
      const fullScreen = window.innerWidth > 920;

      if(fullScreen){
        setSelectedStatus(orderedList[0].enumApp);      
        loadList(orderedList[0].enumApp);
      }
      
      if(!monitoringList) setMonitoringList(result);

      if(companyList.length === 0) {
        let companies = getDistinctCompaniesFromTotalizers(result);
        setCompanyList(companies);
      }
    }catch(e){
      console.log(e)
    }finally{
      setIsPageLoading(false);
    }
  }


  async function loadList(status){
    try{
      setIsListLoading(true);
      const params = {
        enum: status,
        selectedCompany: filteredCompany,
        date: filteredDate
      }
      const result = await APIMonitoramento.getDetailMonitoramento(params);
      if(result.err){
        return;
      }

      const paginatedList = paginateList(result);
      setPaginedList(paginatedList);
      
      const firstPage = paginatedList[0];
      setOriginalList(firstPage);
      setListToShow(firstPage);

      

    }catch{
      
    }finally{
      setIsListLoading(false);
    }
  }


  

  function showNextPage(){
    setOriginalList(listToShow.concat(paginatedList[nextPageToShow]));
    setListToShow(listToShow.concat(paginatedList[nextPageToShow]));
  }


  function paginateList(rawList){
    let list = [];
    let page = [];

    for(let item of rawList){
      if(page.length < 10){
        page.push(item);
      }else{
        list.push(page);
        page = [];
        page.push(item);
      }
    }

    list.push(page);
    return list;

  }

  function groupMonitoringListById(monitoringList, totalKey, identifierKey){
    let totalizers = [];

    for (let monitoringItem of monitoringList) {
      if (filteredCompany === monitoringItem.empresa || !filteredCompany) {
        const index = totalizers.findIndex(
          totalizer => totalizer[identifierKey] === monitoringItem[identifierKey]
        );

        const itemExistsInTotalizers = index !== -1;

        if (itemExistsInTotalizers) {
          totalizers[index] = {
            ...totalizers[index],
            total: totalizers[index].total + monitoringItem[totalKey]
          }
        } else {
          totalizers.push({
            [identifierKey]: monitoringItem[identifierKey],
            descricao: monitoringItem.descricao,
            total: monitoringItem[totalKey]
          })
        }
      }
    }

    return totalizers;
  }

  function handleOptionClick(status){
    if(!isListLoading) setSelectedStatus(status);
  }


  const getDistinctCompaniesFromTotalizers = (monitoringList) => {
    let companies = [];
    for (let monitoringItem of monitoringList) {
      const companyIsNotInArray = !companies.find(company => company.value === monitoringItem.empresa);
      if (companyIsNotInArray) {
        companies.push({
          value: monitoringItem.empresa,
          label: monitoringItem.nomeEmpresa,
        })
      }
    }

    return companies;
  }




  function handleScroll(){
    const bottom = (window.innerHeight + window.scrollY) === ref.current.offsetHeight + 100;
    
    if(bottom){
      setNextPageToShow(state => state + 1);
    }
  }

  function handleResize(){
    if(window.innerWidth > 920 && true){

    }
  }

  const handleSearch = useCallback(event => {
    const search = event.target.value;
   
    if(search.length === 0) {
      setListToShow(originalList);
      return;
    }

    let result = [];

    for(const item of originalList){
      const { funcionario, local } = item;
      if (funcionario.nome.toUpperCase().includes(search.toUpperCase())
        || funcionario.re.indexOf(search) !== -1
        || local.nomeCliente.toUpperCase().indexOf(search.toUpperCase()) !== -1
        || local.nome.toUpperCase().indexOf(search.toUpperCase()) !== -1
        || local.endereco.logradouro.toUpperCase().indexOf(search.toUpperCase()) !== -1
        || funcionario.cargo.toUpperCase().indexOf(search.toUpperCase()) !== -1
        || funcionario.cargoFunc.toUpperCase().indexOf(search.toUpperCase()) !== -1
        || funcionario.situacao.toUpperCase().indexOf(search.toUpperCase()) !== -1
        || funcionario.tpCobertura.toUpperCase().indexOf(search.toUpperCase()) !== -1
      ) {
        result.push(item);
      }
    }

    setListToShow(result);
    
  });

  
  
  function showFilterModal(){
    setIsFilterModalVisible(true);
  }

  function closeFilterModal(){
    setIsFilterModalVisible(false);
  }

  function filterByCompany(id){
    setFilteredCompany(id);
    setIsFilterModalVisible(false);
  }

  function clearCompanyFilter(){
    setFilteredCompany(null);
  }




  function showDatePicker(){
    setIsDatePickerModalVisible(true);
  }

  function closeDatePicker(){
    setIsDatePickerModalVisible(false);
  }



  function filterByDate(rawDate){
    const date = new Date(rawDate);
    const formattedDate = `${date.getDate().toString().padStart(2,'0')}/${(date.getMonth() + 1).toString().padStart(2,'0')}/${date.getFullYear()}`;
    
    setFilteredDate(formattedDate);
    setIsDatePickerModalVisible(false);
  }


  function clearDateFilter(){
    setFilteredDate(null);
  }


  function showTotalizerPicker(){
    setSelectedStatus(null);
    setListToShow([]);
  }

  function closeTotalizerPicker(){
    setIsTotalizerPickerVisible(false);
  }



  const selectedTotalizer = totalizers.length > 0 ? totalizers.find(item => item.enumApp === selectedStatus) : null;
  return(
    <>
    <Loading show={isPageLoading} />
    {isFilterModalVisible && 
      <FilterModal 
        companyList={companyList}
        currentSelectedCompany={filteredCompany}
        applyFilter={filterByCompany}
        closeModal={closeFilterModal}
      />
    }

    {isDatePickerModalVisible && 
      <DatePickerModal 
        applyFilter={filterByDate} 
        currentSelectedDate={filteredDate}
        closeModal={closeDatePicker}
      />
    }

    {!selectedStatus && <TotalizerPicker 
      totalizers={totalizers}
      closeModal={closeTotalizerPicker}
      onClick={handleOptionClick}
      showFilterModal={showFilterModal}
      showDatePicker={showDatePicker}
      filteredDate={filteredDate}
    />}

    {!isPageLoading &&
    <Container ref={ref}>

      <FilterContainer>
        <OptionsContainer>
          {filteredCompany  ? 
          <FilterLabel 
            label={companyList.find(item => item.value === filteredCompany).label}
            onClick={showFilterModal}
            onIconClick={clearCompanyFilter}
          /> : 
          <FilterButton onClick={showFilterModal} style={{marginTop: 2}}>
            <Icon name={'filter'}/>
          </FilterButton>}

          {filteredDate ? 
          <FilterLabel 
            label={filteredDate}
            onClick={showDatePicker}
            onIconClick={clearDateFilter}
          /> :
          <FilterButton onClick={showDatePicker}>
            <Icon name={'calendar'} />
          </FilterButton>}

        </OptionsContainer>

        <TotalizersContainer>         
          {totalizers.map((item, index) => (
            <Totalizer 
              backgroundColor={colorOptions[index]||'#000'}
              animate={item.enumApp === selectedStatus}
              onClick={() => handleOptionClick(item.enumApp)}
            >
              <h1>{item.total}</h1>
              <p>{item.descricao}</p>
            </Totalizer>
          ))}
        </TotalizersContainer>
      </FilterContainer>

      {selectedTotalizer && 
      <MobileTotalizerContainer>
        <Totalizer 
          backgroundColor={selectedTotalizer.color||'#000'}
        >
          <h1>{selectedTotalizer.total}</h1>
          <p>{selectedTotalizer.descricao}</p>
          <a className="topRight" onClick={showTotalizerPicker}>
            <Icon name={"edit"}/>
          </a>
        </Totalizer>

      </MobileTotalizerContainer>}

     

      
      {!isListLoading &&
        <>
        <SearchInput placeholder={'Digite a pesquisa'} onChange={handleSearch}/>
        
        <ListContainer numberOfItems={listToShow.length}>
          {listToShow.map(item => (
            <CardContainer key={item.id}>
              <Card 
                item={item} 
                borderColor={selectedTotalizer.color}
              />
            </CardContainer>
          ))}
        </ListContainer>
        </>
      }

      {isListLoading && 
        <div style={{marginTop: 135}}>
          <Spinner color={'#000'}/>
        </div>
        
      }
    </Container> }
    </>
  )
}