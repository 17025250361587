import React from 'react';
import Button from '@material-ui/core/Button';
import CloseIcon from '@material-ui/icons/Close';
import PropTypes from "prop-types";
import { FileButton } from './style';


const Buttonfile = ({ name, click, index }) => {
    return (
      <>
        <FileButton size="small" onClick={() => click(index)}>
          <CloseIcon />
          {name}
        </FileButton>

      </>
    )
};


Buttonfile.propTypes = {
    name : PropTypes.string.isRequired,
    click : PropTypes.func.isRequired,
    index : PropTypes.number.isRequired
}



export default Buttonfile;
