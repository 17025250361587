import React from 'react';
import { useState } from 'react';
import { Overlay } from '../../../global/style';
import { Button, CompanyPicker, CloseButton, ModalBox, Header } from './style';
import { Icon } from 'semantic-ui-react'

export default function FilterModal({ companyList, applyFilter, currentSelectedCompany, closeModal }){
  const [selectedOption, setSelectedOption] = useState(currentSelectedCompany);

  function handleApplyClick(){
    applyFilter(parseInt(selectedOption));
  }

  function handleOptionChange(event){
    setSelectedOption(event.target.value);
  }

  return(
    <Overlay>
      <ModalBox>
        <Header>
          <h1>Filtro</h1>
          <CloseButton onClick={closeModal}>
            <Icon name={'close'}/>
          </CloseButton>
        </Header>
        
        <CompanyPicker onChange={handleOptionChange} value={selectedOption}>
          <option value={0}>Selecione uma empresa</option>
          {companyList.map(item => <option value={item.value}>{item.label}</option>)}
        </CompanyPicker>

        <Button onClick={handleApplyClick}>Aplicar</Button>
      </ModalBox>
    </Overlay>
  )
}