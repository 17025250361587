import React, { Component } from 'react'
import { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';
import Button from '@material-ui/core/Button';
import ptBR from "date-fns/locale/pt-BR";
import dayjs from "dayjs"
import { DateButton, DatePicker, DateSelectContainer } from './style';
import { SubTitle } from '../../global/style';

registerLocale("pt-BR", ptBR);

export default class DateToSelect extends Component {

  static defaultProps = {
    date: new Date()
  }

  constructor(props) {
    super(props);
    this.refDate = React.createRef();
  }

  state = {
    isOpen: false,
    openOne: false,
    openTwo: false,
    minDate: '',
    maxDate: ''
  }

  componentDidUpdate(prevProps) {
    if (this.props.objLimiteData !== prevProps.objLimiteData) {
      this.handleDataLimit(this.props.objLimiteData)
    }
  }

  componentDidMount() {
    if (this.props.objLimiteData) {
      if (this.props.objLimiteData.referenciaDiaMes)
        this.handleDataLimit(this.props.objLimiteData)
    }
  }

  handleDate = (date) => {
    if (this.props.handleDate) {
      this.props.handleDate(date);
      this.setState({ openOne: false });
    } else {
      return;
    }
  }


  handleDataLimit = ({ referenciaDiaMes, quantidade }) => {
    /* referenciaDiaMes 1=Dias, 2=Mes */
    let auxDate = new Date();
    let date = auxDate;

    if (quantidade === 0) return;

    if (referenciaDiaMes === 1) {

      date.setDate(date.getDate() - quantidade);
      this.setState({ minDate: date })

    } else if (referenciaDiaMes === 2) {

      date.setMonth(date.getMonth() - quantidade);
      date.setDate(1)

      this.setState({ minDate: date })
    }
  }


  handleDateF = (date) => {
    if (this.props.handleDate) {
      this.props.handleDateF(date);
      this.setState({ openTwo: false });
    } else {
      return;
    }
  }

  onSelected = () => {
    if (this.props.onSelected) {
      this.props.onSelected();
    } else {
      return;
    }
  }


  onSelectedF = () => {
    if (this.props.onSelectedF) {
      this.props.onSelectedF();
    } else {
      return;
    }
  }


  openDateSelect = () => {
    if (this.props.date === '_/_/_') {
      this.handleDate(new Date());
      this.handleDateF(new Date());
      this.setState({ openOne: true })
      return
    }
    this.setState({ openOne: true })
  }

  render() {
    const { date, monthAndYearOnly, dateF } = this.props;

    let dataIniLabel = "_/_/_";
    let dataFimLabel = "_/_/_";

    if (monthAndYearOnly) {
      dataIniLabel = dayjs(this.props.date).format('MM/YYYY');
      dataFimLabel = dayjs(this.props.dateF).format('MM/YYYY');
    } else {
      if (date !== "_/_/_") {
        dataIniLabel = dayjs(this.props.date).format('DD/MM/YYYY');
      }
      if (dateF !== "_/_/_") {
        dataFimLabel = dayjs(this.props.dateF).format('DD/MM/YYYY');
      }
    }

    return (
      <DateSelectContainer>
        <SubTitle size="12">{this.props.labelIni}</SubTitle>
        <DateButton onClick={this.openDateSelect} bigger={this.props.monthAndYearOnly}>
          {dataIniLabel}
        </DateButton>

        {
          this.state.openOne ? (
            <DatePicker
              onSelect={this.onSelected}
              locale={ptBR}
              selected={this.props.date}
              onChange={this.handleDate}
              dateFormat={this.props.monthAndYearOnly ? "MM/yyyy" : "dd/MM/yyyy"}
              showMonthYearPicker={this.props.monthAndYearOnly}
              withPortal
              inline

              minDate={this.state.minDate}
              maxDate={this.maxDate}
              ref={(c) => this.refDate = c}
            />
          ) : null
        }


        {
          !this.props.monthAndYearOnly ? (
            <>
              <SubTitle size={12} margin={'0 0 0 20px'}>{this.props.labelFim}</SubTitle>
              <DateButton onClick={() => this.setState({ openTwo: true })}>
                {dataFimLabel}
              </DateButton>
              {
                this.state.openTwo ? (
                  <DatePicker
                    onSelect={this.onSelectedF}
                    locale={ptBR}
                    selected={this.props.dateF}
                    onChange={this.handleDateF}
                    dateFormat={this.props.monthAndYearOnly ? "MM/yyyy" : "dd/MM/yyyy"}
                    showMonthYearPicker={this.props.monthAndYearOnly}
                    withPortal
                    inline
                    minDate={this.state.minDate}
                    maxDate={this.maxDate}
                    ref={(c) => this.refDate = c}
                  />
                ) : null
              }
            </>
          ) : null
        }



        {
          this.props.showButton ? (
            <IconButton className="btnCalender" onClick={this.props.onClick}><SearchIcon /></IconButton>
          ) : null
        }


      </DateSelectContainer>
    )
  }
}
