import styled from "styled-components";
import Button from '@material-ui/core/Button';

export const ButtonContainer = styled(Button)`
  background: var(--azul) !important;
  color : #fff !important;
  padding: 8px 12px 6px !important;
  padding-right: 20px !important;
  border-radius: 5px !important;
  font-size: ${props => props.theme.fontSize.small} !important;
`