import React, { Component } from 'react'
import APIUser from "../../API/APIUser";
import aux from "../../utils/auxiliar"
import { Icon } from "semantic-ui-react";
import Moment from "moment"
import Loading from "../../components/loading/Loading";

import storage from "../../storage"

import { CardContainer, EmptyListMessage, ListContainer, Phone, PhoneIcon } from "./style"
import { CardBody, CardRow, CardTitle } from '../../global/style'


export default class CursosDisponiveis extends Component {

  state = {
    cursosDisponiveis: [],
    loading: false,
    isMobile: false
  }

  async componentDidMount() {
    aux.answerAPP();
    const isMobile = storage.isMobile();
    try {
      this.setState({ loading: true, isMobile });
      const response = await APIUser.getCursosDisponiveis();
      this.setState({ loading: false, cursosDisponiveis: response });
    } catch (error) {
      this.setState({ loading: false });
      console.log(error)
    }

  }


  extractHour = (date) => {
    try {
      return Moment(date.replace('Z', '')).format('HH:mm');
    } catch (error) {
      return ''
    }

  }


  render() {
    const { cursosDisponiveis = [] } = this.state;



    let cursos = cursosDisponiveis.map((c, index) => {
      return (
        <CardContainer key={index} >
          <CardTitle>{c.curso}</CardTitle>

          <CardBody>

            <CardRow>
              <div>
                <small><Icon size="small" name='clock outline' className="" /> Início do curso</small>
                <h6 className="f14">{aux.formataDataUTC(c.dtInicioCurso)}</h6>
              </div>

              <div className="text-right">
                <small><Icon size="small" name='clock outline' className="" /> Fim do curso</small>
                <h6 className="f14">{aux.formataDataUTC(c.dtFimCurso)}</h6>
              </div>
            </CardRow>


            <CardRow>
              <div>
                <small><Icon size="small" name='clock outline' className="" /> Período de horas</small>
                <h6 className="f14">Entre {this.extractHour(c.hrInicioCurso)} e {this.extractHour(c.hrFimCurso)}</h6>
              </div>

              <div className="text-right">
                <small>Inclui Refeição</small>
                <h6 className="f14">{c.incluiRefeicao}</h6>
              </div>
            </CardRow>


            <CardRow>
              <div>
                <small> Inclui Estadia</small>
                <h6 className="f14">{c.incluiEstadia}</h6>
              </div>
            </CardRow>


            <CardRow>
              <div>
                <small> Observação</small>
                <h6 className="f14">{c.obsFuncionario}</h6>
              </div>

              <div className="text-right">
                <small><Icon size="small" name='money bill alternate outline' className="" /> Instituicao</small>
                <h6 className="f14">{aux.formatReal(c.instituicao)}</h6>
              </div>
            </CardRow>


            <CardRow>
              <div>
                <small><Icon size="small" name='phone' className="" /> Telefone da Instituição</small>
                <h6 className="f14" >
                  {c.ddi} {c.ddd} {c.foneInstensino}
                </h6>
              </div>
            </CardRow>


            <CardRow>
              <div>
                <small><Icon size="small" name='location arrow' className="" /> Endereço da Instituição</small>
                <h6 className="f14">{c.endInstensino}</h6>
              </div>

              <div className="text-right">
                <small>Complemento</small>
                <h6 className="f14">{c.complemento}</h6>
              </div>
            </CardRow>



            <CardRow>
              <div>
                <small>Bairro</small>
                <h6 className="f14">{c.nomeBairro}</h6>
              </div>

              <div className="text-right">
                <small>Localidade</small>
                <h6 className="f14">{c.localidade}</h6>
              </div>
            </CardRow>


            <CardRow>
              <div>
                <small>CEP</small>
                <h6 className="f14">{c.cep}</h6>
              </div>
            </CardRow>




            {/* <div className="line-item-card-list-info footer footerCursosDisponiveis text-center">

              <Button className="btnIreiComparecer">
                Confirmar Presença
              </Button>

            </div> */}

          </CardBody>
          {c.ddi && c.ddd && c.foneInstensino && this.state.isMobile && <Phone phoneNumber={`tel:+${c.ddi}-${c.ddd}-${c.foneInstensino}`}>
            <PhoneIcon />
          </Phone>}
        </CardContainer>

      )
    })
    return (
      <>
        <Loading show={this.state.loading} />
        {
          cursos.length === 0 ? <EmptyListMessage>Nenhum curso disponível até o momento</EmptyListMessage> :
            (
              <ListContainer>
                {cursos}
              </ListContainer>
            )
        }
      </>
    )
  }
}
