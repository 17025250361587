import React, { Component } from 'react';
import storage from "../../storage"
import Fab from '@material-ui/core/Fab';
import aux from "../../utils/auxiliar"
import APIChamados from "../../API/APIChamados"
import PropTypes from "prop-types";
import CloseIcon from '@material-ui/icons/Close';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import ShareIcon from '@material-ui/icons/Share';
import Alert from "../alert/Alert"
import Loading from "../loading/Loading";
import Assign from "../assign/Assign";
import { degrees, PDFDocument } from 'pdf-lib';
import { 
	Container,
	Header,
	ImageContainer,
	OptionButton,
	PDFContainer
} from './style';

class ViewDoc extends Component {

	state = {
		assignOpen: false,
		assign: null,
		assignPDF: null,
		assignPDFBase64: null,
		viewAssignPDF: false,
		showAlert: false,
		alertMessage: "",
		loading: false
	}



	resetaStates = () => {
		this.setState(
			{
				assignOpen: false,
				assign: null,
				assignPDF: null,
				viewAssignPDF: false,
				showAlert: false,
				alertMessage: "",
				loading: false,
				assignPDFBase64: null,
				sendedAssignPDF: false,
				isMobile: false

			}
		)
	}



	componentDidMount() {
		this.setState({ isMobile: storage.isMobile() })
	}



	closeAssign = () => this.setState({ assignOpen: false });



	getAssign = (assign) => {
		this.setState({ assign, assignOpen: false }, () => this.generatePDFAssign())
	}



	openAssign = () => this.setState({ assign: null, assignOpen: true });



	_base64ToArrayBuffer(base64) {
		var binary_string = window.atob(base64);
		var len = binary_string.length;
		var bytes = new Uint8Array(len);
		for (var i = 0; i < len; i++) {
			bytes[i] = binary_string.charCodeAt(i);
		}
		return bytes.buffer;
	}



	showMessage = (alertMessage) => this.setState({ showAlert: true, alertMessage })



	closeShowMessage = () => this.setState({ showAlert: false, alertMessage: "" });




	generatePDFAssign = async () => {
		try {
			this.setState({ loading: true });

			// Aqui eu crio o base64 baseado na props file, que é um tipo FILE do javascript.
			//Após isso, crio um arrayBuffer que é o que  o componente aceita
			const base64 = await aux.getBase64(this.props.file);
			const file = this._base64ToArrayBuffer(base64.split(",")[1]);


			//Carrego o pdf no componente
			const pdfDoc = await PDFDocument.load(file);


			// Tratativa para assinar sempre a ultima página
			const pages = pdfDoc.getPages()
			const page = pages[pages.length - 1]

			//Pego as dimensões do documento
			const { width, height } = page.getSize()

			//importo para o componente a assintatua que será injetada no documento.
			const pngImage = await pdfDoc.embedPng(this.state.assign)
			const pngDims = pngImage.scale(0.50)



			/*O componente se comporta de maneira estranha quando o documeto tem um width superior a 842,
			* ele acaba encarando como se fosse aberto em landscape. Portanto, a trataiva abaixo é para que a assinatura
			* se mantenha na mesma posição
			*/
			let x = 0;
			let y = 0;
			let rotate = degrees(0);

			if (height < 842) {
				x = page.getWidth() / 2 - pngDims.width / 2;
				y = 20;
			} else {
				y = height / 2 - pngDims.width / 2;
				x = width - pngDims.height + 50;
				rotate = degrees(90);
			}

			// Por fim, a imagem é injetada no documento
			page.drawImage(pngImage, {
				x,
				y,
				width: pngDims.width - 10,
				height: pngDims.height - 10,
				rotate,
			})

			const base64PDFAssinado = await pdfDoc.saveAsBase64();

			const blob = aux.getBlobFromBase64(base64PDFAssinado);

			this.setState({
				assignPDF: blob,
				viewAssignPDF: true,
				loading: false,
				assignPDFBase64: "data:application/pdf;base64," + base64PDFAssinado
			}, () => {
				this.showMessage("Seu PDF Foi assinado com sucesso. Para envia-lo, basta clicar no ícone de upload.")
			});
		} catch (error) {
			this.setState({ loading: false });
			console.log("Método generatePDFAssign : " + error);
		}
	}




	sendAssignPDF = async () => {
		try {

			this.setState({ loading: true });

			const AssignPDF = this.state.assignPDFBase64;
			const { chamado, document_id, fileName, type } = this.props;

			const fileToSend = {
				fileName: "ASSINADO-" + fileName,
				file: AssignPDF,
				description: fileName,
				document_id: document_id,
				type: type
			};


			const response = await APIChamados.setAnexos([fileToSend], chamado);

			this.setState({ loading: false });

			if (response) {
				this.setState({ sendedAssignPDF: true });
				this.showMessage("Documento enviado com sucesso.");
				if (this.props.callToActionAfterSendAssign) {
					await this.props.callToActionAfterSendAssign();
				}
			} else {
				this.showMessage("Ops.. Algo deu errado. Vamos tentar novamente.");
			}



		} catch (error) {
			this.setState({ loading: false });
		}
	}



	closeSelf = () => {
		this.resetaStates();
		this.props.close();
	}




	render() {
		const { open, type, file, downloadFile } = this.props;
		const { assignPDF, assignOpen, viewAssignPDF, showAlert, alertMessage, loading, sendedAssignPDF } = this.state;

		return (

			open ? (
				<>
					<Alert show={showAlert} title="" message={alertMessage} onClose={this.closeShowMessage} />
					<Loading show={loading} />
					<Assign open={assignOpen} close={this.closeAssign} getAssign={this.getAssign} />
					
					<Container>
						{
							!viewAssignPDF ? (
								<Header>
									{/* {
										type === "pdf" ? (
											<Fab color="secondary" aria-label="Add" className="btnClosePdf mR20" onClick={this.openAssign}>
												<BorderColorIcon style={{ fontSize: 22 }} />
												<p>Teste</p>
											</Fab>
										) : null
									} */}

									<OptionButton color="secondary" aria-label="Add" marginRight onClick={downloadFile}>
										{!this.state.isMobile ? <CloudDownloadIcon style={{ fontSize: 22 }} /> : <ShareIcon style={{ fontSize: 22 }} />}
									</OptionButton>
									<OptionButton color="secondary" aria-label="Add" onClick={this.closeSelf}>
										<CloseIcon style={{ fontSize: 22 }} />
									</OptionButton>
								</Header>
							) : (
									<Header>
										{
											!sendedAssignPDF ? (
												<OptionButton color="secondary" aria-label="Add" onClick={this.sendAssignPDF}>
													<CloudUploadIcon style={{ fontSize: 22 }} />
												</OptionButton>
											) : null
										}

										<OptionButton color="secondary" aria-label="Add" onClick={this.closeSelf}>
											<CloseIcon style={{ fontSize: 22 }} />
										</OptionButton>
									</Header>
								)
						}
						

						{
							type === "pdf" ?
								(
									<PDFContainer>
										{
											!viewAssignPDF ? (
												<iframe title="pdf" src={typeof file === 'object' ? `./web/viewer.html?file=${window.URL.createObjectURL(file)}`:file} height="100%" width="100%" className="pdfViewer"></iframe>
											) : (
													<iframe title="pdf" src={typeof file === 'object' ?`./web/viewer.html?file=${window.URL.createObjectURL(assignPDF)}`:file} height="100%" width="100%" className="pdfViewer"></iframe>
												)
										}

									</PDFContainer>
								) : (
									<ImageContainer>
										<img src={file} className="img-responsive" alt="Imagem para visualização" />
									</ImageContainer>
								)
						}

					</Container>
				</>
			) : null
		)
	}
}

ViewDoc.propTypes = {
	open: PropTypes.bool,
	close: PropTypes.func,
	type: PropTypes.string,
	downloadArq: PropTypes.func
}

export default ViewDoc;





