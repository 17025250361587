import React from 'react';
import aux from '../../utils/auxiliar';
import PropTypes from "prop-types";
import { 
	Container, 
	InfoContainer, 
	InfoRow
} from './style';


const CardTarefa = ({ setor, dtInicio, dtFim, tarefa, acao, texto }) => {

	return (
		<Container>
			<div className="lateralCard"></div>

				{/* <div className="infoCardtarefa">
                    <h2>Tarefa</h2>
                    <p>{tarefa}</p>
                </div> */}

				<InfoContainer>
					<h2>Ação</h2>
					<p>{acao}</p>
				</InfoContainer>

				<InfoContainer>
					<h2>Setor</h2>
					<p>{setor}</p>
				</InfoContainer>


				{texto && <InfoContainer>
					<h2>Justificativa</h2>
					<p>{texto}</p>
				</InfoContainer>}

				<InfoRow>
					<InfoContainer>
						<h2>Inicio</h2>
						<p>{aux.formataDataUTC(dtInicio, true)}</p>
					</InfoContainer>


					<InfoContainer>
						<h2>Fim</h2>
						<p>{dtFim ? aux.formataDataUTC(dtFim, true) : ''}</p>
					</InfoContainer>

				</InfoRow>
		</Container>
	)
}

CardTarefa.propTypes = {
	setor: PropTypes.string,
	dtInicio: PropTypes.string,
	dtFim: PropTypes.string,
	tarefa: PropTypes.number,
	acao: PropTypes.string
}

export default CardTarefa
