import styled, { keyframes } from "styled-components"
import { Select } from 'semantic-ui-react'

export const Container = styled.div`
  position : fixed;
  top : 0;
  left : 0;
  width : 100%;
  height : 100%;
  display : flex;
  z-index : 98989898;
  justify-content : center;
  align-items : center;
  background : rgba(0,0,0,.4);
  
`

export const CustomSelect = styled(Select)`
  width : ${({ width }) => width ? width + 'px !important' : '100% !important'};
  border-bottom : 1px solid #9199A8 !important;
  padding : 0 !important;
  ${({ space }) => space ? 'margin-right : 10px !important' : ''}
  width : 100% !important;
  border : none !important;
  -webkit-border : none !important;
  border-bottom : 1px solid #9199A8 !important;
  -webkit-border-bottom : 1px solid #9199A8 !important;
  -webkit-border-radius : 0 !important;
  border-radius: 0 !important;
  line-height: 30px !important;
  padding: 0 !important;
  margin : 0px 0 10px !important;
  font-size: ${props => props.theme.fontSize.small} !important;
  color : #000 !important;  
  font-family: 'Roboto', sans-serif !important;
  font-weight: 300;  
  
`

const upFromDown = keyframes`
  from {
    opacity: 0;
    transform: translateY(100px);
  }
  to {
    opacity: 1;
    transform: translateY(0px)
  }
`


export const Box = styled.div`
  width  : 300px;
  background : #fff;
  border-radius : 10px;
  opacity : 0;
  padding : 12px;
  flex-direction : column;
  animation : .5s ${upFromDown}  ease-in-out forwards;
  display : flex;
  justify-content : center;
  align-items : center;
`
export const Title = styled.h2`
  font-size : 14px;
  color : #9199A8;
  padding : 0;
  margin : 0;
`
export const ButtonConfirm = styled.div`
  position : absolute;
  width : 44px;
  height : 44px;
  border-radius : 50%;
  background :  #2991D6;
  margin : 90px  auto;
  display : flex;
  justify-content : center;
  align-items : center;
  animation : .5s ${upFromDown}  ease-in-out forwards;
`
export const Close = styled.div`
  width : 20px;
  height : 20px;
  border-radius : 50%;
  position : absolute;
  right: 16px;
  top : -5px;
  z-index : 9999;
`

export const ContainerPhone = styled.div`
  display : flex;
  flex-direction : row;
  width : 100%;
  margin-bottom : 18px;
`

export const Input = styled.input`
  width : ${({ width }) => width ? width + 'px' : 0};
  border : none;
  border-bottom : 1px solid #9199A8;
  padding : 6px 0;
  &:focus{
    border-bottom : 1px solid #2991D6;
    transition : all 1s
  }

  ${({ space }) => space ? `margin-right : 8px` : ''}
`

export const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
`

export const Label = styled.div`
  font-family: 'Roboto', sans-serif !important;
  font-size: ${props => props.theme.fontSize.small};
  color : var(--azul);
  margin-bottom:  6px !important;
  padding: 0 !important;
  font-weight: bold;
`