import React, { useEffect, useState, useRef } from 'react';

import IconButton from '@material-ui/core/IconButton';

import CloseIcon from '@material-ui/icons/Close';
import PhotoIcon from '@material-ui/icons/AttachFile';
import ApiUser from "../../../../API/APIUser"
import Loading from "../../../../components/loading/Loading"
import Utils from "../../../../utils/auxiliar"
import Alert from "../../../../components/alert/Alert"
import AskAlert from "../../../../components/askalert/AskAlert"
import {
  Container,
  Box,
  Input,
  ButtonConfirm,
  Close,
  ContainerPhone,
  CustomSelect,
  Picture,
  ContainerPic,
  SpanError,
  InputContainer,
  Label
} from './styles';
import { NoEncryption } from '@material-ui/icons';


export default function EditAdress(props) {

  const {
    cep,
    chaveEndereco,
    codLocalidade,
    codUf,
    complemento,
    endereco,
    localidade,
    nomeBairro,
    subtprelauxiliar,
    tpLogradouro,
    numero
  } = props.dadosEndereco;

  const LgRef = useRef(null);
  const numeroRef = useRef(null)
  const cepRef = useRef(null)
  const fileInputRef = useRef(null)
  const [logradouro, setLogradouro] = useState(endereco || null);
  const [_cep, setCep] = useState(cep || null)
  const [_numero, setNumero] = useState(numero || null)
  const [bairro, setBairro] = useState(nomeBairro || "")
  const [_complemento, setComplemento] = useState(complemento || null)
  const [showAskAlert, setShowAskAlert] = useState(false)
  const [cidade, setCidade] = useState(localidade || null)
  const [messageAlert, setMessageAlert] = useState("")
  const [loading, setLoading] = useState(false)
  const [showAlert, setShowAlert] = useState(false);
  const disbaleInput = false;
  const Ufs = Utils.getUfs();
  const [ufSelect, setUfSelect] = useState(codUf || 0);
  const [tpLSelect, setTpl] = useState(tpLogradouro || 0)
  const [tiposLogradouros, setTiposLogradouro] = useState([]);
  const [_filesToUpload, setFileUpload] = useState([]);
  const [funcAgree, setFuncAgree] = useState({ func: null })
  const [_codLocalidade, setCodLocalidade] = useState(codLocalidade);
  const [arrCidades, setArrCidades] = useState([]);
  const [citySelect, setCitySelect] = useState(0)
  const [invalidCep, setInvalidCep] = useState(false);
  const invalidNumber = false;


  useEffect(() => {
    Init()
  }, []);



  async function Init() {
    try {
      setLoading(true)
      const logradouros = await ApiUser.getLogradouros();

      if (!logradouros.err) {
        setTiposLogradouro(logradouros);
      } else {
        showMessage('Não foi possível carregar informações necessárias.');
        return;
      }

      const arrCidades = await ApiUser.getCidades(codUf);

      if (!arrCidades.err) {
        setArrCidades(arrCidades);
        setCitySelect(codLocalidade)
      } else {
        showMessage('Não foi possível carregar informações necessárias.');
        return;
      }

      await getAdreesFromCep();
      setLoading(false);
    } catch (error) {
      showMessage('Não foi possível carregar informações necessárias.');
      return;
    }
  }



  async function handleUf(e, data) {
    // Faz a chamada a API para pegar somente as cidades desse estado
    try {
      setLoading(true)
      const arrCidades = await ApiUser.getCidades(data.value);
      setArrCidades(arrCidades);
      setCitySelect(arrCidades[0].value);
      setUfSelect(data.value)
      setLoading(false);
    } catch (error) {
      setLoading(false)
    }
  }




  function handleTipo(e, data) {
    setTpl(data.value)
  }



  function closeAlert() {
    setShowAlert(false)
  }




  async function updateAdress() {
    try {

      if (_filesToUpload.length === 0) {
        return showMessage("Por favor, anexe um comprovante de residência")
      }

      if (!validaData()) {
        return showMessage("Preencha todas as informações")
      }

      setLoading(true);

      const body = {
        "chaveEndereco": chaveEndereco,
        "subtprelauxiliar": subtprelauxiliar,
        "tpLogradouro": tpLSelect,
        "nomeLogradouro": logradouro,
        "numero": parseInt(_numero),
        "Complemento": _complemento,
        "nomeBairro": bairro,
        "localidade": _codLocalidade,
        "codUF": ufSelect,
        "cep": _cep,
        "comprovante": _filesToUpload[0].file
      }

      const response = await ApiUser.persistAddress(body, props.isNewAddress);

      if (!response.err) {
        setLoading(false);
        props.close(!props.isNewAddress ? 'Endereço Atualizado' : 'Endereço Cadastrado', true);
        setShowAlert(true);
      } else {
        setLoading(false);

        if (response.codeErr === 137) {
          setMessageAlert('Ops... Já existe uma alteração pendente de aprovação');
          return setShowAlert(true);
        }

        setMessageAlert('Ops... Algo deu errado. Vamos tentar novamente');
        setShowAlert(true);
      }
    } catch (error) {
      setLoading(false);
    }

  }



  function showMessage(message) {
    setLoading(false);
    setMessageAlert(message);
    setShowAlert(true);
  }




  async function getAdreesFromCep() {

    if (_cep) {
      if (_cep.length !== 8) {
        setInvalidCep(true);
        return;
      }
    } else {
      setInvalidCep(true);
      return;
    }

    setLoading(true)

    const response = await ApiUser.getAdressFromCep(_cep);

    if (!response.err) {
      const { nomeLogradouro, nomeBairro, nomeLocalidade, localidade, tpLogradouro, codUf } = response;
      const arrCidades = await ApiUser.getCidades(codUf);
      numeroRef.current.focus()
      setArrCidades(arrCidades);
      setLogradouro(nomeLogradouro);
      setBairro(nomeBairro);
      setCidade(nomeLocalidade);
      setTpl(tpLogradouro)
      setUfSelect(codUf)
      setCodLocalidade(localidade)
      setCitySelect(localidade)
      setLoading(false);
      setInvalidCep(false);
    } else {
      setInvalidCep(true);
      setLoading(false)
    }
    setLoading(false)
  }



  function validaData() {
    if (_cep && tpLSelect > 0 && logradouro && _numero && ufSelect > 0 && cidade && _filesToUpload.length > 0 && bairro && !invalidCep) {
      return true
    } else {
      return false;
    }
  }



  const openDialog = () => {
    setShowAskAlert(false)
    return fileInputRef.current.click();
  }




  function openDialogAnexos() {
    if (_filesToUpload.length > 0) {
      setFuncAgree({ func: openDialog })
      setMessageAlert('Já existe um arquivo em anexo. Deseja prosseguir e substitui-lo?');
      setShowAskAlert(true);
      return;
    }
    fileInputRef.current.click();
  }



  function closeAks() {
    setFuncAgree({ func: null });
    setShowAskAlert(false)
  }



  function handleFileUpload(e) {
    if (e.target.files.length === 0) return
    addFileToUpload(e.target.files)
  }




  async function addFileToUpload(list) {
    let files = [];

    for (let i = 0; i < list.length; i++) {
      try {
        setLoading(true);
        files.push({
          fileName: list[i].name,
          file: await Utils.getBase64(list[i])
        });
        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    }
    setFileUpload(files)
  }



  function handleCidade(e, data) {
    setCitySelect(data.value)
  }



  function handleLogradouro(e) {
    setLogradouro(e.target.value)
  }



  function handleCep(e) {
    setCep(e.target.value.replace(/\D+/g, ''))
  }





  return (
    <Container>
      <input type="file" accept="image/*,application/*" style={{display: 'none'}} ref={fileInputRef} onChange={handleFileUpload} />

      {loading && <Loading show />}

      <AskAlert
        show={showAskAlert}
        message={messageAlert}
        disagree={closeAks}
        agree={() => funcAgree.func()}
      />
      <Alert show={showAlert} title="" message={messageAlert} onClose={closeAlert} />

      <Box>
        <Close>
          <IconButton onClick={() => props.close(false, false)}>
            <CloseIcon style={{ color: "#000", fontSize: 16 }} />
          </IconButton>
        </Close>



        <ContainerPhone>
          <InputContainer>
            <Label>CEP *</Label>
            <Input
              autoFocus={props.isNewAddress}
              space
              width={'50%'}
              value={_cep}
              onChange={handleCep}
              title="cep"
              placeholder={"CEP"}
              ref={cepRef}
              maxLength={8}
              onBlur={getAdreesFromCep}
            />
            {invalidCep && <SpanError>cep não encontrado</SpanError>}
          </InputContainer>
        </ContainerPhone>


        <ContainerPhone>
          <InputContainer>
            <Label>Tipo *</Label>
            <CustomSelect
              placeholder='Tipo'
              options={tiposLogradouros}
              className="selectCidade"
              value={tpLSelect}
              onChange={handleTipo}
              compact
              space
              disabled
            />
          </InputContainer>
        </ContainerPhone>



        <ContainerPhone>
          <InputContainer>
            <Label>Logradouro *</Label>
            <Input
              autoFocus={!props.isNewAddress}
              ref={LgRef}
              space
              value={logradouro}
              onChange={handleLogradouro}
              title="logradouro"
              placeholder={"Logradouro"}
              type={'text'}
              disabled={disbaleInput}
            />
          </InputContainer>

        </ContainerPhone>


        <ContainerPhone>
          <InputContainer>
            <Label>Número *</Label>
            <Input
              space
              width={'50%'}
              value={_numero}
              onChange={(e) => setNumero(e.target.value.replace(/\D+/g, ''))}
              title="numero"
              placeholder={"Número"}
              type={'text'}
              ref={numeroRef}
              align={'left'}
            />
            {invalidNumber && <SpanError>campo vazio</SpanError>}
          </InputContainer>

        </ContainerPhone>


        <ContainerPhone>
          <InputContainer>
            <Label>Complemento</Label>
            <Input
              space
              value={_complemento}
              onChange={(e) => setComplemento(e.target.value)}
              title="complemento"
              placeholder={"Complemento"}
              type={'text'}
              label="Teste"
            />
          </InputContainer>
        </ContainerPhone>


        <ContainerPhone>
          <InputContainer>
            <Label>Bairro *</Label>
            <Input
              space
              value={bairro}
              onChange={(e) => setBairro(e.target.value)}
              title="bairro"
              placeholder={"Bairro"}
              type={'text'}
              disabled
            />
          </InputContainer>
        </ContainerPhone>


        <ContainerPhone>
          <InputContainer>
            <Label>UF *</Label>
            <CustomSelect
              selection
              placeholder='UF'
              options={Ufs}
              className="selectCidade"
              onChange={handleUf}
              compact
              space
              value={ufSelect}
              disabled
            />
          </InputContainer>
        </ContainerPhone>


        <ContainerPhone>
          <InputContainer>
            <Label>Localidade *</Label>
            <CustomSelect
              selection
              placeholder='UF'
              options={arrCidades}
              className="selectCidade"
              onChange={handleCidade}
              compact
              space
              value={citySelect}
              disabled
            />

          </InputContainer>
        </ContainerPhone>



        {
          <ButtonConfirm onClick={updateAdress}>
            SALVAR ALTERAÇÕES
          </ButtonConfirm>
        }

        <ContainerPic>
          <Picture color={_filesToUpload.length > 0 ? '#47A369' : '#cccc'}>
            <IconButton onClick={openDialogAnexos}>
              <PhotoIcon style={{ color: "#fff" }} />
            </IconButton>
          </Picture>
          <p style={{ fontSize: 12 }}>Anexar Arquivo</p>
        </ContainerPic>
      </Box>
    </Container>
  );
}
