import React from 'react';
import PropTypes from "prop-types";
import { CardContainer, Header, StatusIcon, StatusText } from './style';


const CardChamado = ({ animate, numero, color, icon, texto, click }) => {

	let colorcard = "#fff";

	if (color) colorcard = color;


	return (
		<CardContainer animate={animate} style={{ background: colorcard }} onClick={click} >
			<Header>
				<StatusIcon name={icon} />
				<p>{numero}</p>
			</Header>
		
			<StatusText>
				{texto}
			</StatusText>
			{/* <div className={`card-chamados ${classname}`} style={{ background: colorcard }} onClick={click} >
				<div className="row">
					<div className="col-md-6 col-sm-6 col-xs-6">
						<div className="cardImg" >
							<Icon size="huge" name={iconCard} className="iconCardChamado" />
						</div>
					</div>
					<div className="col-md-6 col-sm-6 col-xs-6">
						<p className="numeroCard text-right">{numero}</p>

					</div>
				</div>
				<div className="row">
					<p className="textoCard">{texto}</p>
				</div>
			</div> */}
		</CardContainer>
	)
}


CardChamado.propTypes = {
	classCustom: PropTypes.string,
	numero: PropTypes.number.isRequired,
	color: PropTypes.string.isRequired,
	icon: PropTypes.string.isRequired,
	texto: PropTypes.string.isRequired
}

export default CardChamado;