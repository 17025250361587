import { IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import PhotoIcon from '@material-ui/icons/AttachFile';

import React, { useContext, useEffect, useRef, useState } from 'react';

import { AttachFileContainer, Box, ButtonConfirm, Close, Container, CustomSelect, ErrorMessage, Input, InputContainer, Label, Picture, PictureLabel, Row } from "./styles";
import APIUser from '../../../../API/APIUser';
import { ShowMessageContext } from '../../../../contexts/showmessage';
import Utils from "../../../../utils/auxiliar"
import Spinner from '../../../../components/spinner/Spinner';
import Loading from '../../../../components/loading/Loading';

export default function EditCNV({ closeModal, values, reload }){
  const [cnvNumber, setCnvNumber] = useState(Utils.cnvMask(values.cnv));
  const [date, setDate] = useState(values.date.split('T')[0]);
  const [type, setType] = useState(values.type);
  const [picture, setPicture] = useState(null);

  const [numberError, setNumberError] = useState(false);
  const [pictureError, setPictureError] = useState(false);
  
  const [isLoading, setIsLoading] = useState(false);
  const { show } = useContext(ShowMessageContext);  
  const fileInputRef = useRef(null)


  function handleCNVNumberChange(number){
    setNumberError(false);
    setCnvNumber(Utils.cnvMask(number));
  }




  function handleDateChange(date){
    setDate(date);
  }




  function handleTypeChange(e, data){
    setType(data.value);
  }




  function isValuesValid(){
    if(cnvNumber && picture) return true;

    if(!cnvNumber) setNumberError(true);

    if(!picture) setPictureError(true);
      
    return false;
      
  }




  function openUploadDialog(){
    setPictureError(false);
    fileInputRef.current.click();
  }




  async function handleFileUpload(e){
    if (e.target.files.length === 0) return;
    setPicture(e.target.files[0])
  }




  async function handleSubmit(){
    if(!isValuesValid()){
      return;
    }

    setIsLoading(true);
    const base64 = await Utils.getBase64(picture);

    try{
      const body = {
        vencimento: date.replaceAll('-',''), 
        numeroCnv: parseInt(cnvNumber.replaceAll('/', '')), 
        tipoCnv: type, 
        docAnexo: base64
      }
      const response = await APIUser.alterCNV(body);

      if(!response.err){
        reload()
        show('CNV alterado com sucesso.', false);
      }else{
        show(response.message, true);
      }
    }catch{
      show('Algo deu errado.', true);
    }finally{
      setIsLoading(false);
    }
  }


  

  const minDate = new Date().toISOString().split("T")[0];
 
  return(
    <Container>
      <Loading show={isLoading}/>

      <Box>
        <Close>
          <IconButton onClick={() => closeModal()}>
            <CloseIcon style={{ color: "#000", fontSize: 16 }} />
          </IconButton>
        </Close>

        <input type="file" accept="image/*,application/*" style={{display: 'none'}} ref={fileInputRef} onChange={handleFileUpload} />
        <Row>

          <InputContainer>
            <Label>
              Tipo
            </Label>
            <CustomSelect
              placeholder='Selecione o tipo do CNV'
              options={[{value: 2, text: 'Protocolo'}, {value: 3, text: 'Registro'}]}
              defaultValue={type}
              onChange={handleTypeChange}
            />
          </InputContainer>
          <AttachFileContainer>
            <Picture color={!!picture ? '#47A369' : pictureError ? '#DC3545': '#cccc'}>
              <IconButton onClick={openUploadDialog}>
                <PhotoIcon style={{ color: "#fff" }} />
              </IconButton>
            </Picture>
            <PictureLabel error={pictureError}>{!!picture ? picture.name:'Anexar Arquivo'}</PictureLabel>
          </AttachFileContainer>
        </Row>

        
        <InputContainer>
          <Label>
            CNV
          </Label>
          <Input
            space
            title="CNV"
            placeholder={"CNV"}
            type={'text'}
            value={cnvNumber}
            onChange={e => handleCNVNumberChange(e.target.value)}
            error={numberError}
            maxLength={10}
          />
          {numberError && <ErrorMessage>O número do CNV não pode estar vazio</ErrorMessage>}
        </InputContainer>
        <InputContainer>
          <Label>
            Validade
          </Label>
          <Input
            space
            type={'date'}
            value={date}
            min={minDate}
            onChange={e => handleDateChange(e.target.value)}
          />
        </InputContainer>


        <ButtonConfirm onClick={handleSubmit} disabled={isLoading}>Editar</ButtonConfirm>
      </Box>

      
    </Container>
  )
} 