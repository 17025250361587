import React from 'react';
import storage from "../../storage"
import Dialog from '@material-ui/core/Dialog';
import aux from "../../utils/auxiliar";
import Loading from "../../components/loading/Loading"
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import CardTarega from '../cardtarefa/CardTarefa';
import PropTypes from "prop-types";
import CardAnexo from "./../cardanexo/CardAnexo"
import ViewDoc from "../viewdoc";
import APIChamados from "../../API/APIChamados"
import Alert from "../alert/Alert";
import AskAlert from "../askalert/AskAlert";
import { withRouter } from "react-router-dom"
import AttachFileIcon from '@material-ui/icons/AttachFile';
import InsertAttachment from "../insertattachment"
import { AttachFileButton, Container, InfoContainer, Section, SectionBody, SectionHeader, TopBar, Value } from './style';


function Transition(props) {
	return <Slide direction="up" {...props} />;
}


class ChamadoDetail extends React.Component {

	static defaultProps = {
		typesOfAttachment: []
	}

	state = {
		openDoc: false,
		fileInBlob: "",
		type: "",
		imageBase64: "",
		loading: false,
		currentFile: "",
		currentFileName: "",
		askAlert: false,
		messageAlert: "",
		showAlert: false,
		alertMessage: "",
		currentAnexo_id: 0,
		chamadoAtual: {},
		anexos: [],
		document_id: null

	};




	async componentDidUpdate(oldProps) {
		if (this.props !== oldProps) {
			if (this.props.open === true) {
				this.setState({ loading: true })
				await this.updateData();
				this.setState({ loading: false })
			}

		}
	}



	updateData = async () => {
		try {
			const response = await APIChamados.getDetail(this.props.chamado);
			const anexos = await APIChamados.getAnexos(this.props.chamado);

			this.setState({ chamadoAtual: response.detail || [], anexos });

		} catch (error) {
			console.log(error);
		}
	}



	getObjectToUpload = async (list, nameCurrentAnexo, catCurrentAnexo) => {
		let files = [];
		for (let i = 0; i < list.length; i++) {
			try {
				files.push({
					fileName: list[i].name,
					file: await aux.getBase64(list[i]),
					description: nameCurrentAnexo,
					document_id: catCurrentAnexo.key,
					type: aux.getTypeFile(list[i].name)
				});
			} catch (error) {
				console.log(error)
			}
		}
		return files;
	}



	// o "e" é um FILE
	handleFileUpload = async (e, nameCurrentAnexo, catCurrentAnexo) => {
		try {

			const chamado = this.props.chamado;

			this.setState({ loading: true });

			const objectToUpload = await this.getObjectToUpload(e.target.files, nameCurrentAnexo, catCurrentAnexo);
			const response = await APIChamados.setAnexos(objectToUpload, chamado);

			if (response) {
				await this.updateData();
				this.showMessage("Arquivo anexado com sucesso")
			} else {
				this.showMessage("Ops... algo deu errado. Vamos tentar novamente");
			}
			this.setState({ showAttachment: false, loading: false });
		} catch (error) {
			this.setState({ showAttachment: false, loading: false });
			this.showMessage("Ops... algo deu errado. Vamos tentar novamente");
		}

	}




	downloadFile = () => {
		try {
			const isMobile = storage.isMobile();
			if (isMobile) {
				const body = {
					acao: "Download",
					fileName: this.state.currentFileName,
					anexo_id: this.state.currentAnexo_id,
					chamado_id: this.props.chamado,
					ex: aux.getTypeFile(this.state.currentFileName)
				}
				window.ReactNativeWebView.postMessage(JSON.stringify(body));
			} else {
				aux.downloadFile(this.state.currentFile, this.state.currentFileName);
			}
			this.setState({ askAlert: false });
		} catch (error) {
			console.log(error);
		}
	}

	closeShowMessage = () => this.setState({ showAlert: false, alertMessage: "" })

	showMessage = message => this.setState({ showAlert: true, alertMessage: message })


	openAnexo = async (anexo_id, nomeArquivo, document_id) => {

		this.setState({ loading: true });
		try {
			const response = await APIChamados.getAnexo(anexo_id, this.props.chamado)

			if (response) {
				if (aux.getTypeFile(nomeArquivo).toUpperCase() !== "PDF") {
					if (aux.isImage(nomeArquivo).hadAnImage) {
						this.setState({
							openDoc: true,
							type: "img",
							currentFile: response.data.anexo,
							currentFileName: nomeArquivo,
							currentAnexo_id: anexo_id,
							document_id,
						})
					}
					else {
						this.setState({
							askAlert: true,
							messageAlert: "Não há visualizador para o arquivo selecionado. Deseja efetuar o download do arquivo ?",
							currentFile: response.data.anexo,
							currentFileName: nomeArquivo,
							currentAnexo_id: anexo_id,
							document_id,
						});
					}
				} else {
					const file = aux.getBlobFromBase64(response.data.anexo.split(",")[1]);
					this.setState({ openDoc: true, type: "pdf", currentFile: file, currentFileName: nomeArquivo, currentAnexo_id: anexo_id, document_id });
				}
			} else {
				this.showMessage("Ops.. Algo não deu certo. Vamos tentar novamente.")
			}


			this.setState({ loading: false })
		} catch (error) {
			this.setState({ loading: false })
		}
	}


	closePdf = () => {
		this.setState({ openDoc: false, currentAnexo_id: 0 });
	}

	openAttachament = () => {
		this.setState({ showAttachment: true });
	}

	closeAttachment = () => this.setState({ showAttachment: false });




	render() {
		const { open, close } = this.props;
		const { chamadoAtual, anexos } = this.state;

		let auxTarefas = [];

		if (chamadoAtual.tarefas) auxTarefas = chamadoAtual.tarefas;

		const viewTarefas = auxTarefas.map((o, index) => <CardTarega {...o} key={index} />);
		let textoChamado = chamadoAtual.textoChamado;

		if (textoChamado) {
			textoChamado = textoChamado.split('\r').map(t => {
				return <Value>{t}</Value>
			})
		}


		return (
			<div>
				<Alert show={this.state.showAlert} title="" message={this.state.alertMessage} onClose={this.closeShowMessage} />
				<InsertAttachment
					typesOfAttachment={this.props.typesOfAttachment}
					getFileUpload={this.handleFileUpload}
					show={this.state.showAttachment}
					close={this.closeAttachment}
				/>
				<Loading show={this.state.loading} />
				<AskAlert
					show={this.state.askAlert}
					message={this.state.messageAlert}
					disagree={() => this.setState({ askAlert: false, messageAlert: "", currentFile: "", currentFileName: "" })}
					agree={this.downloadFile}
				/>
				{/* Esse ViewDoc é o componente de visualizacao do arquivo pdf ou img */}
				<ViewDoc open={this.state.openDoc} close={this.closePdf}
					file={this.state.currentFile}
					type={this.state.type}
					downloadFile={this.downloadFile}
					chamado={this.props.chamado}
					document_id={this.state.document_id}
					fileName={this.state.currentFileName}
					callToActionAfterSendAssign={this.updateData}
				/>

				<Dialog
					fullScreen
					open={open || false}
					onClose={close}
					TransitionComponent={Transition}


				>
					<TopBar>
						<Toolbar>
							<IconButton color="inherit" aria-label="Close" onClick={close}>
								<CloseIcon />
							</IconButton>
						</Toolbar>
					</TopBar>

					<Container>
						<Section firstSection>
							<SectionHeader>
								<h2>Dados do Chamado</h2>
							</SectionHeader>

							<InfoContainer>
								<h2>Número do Chamado</h2>
								<Value>{chamadoAtual.numeroChamado}</Value>
							</InfoContainer>


							<InfoContainer>
								<h2>Ocorrência</h2>
								<Value>{chamadoAtual.ocorrencia}</Value>
							</InfoContainer>


							<InfoContainer>
								<h2>Título</h2>
								<Value>{chamadoAtual.titulo}</Value>
							</InfoContainer>

							<InfoContainer>
								<h2>Situação</h2>
								<Value>{chamadoAtual.sitChamado}</Value>
							</InfoContainer>


						</Section>

						<Section>
							<SectionHeader>
								<h2>Descrição do Chamado</h2>
							</SectionHeader>
							<SectionBody>
								{textoChamado}
							</SectionBody>
						</Section>

						<Section>
							<SectionHeader hasIcon>
								<h2>Arquivos Anexados</h2>
								<AttachFileButton color="inherit" aria-label="Close" className="btnAnexarIntoDetail" onClick={this.openAttachament}>
									<AttachFileIcon />
								</AttachFileButton>
							</SectionHeader>
							<SectionBody justify="flex-start">

								{
									anexos.map((a, index) =>
										<CardAnexo
											click={() => this.openAnexo(a.arquivo, a.nomeArquivo, a.conteudoArquivo)}
											fileName={a.nomeArquivo}
											type="img"
											description={a.descricao}
											key={index}
										/>
									)
								}

							</SectionBody>
						</Section>


						<Section>
							<SectionHeader>
								<h2>Tarefas</h2>
							</SectionHeader>
							<SectionBody justify="center">
								{viewTarefas}
							</SectionBody>
						</Section>

					</Container>
				</Dialog>
			</div>
		)
	}
}

ChamadoDetail.propTypes = {
	titulo: PropTypes.string,
	ocorrencia: PropTypes.string,
	numeroChamado: PropTypes.number,
	sitChamado: PropTypes.string,
	textoChamado: PropTypes.string,
	tarefas: PropTypes.array,
	open: PropTypes.bool,
	close: PropTypes.func.isRequired,
	typesOfAttachment: PropTypes.array
}

export default withRouter(ChamadoDetail);
