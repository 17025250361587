import styled, { css, keyframes } from "styled-components";
import { Form } from 'semantic-ui-react';


export const DefaultCard = styled.div`
  width: 100%;
  padding: 5px;
  position: relative;
  border-radius: 5px;
  background: #fff;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.21);

  
`

export const CardBody = styled.div`
  width: 100%;
  padding : 10px;
`

export const CardRow = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  width: 100%;

  small {
    color: #A9AEB8;
  }

  h4, h6 {
    color: #1a1a1a;
  }

  h4 {
    margin-top: 10px;
    margin-bottom: 10px;
  }
`

export const CardTitle = styled.h5`
  text-align: center;
  padding: 10px 0 10px;
  font-size:  ${props => props.theme.fontSize.normal};
  letter-spacing: .45px;
  font-weight: 600;
`

export const SubTitle = styled.h6`
  font-size:${props => props.size ? props.size : '14'}px;
  font-weight: 400;
  text-transform: uppercase;
  ${props => props.margin && `margin: ${props.margin};`}
`


const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`

export const FadeInAnimation = css`
  animation-name: ${fadeIn};
  animation-duration: 1s;
  -webkit-animation-fill-mode: forwards;
  animation-delay: 0;
  transition: all 1s;
`

export const Dropdown = styled(Form.Select)`
  span{
    color : #000;
    font-size: ${props => props.theme.fontSize.smallernormal} !important;
  }
`



export const OptionCard = styled.button`
  height: 100px;    
  border: none;
  border-radius: 2px; 
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #fff;
  box-shadow: 0 1px 4px 0 rgba(0,0,0,.14); 
  position: relative;

 

  ${props => props.backgroundColor && `background: ${props.backgroundColor};`}
  ${props => props.animate && `
    box-shadow: 0 4px 38px 5px rgba(35,51,64,.5)!important;
    -webkit-transform: translateY(-3px);
    transform: translateY(-3px);  
    transition: all .4s;
    h1, p {
      font-weight: 600;
    }

    ` 
  }

  


  :hover {
    cursor: pointer;
  }

  @media (min-width: 280px) and (max-width: 1024px) {
    height: 77px;
    width: 100%;    
  }

` 


export const Overlay = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  height: 100vh;
  background: rgba(0,0,0,.6);
  z-index: 999999;
  display: flex;
  align-items: center;
  justify-content: center;
`