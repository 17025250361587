import styled from "styled-components";

export const Container = styled.div`
  position: relative;
  border-radius: 5px; 
  color : #000;
  margin : 10px 10px 10px;
  width: 100%;
  background: #fff;
  border-left: 5px solid var(--azul);
  box-shadow: 0 1px 4px 0 rgba(0,0,0,.14);
`

export const InfoContainer = styled.div`
  padding: 10px;

  h2 {
    font-size: ${props => props.theme.fontSize.smallernormal};
    font-weight: bold;
    color : var(--azul);
    text-align: left;
  }

  p {
    font-size: ${props => props.theme.fontSize.smallernormal};
    line-height: 18px;
    color : #000;    
  }
`

export const InfoRow = styled.div`
  display: flex;
  justify-content: flex-start;
`