import React from 'react';
import { CardRow, CardTitle } from '../../../global/style';
import { Container, Footer } from './style';

export default function Card({ item, borderColor }){
  return(
   <Container borderColor={borderColor}>
       
      <CardTitle>{item.nomeEmpresa}</CardTitle>
      <h6>Local</h6>
      <p>{item.local.nome||'--'}</p>
      <h6>Endereço</h6>
      <p className={'address'}>
        {item.local.endereco.tpLogradouro} {item.local.endereco.logradouro}, {item.local.endereco.numero}, {item.local.endereco.nomeBairro} - {item.local.endereco.localidade} - {item.local.endereco.uF} 
      </p>
      <h6>Funcionário</h6>
      <p>{item.funcionario.nome||'--'}</p>
      <h6>Cargo Vaga</h6>
      <p>{item.funcionario.cargo||'--'}</p>
      <h6>Cargo Funcionario</h6>
      <p>{item.funcionario.cargoFunc||'--'}</p>
      <CardRow>
        <div>
          <h6>St. Funcionário</h6>
          <p>{item.funcionario.situacao||'--'}</p>
        </div>

        <div>
          <h6>St. Vaga</h6>
          <p>{item.funcionario.tpCobertura||'--'}</p>
        </div>
      </CardRow>

      <h6>Escala</h6>
      <p>{item.funcionario.escala||'--'}</p> 

      <Footer>
        <p>{item.funcionario.horario.hREntrada}</p>
        <p>{item.funcionario.horario.hREntradaAlmoco}</p>
        <p>{item.funcionario.horario.hRSaida}</p>
        <p>{item.funcionario.horario.hRSaidaAlmoco}</p>
      </Footer>
    </Container>
  )
}