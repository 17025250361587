import React, { useMemo, useState } from 'react';
import { News, NewsHeader, NewsImageContainer, NewsBody } from './style';


export default function NewsCard({ news, open }){

  const [noticiaExpandida, setNoticiaExpandida] = useState(false);
  
  const corpoNoticia = useMemo(() => {  
    if(news.txtComplementar.length > 230 && !noticiaExpandida) return news.txtComplementar.substring(0, 230) + "...";
    return news.txtComplementar;
  }, [noticiaExpandida]);


  function expandirNoticia(){
    setNoticiaExpandida(true);
  }

  return(
    <News>
      <NewsHeader smallerTitle={news.descricao.length > 30}>
        <h1>{news.descricao}</h1>
        <h2>{news.dataHora}</h2>
      </NewsHeader>

      <NewsBody>
        <p>{corpoNoticia}</p>
        {!noticiaExpandida && corpoNoticia.length > 230 && <a onClick={expandirNoticia}>Ver mais</a>}
      </NewsBody>

      {!!news.imagem && <NewsImageContainer>
        <img src={news.imagem}/>
      </NewsImageContainer>}

    </News>
  )
}