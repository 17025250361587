import styled, { keyframes, css } from "styled-components";
import Button from '@material-ui/core/Button';
import { DefaultCard } from "../../global/style";

export const Container = styled.div`
  width: 100%;
  overflow-x: hidden;
`

export const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 30px;

  @media (min-width: 320px) and (max-width: 990px) {
    display: flex;
    flex-direction: column;
    grid-gap: 0;

  }

`
export const DateFilterContainer = styled.div`
  width: 100%;
  height: 80px;
  background: #fff;
  margin-bottom: 20px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border-radius: 5px;
  box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, .1);
`

export const StatusFilterContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-wrap: nowrap;
  position: relative;
  width: 100%;
  background: #fff;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.21);
  height: fit-content;
  @media (min-width: 320px) and (max-width: 990px) {
    margin-bottom: 16px;
  }
`

const spin180 = keyframes`
  from {
    transform: rotate(0);
    transition: all .4s;
  }
  to {
    transform: rotate(180deg);
    transition: all .4s;
  }
`

const spinAnimationCss = css`
  animation-name: ${spin180};
  animation-duration: .4s;
  animation-timing-function: linear;
  -webkit-animation-fill-mode: forwards;
  animation-delay: 0;
`

export const StatusFilterHeader = styled.div`
  width: 100%;
  height: 40px;
  box-sizing: border-box;
  padding-left: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: all .4s;

  h6 {
    text-transform: uppercase;
    letter-spacing: 0.5px;   
    padding-left: 10px;
    text-align: left
  }

  i {
    ${props => props.isOpen && spinAnimationCss}
  }

  button {
    height: 100%;
  }
`

export const StatusFilterBody = styled.div`
  width: 100%;
  height: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;

  ${props => props.isOpen && `
    padding-top: 20px !important;
    padding-bottom: 20px !important;
    height: auto !important;
    transition: all .2s;

    @media (min-width: 320px) and (max-width: 990px) {
      flex-direction: row;
      flex-wrap: wrap;
      padding: 10px;
      justify-content: space-around;
    }
  `}

  
`

export const StatusCard = styled(Button, DefaultCard)`
  width: 150px;
  height: 120px;
  cursor: pointer;
  padding: 18px 0 0 !important;
  transition: all .4s;
  margin-right: 10px;
  display: flex !important;
  flex-direction: column !important;
  transition: all .2s;
  margin: 0 10px 0 !important;

  ${props => props.animate && `
    box-shadow: 0 24px 48px 10px rgba(35, 51, 64, .35)!important;
    -webkit-transform: translateY(-3px);
    transform: translateY(-3px);

    @media (min-width: 320px) and (max-width: 990px) {
      -webkit-transform: translateY(-5px);
      transform: translateY(-5px);
    }
  `}

  span {
    display: block;
    text-align: center !important;
  }

  &:hover{
    box-shadow: 0 0px 20px 2px rgba(35, 51, 64, .35)!important;
  }

  @media (min-width: 320px) and (max-width: 990px) {
    margin: 0 !important;
    height: 65px !important;
    width: 150px !important;
    margin-bottom: 2px !important;
    justify-content: center;
    padding: 0 !important;
    span {
      text-align: center !important;
    }
  }

`

export const StatusNumber = styled.div`
  width: 100%;
  height: 60px;
  text-align: center;
  font-size: ${props => props.theme.fontSize.extrahuge};
  padding-top: 10px;

  @media (min-width: 320px) and (max-width: 990px) {
    width: 100%;
    margin: 0;
    height: auto;
    padding: 0;
    text-align: center;
    margin: 0 auto;
    p {
      font-size: ${props => props.theme.fontSize.large};
    }
  }
`

export const StatusText = styled.div`
  width: 100%;
  height: 80px;
  padding-top: 10px;
  font-size: ${props => props.theme.fontSize.smallernormal};

  @media (min-width: 320px) and (max-width: 990px) {
    height: auto;
    padding: 0;
    margin: 0 auto;
    p {
      font-size: ${props => props.theme.fontSize.small} !important;
    }
  }
`

