import React, { useState, useEffect } from "react";
import aux from "../../utils/auxiliar"
import { Icon } from "semantic-ui-react";
import GenericCard, { LineGenericCard } from "../../components/genericcard"
import Loading from "../../components/loading/Loading"
import APIUser from "../../API/APIUser";
import { Container } from "./style";

export default function HistoricoCursos() {

  const [loading, setLoading] = useState(true);
  const [cursos, setCursos] = useState([]);


  async function getCursos() {
    setLoading(true);
    const response = await APIUser.getCursosHistorico() || [];
    setCursos(response);
    setLoading(false);
  }
  useEffect(() => {
    aux.answerAPP();
    getCursos();
  }, []);


  if (loading) {
    return <Loading show={loading} />
  }

  if (cursos.length > 0) {
    const ToRender = cursos.map((c, index) => {
      return (

        <GenericCard key={index} title={c.cursoFormacao} footer footerTitle="AVALIAÇÃO" footerText={c.avaliacao}>
          <LineGenericCard
            leftTitle={<><Icon size="small" name='clock outline' className="" /> Início do curso</>}
            leftText={c.dtInicioCurso}

            rightTitle={<><Icon size="small" name='clock outline' className="" /> Fim do curso</>}
            rightText={c.dtFimCurso}

            lFd
            rFd
          />

          <LineGenericCard
            leftTitle={"Tem Validade ?"}
            leftText={c.temValidade}

            rightTitle={"Validade do Curso"}
            rightText={c.validadeCurso}
            rFd
          />

          <LineGenericCard
            leftTitle={"Tem Validade ?"}
            leftText={c.temValidade}

            rightTitle={"Validade do Curso"}
            rightText={c.validadeCurso}
            rFd
          />
        </GenericCard>

      )
    });
    return (
      <Container>
        {ToRender}
      </Container>
    );
  } else {
    return <p className="mensagemCursosDisponiveis">Nenhum curso disponível até o momento</p>
  }

}




