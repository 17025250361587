import React from "react";

const Page404 = props => {
  return (
    <div style={{ display: "flex", width : "100%", height : "100vh", justifyContent: "center", alignItems: "center" }}>
      <h1>Ops... Essa página não foi encontrada :(</h1>
    </div>
  )
}

export default Page404;
