import React, { Component, useContext } from 'react';
import {
  Container,
  ContainerDireito,
  LogoContainer,
  MenuButton,
  PontoContainer
} from './style';
import { useState } from 'react';
import moment from 'moment';
import APIUser from "../../API/APIUser";
import aux from '../../utils/auxiliar';
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom'; 
import { PontoContext } from '../../contexts/ponto';


export default function Header(props){
  const { logo, active, visible, isMenuOpen } = props;

  const [lastRecord, setLastRecord] = useState(null);
  const history = useHistory();
  const { loadPonto } = useContext(PontoContext);


  useEffect(() => {
    innit();
  }, [])


  async function innit(){
    const ponto = await loadPonto();
    setLastRecord(ponto);
  }



  function clickedMenu(){
    // if (this.state.classActive === "") this.setState({ classActive: "is-active" })
    // else this.setState({ classActive: "" })
    props.openHeader();
  }


  function goToPonto(){
    history.push({pathname: '/ponto'})
  }



    if (visible) {
      return (
          <Container menuAberto={active}>
            <h1>Portal do Colaborador</h1>


            <ContainerDireito>
              
              <PontoContainer onClick={goToPonto}>
                <span>Ponto diário:</span> 
              
                <span>{lastRecord}</span>
              </PontoContainer>

              {logo && <LogoContainer isMenuOpen={isMenuOpen}>
                <img src={logo} alt="logo empresa" />
              </LogoContainer>}

            </ContainerDireito>

            <MenuButton type="button" className={`hamburger--spin ${active ? 'is-active' : ''}`} onClick={clickedMenu}>
              <span className="hamburger-box">
                <span className="hamburger-inner"></span>
              </span>
            </MenuButton>

          </Container>
      );
    } else {
      return null
    }
  
}
