import React from 'react';
import PropTypes from 'prop-types';
import { AttachIcon, Container, Footer, IconContainer } from './style';

const CardAnexo = ({ fileName, click, type }) => { 
    // let icon = "";   
    // if(type === "img") icon = "images";
    // if(type === "pdf") icon = "file pdf outline";    

    return (
      <>
      <Container onClick={click}>
        <IconContainer>
          <AttachIcon name={"attach"} size="huge" />
        </IconContainer>
        <Footer>
          <p>{fileName}</p>
        </Footer>
      </Container>
      </>
    )
};


CardAnexo.propTypes = {    
    fileName: PropTypes.string.isRequired
}

export default CardAnexo;
