import React from 'react';

import {
  QuestionsContent,
  Option
} from "./style"

const Item = ({ data, handleOptionClick, loading }) => {
  return (
    <QuestionsContent>
      <h2>{data.pergunta}</h2>
      {
        data.alternativas && data.alternativas.map((i, index) => {
          return (
            <Option active={i.active} onClick={() => handleOptionClick(index)}>
              <p>{i.texto}</p>
            </Option>
          )
        })
      }
    </QuestionsContent>
  );
};

export default Item;