import React, { Component } from 'react'
import dayjs from "dayjs"
import PropTypes from "prop-types"
import { Icon } from "semantic-ui-react"
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';

import {
  CardContainer,
  CardRow,
  DataContainer,
  Footer,
  FooterCard,
  IconContainer,
  Label,
  Title,
  Value
} from "./styles"

export const LineGenericCard = props => { };

export default class GenericCard extends Component {


  state = {
    lines: null
  }

  componentDidMount() {
    this.montaCard();
  }

  componentDidUpdate(oldProps) {
    if (this.props.children !== oldProps.children) {
      this.montaCard();
    }
  }

  montaCard = () => {

    let lines = null;

    if (!this.props.children) {
      throw new Error('Nenhuma linha definida');
    };

    const childrens = this.props.children;

    if (childrens.constructor !== Array) {
      if (childrens.type !== LineGenericCard) {
        throw new Error("O componente filho do componente GenericCard deve ser um LineGenericCard");
      }
    }

    for (let i = 0; i > childrens.length; i++) {
      const o = childrens[i];
      if (o.type !== LineGenericCard) {
        throw new Error("O componente filho do componente GenericTable deve ser um LineGenericCard");
      }
    }

    // o Line do map na realidade é o componente lineGenericCard
    if (this.props.children.constructor === Array) {
      lines = this.props.children.map((l, index) => {

        const leftTitle = l.props.leftTitle;
        const rightTitle = l.props.rightTitle;

        let leftText = l.props.leftText;
        let rightText = l.props.rightText;

        leftText = this.resolveValue(l.props, leftText, true);
        rightText = this.resolveValue(l.props, rightText, false);

        return (
          <CardRow key={index}>
            <div>
              <Label>{leftTitle}</Label>
              <Value>{leftText}</Value>
            </div>

            <div className="text-right">
              <Label>{rightTitle}</Label>
              <Value>{rightText}</Value>
            </div>
          </CardRow>
        )
      })

      this.setState({ lines });

    } else {
      const { props } = this.props.children;

      const leftTitle = props.leftTitle;
      const rightTitle = props.rightTitle

      let leftText = props.leftText;
      let rightText = props.rightText;

      leftText = this.resolveValue(props, leftText, true);
      rightText = this.resolveValue(props, rightText, false);

      lines = <CardRow>
        <div>
          <Label>{leftTitle}</Label>

          <Value>{leftText}</Value>
        </div>

        <div className="text-right">
          <Label>{rightTitle}</Label>
          <Value>{rightText}</Value>
        </div>
      </CardRow>
    }

    this.setState({ lines });
  }


  formataData = (strData, withHour, withDayOfWeek) => {

    if (!strData || strData === "") return;

    let aux = strData.split("T")
    let data = dayjs(aux[0])

    let day = data.get('date');
    let month = parseInt(data.get('month')) + 1;
    let year = data.get('year');
    let hour = data.get('hour');
    let minutes = data.get('minute');

    let daysOfWeek = ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sab'];

    if (day.toString().length === 1) day = '0' + day;
    if (month.toString().length === 1) month = '0' + month;

    if (hour.toString().length === 1) hour = '0' + hour
    if (minutes.toString().length === 1) minutes = '0' + minutes;

    if (withDayOfWeek) {
      return `${daysOfWeek[data.get('day')]} - ${day}/${month}/${year}`
    }

    if (day && month && hour) {
      if (day === 30 && month === 12 && year === 1899) return '\u00A0'
      return withHour ? `${day}/${month}/${year}  ${hour}:${minutes}` : `${day}/${month}/${year}`
    } else {
      return "";
    }

  }


  resolveValue = (props, value, isLeft) => {

    let aux = value;
    try {
      if (!aux || aux === "") return '\u00A0';
      if (isLeft) {
        //Formata Dinheiro
        if (props.moneyLeft) {
          aux = parseFloat(aux)
          aux = this.formatReal(aux);
        }
        //Formata Data
        if (props.lFd) {
          aux = aux.toString();
          aux = this.formataData(aux);
        }

      } else {
        // Formata Dinheiro
        if (props.moneyRight) {
          aux = parseFloat(aux);
          aux = this.formatReal(aux);
        }
        //Formata Data
        if (props.rFd) {
          aux = aux.toString();
          aux = this.formataData(aux);
        }
      }
    } catch (error) {
      console.log(error)
      return value;
    }
    return aux;
  }

  formatReal(valor) {
    if (!valor || valor === "") return;
    const formatado = valor.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
    return formatado;
  }



  render() {
    const { editavel } = this.props;
    return (
      <CardContainer>

        {
          editavel && <IconContainer>
            <IconButton onClick={this.props.handleEdit}>
              <EditIcon style={{ color: '#2991D6' }} />
            </IconButton>
          </IconContainer>
        }


        {this.props.title ? (<Title>{this.props.title}</Title>) : null}

        <DataContainer>
          {this.state.lines}

          {
            this.props.footer ? (
              <Footer>
                <div>
                  <Label>{this.props.footerTitle}</Label>
                  <Value>{this.props.footerText}</Value>
                </div>
              </Footer>
            ) : null
          }
        </DataContainer>

      </CardContainer>
    )
  }
}

GenericCard.protoTypes = {
  leftTitle: PropTypes.string,
  leftText: PropTypes.string,
  rightText: PropTypes.string,
  rightTitle: PropTypes.string,
  footer: PropTypes.bool,
  lFd: PropTypes.bool,
  rFd: PropTypes.bool,
  options: PropTypes.array
}