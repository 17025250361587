import React, { Component } from 'react';
import { Form } from 'semantic-ui-react';
import APIUser from "../../API/APIUser";
import GenericCard, { LineGenericCard } from "../../components/genericcard";
import Loading from "../../components/loading/Loading"
import EditPhone from "./components/editphone/"
import EditAdress from "./components/editadress"
import Alert from "../../components/alert/Alert"
import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@material-ui/icons/Add';
import CloneDadosUser from "./clone"
import EditIcon from '@material-ui/icons/Edit';
import AskAlert from "../../components/askalert/AskAlert"
import aux from '../../utils/auxiliar';
import { 
  LabelAd, 
  InfoAd, 
  ContainerInfoAd, 
  CornerOption,
  UserInfoContainer,  
  OtherInfoContainer, 
  ProfileContainer, 
  ProfileHeader, 
  PictureContainer, 
  UserName, 
  UserRE, 
  ProfileBody, 
  Label, 
  Value, 
  InfoRow, 
  AdOptionContainer, 
  UserInfoForm, 
  InfoGroup,
  IconContainer,
  Grid,
  StyledInput
} from "./style"
import APIChamados from '../../API/APIChamados';
import EditCNV from './components/editcnv';




export default class DadosUser extends Component {

  constructor(props) {
    super(props);
    this.state = {
      nextBtns: false,
      dadosEndereco: {},
      alocacoes: aux.getAlocacoes(),
      load: false,
      user: {},
      dependentes: [],
      loading: false,
      profilePic: null,
      currentPhoneNumber: "",
      editPhoneNumber: false,
      DDD: "",
      DDI: "",
      Phone: "",
      tipo: "",
      phoneID: 0,
      showAlert: false,
      messageAlert: '',
      isNewPhone: false,
      isNewAddress: false,
      openEditAdress: false,
      openOpAdiantamento: false,
      textAdiantamento: '',
      currentOpAdiant: {}
    }
  }


  componentDidMount() {

    aux.answerAPP();
    this.getData();
  }



  getData = async () => {
    this.setState({ loading: true });

    const user = await APIUser.getUserInfo() || {};
    const dependentes = await APIUser.getDependentes();
    const profilePic = await APIUser.getProfilePicture();

    this.setState({ user, dependentes, loading: false, profilePic });
  }




  changeBtns = () => {
    this.setState({ nextBtns: true })
  }




  openEditNumber = (phoneObj = {}) => {
    this.setState({
      DDI: phoneObj.ddi,
      DDD: phoneObj.ddd,
      Phone: phoneObj.telefone,
      tipo: phoneObj.tipo,
      phoneID: phoneObj.chaveTelefone,
      isNewPhone: phoneObj.telefone ? false : true,
      openEditNumber: true
    });
  }





  closeEditPhoneNumber = async (message, update) => {
    this.setState({
      openEditNumber: false,
      openEditAdress: false
    });

    if (update) {
      await this.getData();
    }

    if (message) {
      this.showMessage(message);
    }

  }

  showMessage = (message) => {
    this.setState({
      showAlert: true,
      messageAlert: message
    })
  }

  closeAlert = async () => {

    this.setState({
      showAlert: false,
      messageAlert: ''
    })
  }

  changeIndexTab = (index) => {

    this.setState({ activeIndex: index });
  }

  backButtons = () => {
    this.setState({ nextBtns: false })
  }


  openCNVEdit = () => {
    this.setState({ openCNVEdit: true })
  }

  
  closeCNVEdit = () => {
    this.setState({ openCNVEdit: false })
  }


  finishCNVEdit = () => {
    this.closeCNVEdit();
    this.getData();
  }

  


  openEditAdress = (e) => {
    const isNewAddress = e === 'new';
    this.setState({ openEditAdress: true, dadosEndereco: e, isNewAddress })
  }


  closeEditAdress = () => {
    this.setState({ openEditAdress: false })
  }





  openOptante = (current) => {

    let _str = "";
    if (current.opcao) {
      _str = `Atualmente você é optante pelo adiantamento. Deseja realmente se tornar NÃO OPTANTE ?`;
    } else {
      _str = `Atualmente você não é optante pelo adiantamento. Deseja realmente se tornar OPTANTE ?`;
    }
    this.setState({ openOpAdiantamento: true, textAdiantamento: _str, currentOpAdiant: current })
  }


  handleOptante = async () => {
    try {
      const { alteravel, opcao } = this.state.currentOpAdiant;
      this.setState({ openOpAdiantamento: false });


      // Troca direta
      if (alteravel === 1) {

        this.setState({ loading: true });

        const response = await APIUser.alterOpAdiantamento();

        this.setState({ loading: false });

        if (response) {
          await this.getData();
          this.showMessage('Alteração Concluída');
        } else {
          this.showMessage('Ops... Algo deu errado. Vamos tentar novamente');
        }
        // Troca via sac
      } else if (alteravel === 2) {
        this.setState({ loading: true });

        const ocorrencia = await APIUser.getOcorrenciaPadrao(3);

        if (!ocorrencia) {
          this.setState({ loading: false });
          return this.showMessage('Ops... Algo deu errado. Vamos tentar novamente.')
        }

        let _str;

        if (opcao) {
          _str = `Funcionário deseja se tornar NÃO OPTANTE pelo adiantamento.`;
        } else {
          _str = `Funcionário deseja se tornar OPTANTE pelo adiantamento`;
        }

        const body = {
          _ocorrencia: { key: ocorrencia },
          _titulo: 'Troca Adiantamento Salarial',
          _descricao: _str,
          _filesToUpload: []
        }


        const response = await APIChamados.setChamado(body);

        this.setState({ loading: false });

        if (response.err) {
          this.setState({ loading: false });
          return this.showMessage('Ops... Algo deu errado. Vamos tentar novamente.')
        }
        this.setState({ loading: false });
        return this.showMessage('Foi aberto um SAC solcitando a alteração. Por favor, verifique o andamento no módulo SAC.');

      } else {
        this.setState({ loading: false });
        return this.showMessage('Ops... Algo deu errado. Vamos tentar novamente.')
      }

    } catch (error) {
      this.setState({ loading: false });
      this.showMessage('Ops... Algo deu errado. Vamos tentar novamente');
    }
  }
























  render() {
    const {
      user,
      dependentes,
      profilePic, openEditNumber,
      DDD,
      DDI,
      Phone,
      tipo,
      phoneID,
      showAlert,
      messageAlert,
      isNewPhone,
      isNewAddress,
      openEditAdress,
      openCNVEdit,
    } = this.state;

    let email = null;
    if (user.email) email = user.email.email;

    let alterOp = false;
    let isOp = false;
    if (user.adiantamentoSalarial) {
      alterOp = user.adiantamentoSalarial.alteravel === 1 || user.adiantamentoSalarial.alteravel === 2;
      isOp = user.adiantamentoSalarial.opcao;
    }

    let opcoesVT = [];
    let auxDependentes = <p style={{ fontSize: "14px", textAlign: "center" }}>Não existe dependente cadastrado</p>
    let enderecos = <p style={{ fontSize: "14px", textAlign: "center" }}>Não existe endereço cadastrado</p>
    let telefones = <p style={{ fontSize: "14px", textAlign: "center" }}>Não existe telefone cadastrado</p>
    let hasPhone = false;
    let hasAdress = false;

    if (user.opcoesVT) {

      if (user.opcoesVT.length > 0) {
        opcoesVT = user.opcoesVT.map((o, index) => {
          return (
            <GenericCard title={o.nomeItinerario} key={index}>
              <LineGenericCard
                leftTitle="Optante"
                leftText={o.opcaoVT || '---'}

                rightTitle="Data de Início"
                rightText={o.dataInicio || '---'}
                rFd
              />

              <LineGenericCard
                leftTitle="Quantidade de Itinerário"
                leftText={o.qtdeItinerario || '---'}

                moneyRight
                rightTitle="Valor Unitário do Itinerário"
                rightText={o.valorItinerario || '---'}
              />
            </GenericCard>
          )
        });
      }
    }


    if (user.enderecos) {
      if (user.enderecos.length > 0) {
        hasAdress = true;
        enderecos = user.enderecos.map((e, index) => {
          return (
            <GenericCard title={e.tpEndereco} key={index} editavel={e.alteravel} handleEdit={() => this.openEditAdress(e)}>
              <LineGenericCard
                leftTitle="CEP"
                leftText={e.cep || '---'}

                rightTitle="Endereço"
                rightText={e.endereco + ', ' + e.numero}

              />

              <LineGenericCard
                leftTitle="Complemento"
                leftText={e.complemento || '---'}

              />

              <LineGenericCard
                leftTitle="Localidade"
                leftText={e.localidade || '---'}

                rightTitle={"Bairro"}
                rightText={e.nomeBairro || '---'}
              />
            </GenericCard>
          )
        });

      }
    }

    if (dependentes) {
      if (dependentes.length > 0) {

        auxDependentes = dependentes.map((d, index) => {
          return (
            <GenericCard title={d.nome} key={index}>
              <LineGenericCard
                leftTitle="Data de Nascimento"
                leftText={d.dtNascimento || '---'}
                lFd

                rightTitle="CPF"
                rightText={d.cpf || '---'}
              />

              <LineGenericCard
                leftTitle="Registro de Nascimento"
                leftText={d.dtNascimento || '---'}
                lFd
                rFd
                rightTitle="Data Registro de Nascimento"
                rightText={d.dtRegistroNascimento}
              />


              <LineGenericCard
                leftTitle="Estado Civil"
                leftText={d.estadoCivil || '---'}

                rightTitle="Sexo"
                rightText={d.sexo || '---'}
              />
            </GenericCard>
          )
        })
      }
    }


    if (user.telefones) {
      if (user.telefones.length > 0) {
        hasPhone = true;
        telefones = user.telefones.map((tel, index) => {

          return (
            <GenericCard title={tel.descricaotipo} key={index} editavel={tel.alteravel} handleEdit={() => this.openEditNumber(tel)}>
              <LineGenericCard
                leftTitle="DDD"
                leftText={tel.ddd || '---'}

                rightTitle="DDI"
                rightText={tel.ddi || '---'}
              />

              <LineGenericCard
                leftTitle="Telefone"
                leftText={tel.telefone || '---'}
              />
            </GenericCard>
          )
        })


      }
    }


    const hasCNVDatePassed = new Date() > new Date(user.dtValidadeCnv);
    return (
      <>
        <Loading show={this.state.loading} />
        <Alert show={showAlert} title="" message={messageAlert} onClose={this.closeAlert} />
        <CloneDadosUser show={!this.state.load} />

        {
          openEditNumber && <EditPhone
            tipo={tipo}
            DDD={DDD}
            DDI={DDI}
            Phone={Phone}
            ID={phoneID}
            close={this.closeEditPhoneNumber}
            isNewPhone={isNewPhone}
          />
        }

        {
          openEditAdress && <EditAdress
            dadosEndereco={this.state.dadosEndereco}
            close={this.closeEditPhoneNumber}
            isNewAddress={isNewAddress}

          />
        }

        {
          openCNVEdit && <EditCNV 
            closeModal={this.closeCNVEdit}
            reload={this.finishCNVEdit}
            values={{
              cnv: user.cnv,
              date: user.dtValidadeCnv,
              type: user.tipoCnv
            }}
          />
        }


        <div className="" onLoad={() => { this.setState({ load: true }) }} style={{ opacity: !this.state.load ? "0" : "1", transition: "all .7s" }}>
          <UserInfoContainer>
            <Grid>
              
              <ProfileContainer>

                <ProfileHeader>
                  <PictureContainer>
                    {
                      !profilePic && <img src="assets/user.png" className="img-responsive" alt="user" />
                    }

                    {
                      profilePic && <img src={profilePic} className="img-responsive" alt="user" />
                    }

                  </PictureContainer>

                  <UserName>{user.nome}</UserName>
                  <UserRE>RE: {user.matricula}</UserRE>
                </ProfileHeader>

                <ProfileBody>
                  <InfoRow>
                    <div className="itemGroupDadosUser">
                      <Label>Data de nascimento</Label>
                      <Value>{aux.formataDataUTC(user.nascimento)}</Value>
                    </div>

                    <div className="itemGroupDadosUser text-right">
                      <Label>Cidade de Nascimento</Label>
                      <Value>{user.cidadeNascimento}</Value>
                    </div>
                  </InfoRow>



                  <InfoRow>
                    <div className="itemGroupDadosUser text-left">
                      <Label>Estado Civil</Label>
                      <Value>{user.estadoCivil}</Value>
                    </div>

                    <div className="itemGroupDadosUser text-right">
                      <Label>Escolaridade</Label>
                      <Value>{user.escolariedade}</Value>
                    </div>
                  </InfoRow>


                  <InfoRow>
                    <div className="itemGroupDadosUser text-left">
                      <Label>E-mail</Label>
                      <Value>{email}</Value>
                    </div>

                    <div className="itemGroupDadosUser text-right">
                      <Label>Sexo</Label>
                      <Value>{user.sexo}</Value>
                    </div>
                  </InfoRow>
                </ProfileBody>
              </ProfileContainer>


              <OtherInfoContainer>

                {/* DOCUMENTOS     */}
                  <UserInfoForm>
                    <InfoGroup>
                      <h6>Documentos</h6>

                      <Form.Group widths='equal' className="">
                        <Form.Input fluid label='CPF' placeholder='CPF' value={user.cpfrne} />
                        <Form.Input fluid label='RG' placeholder='RG' value={user.rg} />
                        <Form.Input fluid label='UF' placeholder='UF' value={user.ufRg} />
                        <Form.Input fluid label='Data de emissão RG' placeholder='Data de emissão RG' value={aux.formataDataUTC(user.dtEmissaoRg)} />
                      </Form.Group>


                      <Form.Group widths='equal' className="">
                        <Form.Input fluid label='Número da CNH - Categoria' placeholder='Número da CNH' value={user.habilitacao} />
                        <Form.Input fluid label='Vencimento CNH' placeholder='Vencimento CNH' value={aux.formataDataUTC(user.dtVencHabilitacao)} />
                        <Form.Input fluid label='Número PIS' placeholder='Número PIS' value={user.pis} />
                        <Form.Input fluid label='Cartão Nacional de Saúde' placeholder='Cartão Nacional de Saúde' value={user.cartaoNacionalSaude} />
                      </Form.Group>



                      <Form.Group widths='equal' className="">
                        <Form.Input fluid label='Título de Eleitor' placeholder='Título de Eleitor' value={user.tituloEleitor} />
                        <Form.Input fluid label='Número da Carteira de Trabalho' placeholder='Número da Carteira de Trabalho' value={user.ctps}/>
                      </Form.Group>
                    </InfoGroup>
                  </UserInfoForm>

                  
                  {!!user.validaCNV && <UserInfoForm>
                    <InfoGroup>
                      <h6>CNV</h6>
                      {user.alteravelCNV && <CornerOption onClick={() => this.openCNVEdit()}>
                        <EditIcon style={{ color: '#2991D6', fontSize: 18 }} />
                      </CornerOption>}
                      <Form.Group widths='equal' className="">
                        <Form.Input fluid label='CNV' placeholder='CNV' value={aux.cnvMask(user.cnv)} />
                        <StyledInput fluid label='Validade CNV' placeholder='Validade CNV' value={aux.formataDataUTC(user.dtValidadeCnv)} hasPassed={hasCNVDatePassed}/>
                        <Form.Input fluid label='Tipo CNV' placeholder='Tipo CNV' value={user.tipoCnv === 2 ? 'Protocolo' : 'Registro'} />
                      </Form.Group>

                    </InfoGroup>
                   
                  </UserInfoForm>}

                {/* DADOS DE ADMISSAO   */}
                  <UserInfoForm>
                    <InfoGroup>
                      <Form.Group widths='equal'>
                        <h6>Dados de Admissão</h6>
                        <Form.Input fluid label='Data de Admissão' placeholder='Data de Admissão' value={aux.formataDataUTC(user.dtAdmissao)} />
                        <Form.Input fluid label='Validade do Crachá' placeholder='Validade do Crachá' value={aux.formataDataUTC(user.dtValidadeCracha)} />
                        <Form.Input
                          fluid label='Término primeiro período de Experiência'
                          placeholder='Término primeiro período de Experiência'
                          value={aux.formataDataUTC(user.dtPrimeiroPeriodoExperiencia)}
                        />
                        <Form.Input
                          fluid label='Término segundo período de Experiência'
                          placeholder='Término segundo período de Experiência'
                          value={aux.formataDataUTC(user.dtSegundoPeriodoExperiencia)}
                        />
                      </Form.Group>
                      <Form.Group widths='equal'>
                        <Form.Input fluid label='Escala de Trabalho' placeholder='Escala de Trabalho' value={user.escalaTrabalho} />
                        <Form.Input fluid label='Nome do local de serviço' placeholder='Nome do local de serviço' value={user.nomeLocalservico} />
                        <Form.Input fluid label='Nome do cliente de alocação' placeholder='Nome do cliente de alocação' value={user.nomeCliente} />
                      </Form.Group>

                      <Form.Group widths='equal' className="">
                        <Form.Input fluid label='Cargo' placeholder='Cargo' value={user.nomeCargo} />
                        <Form.Input fluid label='Convenção' placeholder='Convenção' value={user.convecaoColetiva} />
                      </Form.Group>


                      <AdOptionContainer>
                        <LabelAd>Optante por Adiantamento</LabelAd>
                        <ContainerInfoAd>
                          <InfoAd>{isOp ? 'Sim' : 'Não'}</InfoAd>
                          {
                            alterOp && <IconButton style={{ marginTop: -12 }} onClick={() => this.openOptante(user.adiantamentoSalarial)}>
                              <EditIcon style={{ color: '#2991D6', fontSize: 18 }} />
                            </IconButton>
                          }
                        </ContainerInfoAd>
                      </AdOptionContainer>
                    </InfoGroup>
                  </UserInfoForm>

                <InfoGroup rowOriented>
                  <h6>Opções de Vale Transporte</h6>
                  {opcoesVT}
                </InfoGroup>

                {/* CONTATOS */}
                  <InfoGroup rowOriented>
                    <h6>Endereços</h6>
                    {
                      !hasAdress && <IconContainer>
                        <IconButton onClick={() => this.openEditAdress('new')}>
                          <AddIcon style={{ color: '#2991D6' }} />
                        </IconButton>
                      </IconContainer>
                    }

                    {enderecos}
                  </InfoGroup>

                  <InfoGroup rowOriented>
                    <h6>Contatos</h6>
                    {
                      !hasPhone && <IconContainer>
                        <IconButton onClick={this.openEditNumber}>
                          <AddIcon style={{ color: '#2991D6' }} />
                        </IconButton>
                      </IconContainer>
                    }

                    {telefones}
                  </InfoGroup>


                {/* DEPENDENTES */}
                
                <InfoGroup rowOriented>
                  <h6>Dependentes</h6>
                  {auxDependentes}
                </InfoGroup>


              </OtherInfoContainer>
            </Grid>
          </UserInfoContainer>
        </div>
        <AskAlert
          show={this.state.openOpAdiantamento}
          message={this.state.textAdiantamento}
          disagree={() => this.setState({ openOpAdiantamento: false, textAdiantamento: "" })}
          agree={this.handleOptante}
        />
      </>
    );
  }
}




