import React, { Component } from 'react';
import aux from "../../utils/auxiliar";
import { Icon } from 'semantic-ui-react'
import { COR_ATRASO, COR_FALTA, COR_SEM_MARCACAO, COR_FERIAS } from "./../../utils/constantes";
import { Popup } from 'semantic-ui-react'
import storage from '../../storage';
import APIUser from "../../API/APIUser";
import DemonstrativoPonto from "./demonstrativoponto"
import Alert from "../alert/Alert"
import { Body, Container, EspelhoPontoTable, Header, NavigatorContainer, TableBody, TableRow } from './style';
import { SubTitle } from '../../global/style';

export default class CardListPonto extends Component {
  static defaultProps = {
    options: []
  }
  //31703168852
  constructor(props) {
    super(props);
    this.state = {
      tablePonto: [],
      qtPagesPonto: 0,
      atualPagePontos: 1,
      activeEfeito: false,
      atualHorario: [],
      userName: "",
      itemTicket: null,
      showAlert: false,
      messgaeAlert: ''
    }
  }


  async componentDidMount() {
    let size = 0;
    if (this.props.options.length > 0) size = Math.ceil(this.props.options.length / 7);
    const userName = await APIUser.getUserName();
    this.setState({ qtPagesPonto: size, userName }, () => this.montaTablePonto())
  }


  componentDidUpdate(oldProps) {
    if (this.props.options !== oldProps.options) {
      let size = 0;

      if (this.props.options.length > 0) {
        size = Math.ceil(this.props.options.length / 7);
      }

      this.setState({ qtPagesPonto: size }, () => this.montaTablePonto())
    }
  }


  resolveJornada = (item) => {
    const str = `Entrada ${aux.extraiHorario(item.hrEntradaVaga)} Almoço ${aux.extraiHorario(item.hrSaiAlmocoVaga)} 
      Retorno ${aux.extraiHorario(item.hrRetalmocoVaga)} Saída ${aux.extraiHorario(item.hrSaidaVaga)}
      `
    return str;
  }




  voltaTablePontos = () => {
    this.setState({ activeEfeito: true })
    setTimeout(() => {
      this.setState({ activeEfeito: false });
    }, 500)
    this.montaTablePonto(this.state.atualPagePontos - 1);
  }


  avancaTablePontos = () => {
    this.setState({ activeEfeito: true })
    setTimeout(() => {
      this.setState({ activeEfeito: false });
    }, 500)
    this.montaTablePonto(this.state.atualPagePontos + 1);
  }



  resolveStyle = (item, type) => {
    if (type === "entrada") {
      if (item.entrada.temOcorrencia) return { color: COR_ATRASO, fontWeight: "bold" }
      else if (item.entrada.tpMarcacao === "0" || item.entrada.tpMarcacao === "") return { color: COR_SEM_MARCACAO, fontWeight: "bold" }
      else return { color: COR_ATRASO, fontWeight: "bold" }
    } else if (type === "saidaAlmoco") {
      if (item.saidaAlmoco.temOcorrencia) return { color: COR_ATRASO, fontWeight: "bold" }
      else if (item.saidaAlmoco.tpMarcacao === "0" || item.saidaAlmoco.tpMarcacao === "") return { color: COR_SEM_MARCACAO, fontWeight: "bold" }
      else return { color: COR_ATRASO, fontWeight: "bold" }
    } else if (type === "retornoAlmoco") {
      if (item.retornoAlmoco.temOcorrencia) return { color: COR_ATRASO, fontWeight: "bold" }
      else if (item.retornoAlmoco.tpMarcacao === '0' || item.retornoAlmoco.tpMarcacao === "") return { color: COR_SEM_MARCACAO, fontWeight: "bold" }

      else return { color: COR_ATRASO, fontWeight: "bold" }
    } else if (type === "saida") {
      if (item.saida.temOcorrencia) return { color: COR_ATRASO, fontWeight: "bold" }
      else if (item.saida.tpMarcacao === '0' || item.saida.tpMarcacao === "") return { color: COR_SEM_MARCACAO, fontWeight: "bold" }
      else return { color: COR_ATRASO, fontWeight: "bold" }
    }
  }



  openSendAtestado = (item) => {
    if (!item.temAtestado) return;


    if (item.permiteAtestado === 0) {
      return this.showMessage('Tempo limite para justificativa expirado');
    }

    const codeAtestado = storage.getCodeDisciplaAtestado();
    if (codeAtestado === 0 || codeAtestado === '0') {
      this.showMessage("Empresa não parametrizada para o envio de atestado. Por favor, contate o administrador do seu sistema.")
      return;
    }
    this.setState({ itemTicket: { item: item, type: null, isFalta: true } })
  }



  resolveTicket = (item, type) => {
    this.setState({ itemTicket: { item: item, type } })
  }


  showMessage = (message) => {
    this.setState({ messgaeAlert: message, showAlert: true });
  }

  closeAlert = () => this.setState({ messgaeAlert: '', showAlert: false })


  montaTablePonto = (page = 1) => {

    let pontosToMap = [];

    if (page > this.state.qtPagesPonto) {
      if (this.state.qtPagesPonto === 0) {
        this.setState({
          tablePonto: [],
          atualPagePontos: 1
        })
      }
      return;
    }

    if (page === 0) page = 1;

    pontosToMap = this.props.options.filter(o => o.page === page);

    const tablePonto = pontosToMap.map((item, index) => {
      return (
        <TableRow key={index}>
          {
            item.mostraPonto ? (
              <>
                <Popup content={this.resolveJornada(item)} size='large' trigger={<td colSpan="2" className="text-center dateColumn">{aux.formataDataUTC(item.data, false, true)}</td>} />

                <td className="text-left" id={index === 1 ? "firstButtonEntrada" : ""} style={this.resolveStyle(item, "entrada")} onClick={() => this.resolveTicket(item, "entrada")} >

                  {
                    item.entrada.tpMarcacao === "0" || item.entrada.tpMarcacao === "" ? (
                      <p>--</p>
                    ) : (
                      <>
                        {aux.extraiHorario(item.entrada.horario)}{item.entrada.temOcorrencia ? (<Icon name="warning" className="warningIcon" />) : ""}
                      </>
                    )
                  }

                </td>


                <td className="text-left" id={index === 1 ? "secondButtonSaida" : ""} style={this.resolveStyle(item, "saidaAlmoco")} onClick={() => this.resolveTicket(item, "saidaAlmoco")}>

                  {
                    item.saidaAlmoco.tpMarcacao === "0" || item.saidaAlmoco.tpMarcacao === "" ? (
                      <p>--</p>
                    ) : (
                      <>
                        {aux.extraiHorario(item.saidaAlmoco.horario)}{item.saidaAlmoco.temOcorrencia ? (<Icon name="warning" className="warningIcon" />) : ""}
                      </>
                    )
                  }

                </td>


                <td className="text-left" style={this.resolveStyle(item, "retornoAlmoco")} onClick={() => this.resolveTicket(item, "retornoAlmoco")}>

                  {
                    item.retornoAlmoco.tpMarcacao === "0" || item.retornoAlmoco.tpMarcacao === "" ? (
                      <p>--</p>
                    ) : (
                      <>
                        {aux.extraiHorario(item.retornoAlmoco.horario)}{item.retornoAlmoco.temOcorrencia ? (<Icon name="warning" className="warningIcon" />) : ""}
                      </>
                    )
                  }

                </td>

                <td className="text-left" style={this.resolveStyle(item, "saida")} onClick={() => this.resolveTicket(item, "saida")}>

                  {
                    item.saida.tpMarcacao === "0" || item.saida.tpMarcacao === "" ? (
                      <p>--</p>
                    ) : (
                      <>
                        {aux.extraiHorario(item.saida.horario)}{item.saida.temOcorrencia ? (<Icon name="warning" className="warningIcon" />) : ""}
                      </>
                    )
                  }

                </td>

              </>
            ) : (
              <>
                {
                  <>

                    {
                      item.temFalta ? (
                        <>
                          <Popup content={this.resolveJornada(item)} size='large' trigger={<td colSpan="2" className="text-center dateColumn">{aux.formataDataUTC(item.data, false, true)}</td>} />
                          <td className="text-center" colSpan="5" style={{ background: COR_FALTA, color: "#FFF" }} onClick={() => this.openSendAtestado(item)} ><strong>{item.mensagem}</strong></td>
                        </>

                      ) : (
                        <>
                          {
                            item.mensagem !== "" ? (
                              <>
                                <Popup content={this.resolveJornada(item)} size='large' trigger={<td colSpan="2" className="text-center dateColumn">{aux.formataDataUTC(item.data, false, true)}</td>} />
                                <td className="text-center" colSpan="5" style={{ background: COR_FERIAS, color: "#FFF" }} >{item.mensagem}</td>
                              </>

                            ) : (<td className="text-center" colSpan="6" style={{ background: "#fff", color: "#FFF" }} >&nbsp;</td>)
                          }
                        </>

                      )
                    }

                  </>
                }
              </>
            )
          }

        </TableRow>
      )
    });

    this.setState({
      tablePonto,
      atualPagePontos: page
    })
  }




  render() {

    const { tablePonto, messgaeAlert, showAlert } = this.state;

    return (
      <>
        <DemonstrativoPonto item={this.state.itemTicket} userName={this.state.userName} pis={this.props.pis} />
        {
          showAlert && <Alert show={true} title="" message={messgaeAlert} onClose={this.closeAlert} />
        }

        <Container>
          <Header>
            <SubTitle size="12">ESPELHO PONTO</SubTitle>

            <NavigatorContainer>
              <Icon name="chevron circle left" size="big" disabled={this.state.atualPagePontos === 1} id="iconDireita"  onClick={this.voltaTablePontos} />
              <Icon name="chevron circle right" size="big" disabled={this.state.atualPagePontos === this.state.qtPagesPonto} id="iconEsquerda"  style={{marginLeft: 10}} onClick={this.avancaTablePontos} />
            </NavigatorContainer>
          </Header>

          <Body>
            <EspelhoPontoTable>
              <thead>
                <tr>
                  <th style={{width: '10%'}}>&nbsp;</th>
                  <th style={{width: '10%'}}>&nbsp;</th>
                  <th>ENTRADA</th>
                  <th>SAÍDA</th>
                  <th>RETORNO</th>
                  <th>SAÍDA</th>
                </tr>
              </thead>

              <TableBody fadeIn={this.state.activeEfeito}>
                {tablePonto}
              </TableBody>

            </EspelhoPontoTable>
          </Body>
        </Container>
      </>
    );
  }
}
