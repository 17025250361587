import axios from 'axios';
import storage from '../storage/index';
import APIHk from "../API/APIHk";
import aux from '../utils/auxiliar';


/*
		A princípio temos dois tokens. Um de licença e outro do acesso a API do cliente. 

		O ecosistema desse arquivo é assim : 
		A primeira chamada que deve ser feita pela aplicacao é o getLicenca, pois através dele, será possível
		acessar os demais endpoints das APis. Esse método solicita o token de licenca para API e o armazena no storage para futuro uso.
		Com pose nesse token, podemos efetuar a chamada de login, passando ele + usuario e senha em base64 para api para obtermos o token 
		de acesso. A funcao que está dentro do valida login, chamada de getHeader login, nada mais é que um criador de header seguindo
		os padroes da APi do cliente. Que é, gerar um base64 contendo um json com usuario, senha e licenca, cria a property de 
		Authorization no header e passa o Bearer + o token de licenca. Com esse header retornado, podemos enfim validar o login na api. Estando tudo certo,
		já posso acessar a plataforma e seus metodos, sempre passando um o

*/

const API = {

	getHeaderLogin: function (user, pass, codempresa) {

		const jsonUsuario = {
			"usuario": user,
			"senha": pass,
			"empresa": codempresa,
			"licenca": storage.getLicensa()
		}

		let jsonEnvia = JSON.stringify(jsonUsuario);
		jsonEnvia = jsonEnvia.trim();

		const base64 = aux.base64Encode(jsonEnvia);
		const token = base64;

		const header = {
			'Authorization': `Bearer ${token}`,
			'Content-Type': 'application/json'
		};
		return header;
	},

	cadNewPassword: async function (confirmPassword, newPassword, cpf, codempresa) {
		try {

			const header = aux.getHeaderPadrao();

			const data = { "novasenha": aux.base64Encode(newPassword), "confirmacaonovasenha": aux.base64Encode(confirmPassword) };

			const response = await axios.post(`${storage.getUrlClient()}/TrocarSenha`, data, { headers: header });

			const { err, message } = aux.trataErroAPI(response);

			if (err) {
				console.log(message);
				return 500;
			}

			return await this.validaLogin(cpf, newPassword, codempresa);

		} catch (error) {
			console.log(error);
		}
	},

	//cadNewPassword


	cadNewPassword: async function (confirmPassword, newPassword, cpf, url_client, token_acesso) {
		try {
			let token = { login: token_acesso, licenca: storage.getLicensa() }

			token = aux.base64Encode(JSON.stringify(token).trim());

			const header = {
				'Authorization': `Bearer ${token}`,
				'Content-Type': 'application/json'
			}

			const data = {
				"novasenha": aux.base64Encode(newPassword),
				"confirmacaonovasenha": aux.base64Encode(confirmPassword)
			};

			const response = await axios.post(`${url_client}/TrocarSenha`, data, { headers: header });

			if (response.data.status !== 0) {
				return {
					error: true,
					message: response.data.mensagem
				}
			}

			return {
				error: false,
			}

		} catch (error) {
			return {
				error: true,
				message: error
			}
		}
	},


	getQuesitons: async function (url, cpf) {
		try {

			let token = {
				licenca: storage.getLicensa()
			}
			token = aux.base64Encode(JSON.stringify(token).trim());

			const header = {
				'Authorization': `Bearer ${token}`,
				'Content-Type': 'application/json'
			}

			const response = await axios.post(`${url}/SolicitarAcesso`, { cpf }, { headers: header });

			if (response.data.status !== 0) {
				return {
					error: true,
					message: response.data.mensagem
				}
			}

			return {
				perguntas: response.data.perguntas,
				telefone: response.data.telefone_admpessoal
			}

		} catch (error) {
			return {
				error: true,
				message: error
			}
		}
	},




	sendQuestionsAnswers: async function (url, body) {
		try {
			let token = {
				licenca: storage.getLicensa()
			}
			token = aux.base64Encode(JSON.stringify(token).trim());

			const header = {
				'Authorization': `Bearer ${token}`,
				'Content-Type': 'application/json'
			}

			const response = await axios.post(`${url}/ValidarRespostas`, body, { headers: header });

			if (response.data.status !== 0) {
				return {
					error: true,
					message: response.data.mensagem
				}
			}

			return {
				error: false,
				...response.data
			}
		} catch (error) {
			return {
				error: true,
				message: error
			}
		}
	},



	getLogoEmpresaLogada: async function () {
		try {

			const header = {
				'Authorization': `Bearer ${storage.getLicensa()}`,
				'Content-Type': 'application/json'
			};

			let codEmpresa = storage.getCodEmpresa();

			if (codEmpresa) codEmpresa = parseInt(codEmpresa)
			else return "";

			const response = await APIHk.get(`/logoempresa/${codEmpresa}`, { headers: header });

			const { err, message } = aux.trataErroAPI(response);
			if (err) {
				console.log(message);
				return null;
			}

			return response.data.imageSarApp;

		} catch (error) {
			console.log(error)
			return null;
		}
	},


	isValidRoute: async function (rota) {
		if (!rota) return false;
		try {
			const header = aux.getHeaderPadrao();

			const response = await axios.get(`${storage.getUrlClient()}/validaAcessoAcao/${rota}`, { headers: header });

			const { err } = aux.trataErroAPI(response);

			return !err;

		} catch (error) {

			return false;
		}
	},

	getLicenca: async function (paramCpf) {
		try {

			let cpf = paramCpf.replace(/[.-]/g, '');

			const header = {
				"Content-Type": "application/json"
			};

			const data = {
				"codigoaplicativo": 123,
				"cpf": cpf
			};




			const response = await APIHk.post(`/licencas`, data, { headers: header });


			const { err, message } = aux.trataErroAPI(response);

			if (!err) {
				storage.setLicenca(response.data.token);
				return 200;
			} else {
				console.log(message)
				return response.status;
			}

		} catch (error) {
			console.log(error)
			if (error.response) {
				return error.response.status;
			} else {
				return 502;
			}
		}
	},


	getNewTokenLicenca: function () {
		return 200;
	},


	getNewTokenAcesso: async function () {
		try {
			const header = storage.getFirstHeader();
			const response = await axios.get(`${storage.getUrlClient()}/login`, { headers: header });
			const { data } = response;
			if (data) {
				if (!aux.trataErroAPI(response).err) {

					let tokenAuth = { login: data.token, licenca: storage.getLicensa() }


					tokenAuth = JSON.stringify(tokenAuth);
					tokenAuth = aux.base64Encode(tokenAuth);

					storage.setaccess_token(data.token);
					storage.setTokenLoginPlusLicenca(tokenAuth);
					storage.setFirstHeader(header);

					return 200
				} else {
					console.log(aux.trataErroAPI(response).message)
					return 401;
				}
			}
		} catch (error) {
			console.log(error.response);
			return 500;
		}
	},

	validaLogin: async function (user, pass, empresa) {
		try {

			user = user.replace(/[.-]/g, '');
			const header = this.getHeaderLogin(user, pass, empresa.codigo);

			const response = await axios.get(`${storage.getUrlClient()}/login`, { headers: header });
			console.log(response)
			const { data } = response;
			if (data) {
				if (!aux.trataErroAPI(response).err) {

					// Aqui é gerado um objeto para posteriormente ser convertido em base64, que será minha "credencial para o acesso aos outros metodos da API"
					let tokenAuth = { login: data.token, licenca: storage.getLicensa() }

					// Aqui eu vou armazenando os tokens para posterior uso. O setFirstHeader, serve para eu pedir um novo token de acesso, Pois esse já contém aquele json com 
					// o header certinho para o consumo.
					tokenAuth = JSON.stringify(tokenAuth);
					tokenAuth = aux.base64Encode(tokenAuth);
					storage.setaccess_token(data.token);
					storage.setTokenLoginPlusLicenca(tokenAuth);
					storage.setFirstHeader(header);
					storage.setEmpresaLogada(empresa);
					storage.setLoggedUser({name: data.nome, re: data.re});

					const { hkse } = aux.decodeJwt(data.token);

					if (hkse) return 403;

					return 200
				} else {
					console.log(aux.trataErroAPI(response).message)
					return 401;
				}
			}

		} catch (error) {
			console.log(error.respsonse);
			return 500;
		}
	},


	setLogAcesso: function (dadosUser) {
		try {
			const header = {
				"Content-Type": "application/json",
				'Authorization': `Bearer ${storage.getLicensa()}`,
			};

			const body = {
				"cpf": dadosUser.cpf.replace(/[.-]/g, ''),
				"codigoempresa": dadosUser.codempresa
			}

			APIHk.post(`/setlogacesso`, body, { headers: header });

		} catch (error) {
			console.log(error);
		}
	}


};

export default API;