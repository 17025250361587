import styled from "styled-components";

export const Container = styled.div`
  display: grid;
  grid-template-columns: 300px auto;
  width: 100%;
  gap: 20px;
  height: calc(100vh - 140px);
`

export const ContainerMenu = styled.div`
  display: flex;
  background: #fff;
  flex-direction: column;
  flex: 1;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
`

export const ContainerFrame = styled.div`
  display: flex;
  flex: 1;
  padding: 10px;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
  >span{
    color: #999
  }
`

export const Item = styled.div`  
  border-bottom: .3px solid #eee;
  padding: 14px;
  cursor: pointer;
  >span{
    font-weight: 400;
  }
  &:hover{
    background: #eee;
  }
`