import styled from "styled-components"


export const Container = styled.div`
  max-width: 100%;
  height: 100vh;
  display: grid;
  grid-template-columns: 2fr 1fr;
  background: #fff;
  @media(max-width: 924px) {
    grid-template-columns: 1fr;    
  }

`


export const SvgContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  background: #F9F9F9;
  justify-content: center;
  align-items: center;
  >img{
    max-width: 90%;
  }

  >h1{
    text-align: center;
    font-size: ${props => props.theme.fontSize.large};
    letter-spacing: 1px;
    font-family: ${props => props.theme.font} !important;
  }
  >h1>span{
    font-weight: 400;
  }
`

export const LoginContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: center;
  align-items: center;
  padding: 0 40px;

  >h2{
    font-size: ${props => props.theme.fontSize.large};
    letter-spacing: 1px;
    font-family: ${props => props.theme.font} !important;
    margin-bottom: 20px;
  }
  >input{
    margin-bottom: 20px;
  }

  >span{
    margin-top: 15px;
    font-family: ${props => props.theme.font} !important;
    cursor: pointer;
  }
  >select{
    width: 100%;
    display: block;
    padding: 10px 10px;
    margin-bottom: 20px;
    background: #eee;
    border: none;
    font-family: ${props => props.theme.font} 
  }

  @media(max-width: 924px) {
    justify-content: flex-start;
    align-items: center; 
    padding-top: 20px;
  }
`


export const BackButton = styled.button`
  width: 100%;
  border: none;

  font-family: ${props => props.theme.font} !important;
  background: none;  
  color :${props => props.theme.colors.red};
  padding:10px;
  font-weight: 400;


`

export const ButtonAcess = styled.button`
  width: 100%;
  border: none;
  border-radius: 20px;
  font-family: ${props => props.theme.font} !important;
  background: ${props => props.theme.colors.primary};  
  color : #fff;
  padding:10px;
  font-weight: 400;

  &:hover{
    background: #2382bc;
  }
`


export const PasswordContainer = styled.div`
  width: 100%;
`