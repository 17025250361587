import React from 'react';
import PropTypes from "prop-types";
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import { ButtonsContainer, TextContent, Title } from './style';


const AskAlert = ({ show, agree, disagree, message }) => {
	return (
		show ? (
			<Dialog open={show} >
				<Title style={{fontSize: 24}}>ATENÇÃO</Title>
				<DialogContent>
					<TextContent>
						{message}
					</TextContent>
				</DialogContent>
				<ButtonsContainer>
					<Button onClick={disagree} color="primary">
						Cancelar
                      </Button>
					<Button onClick={agree} color="primary" autoFocus>
						Sim
                     </Button>
				</ButtonsContainer>
			</Dialog>
		) : null
	)
};

AskAlert.propTypes = {
	agree: PropTypes.func,
	disagree: PropTypes.func,
	message: PropTypes.string
}

export default AskAlert;
