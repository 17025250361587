import axios from "axios";
import storage from '../storage/index';
import aux from '../utils/auxiliar';


export const getConteudoArquivoAtestado = async () => {
  try {
    const header = aux.getHeaderPadrao();
    const url = storage.getUrlClient();
    const response = await axios.get(`${url}/OcorrDisciplinarPadraoAtestado`, { headers: header });

    const { err } = aux.trataErroAPI(response);

    if (err) {
      return 0
    } else {
      return response.data.conteudoArquivo;
    }

  } catch (error) {
    return 0;
  }
}

export const postAtestado = async (_files, conteudoArquivo, descricao) => {
  try {
    const header = aux.getHeaderPadrao();
    const url = storage.getUrlClient();

    const qtFiles = _files.length;
    let sendedFiles = 0;


    const response = await axios.post(`${url}/InclusaoOcorrDisciplinarApp`, {}, { headers: header });

    const { err, message } = aux.trataErroAPI(response);

    if (!err) {
      const codOcorrencia = response.data.histDisciplinar;
      for (let i = 0; i < qtFiles; i++) {
        try {

          const file = _files[i];
          const anexo = {
            histdisciplinar: codOcorrencia,
            conteudoArquivo: conteudoArquivo,
            nomeOriginal: file.fileName,
            descricao,
            anexo: file.file
          }
          console.log(anexo)
          const responseInclusao = await axios.post(`${url}/OcorrDisciplinarInclusaoAnexo`, anexo, { headers: header });
          const { err, message } = aux.trataErroAPI(responseInclusao);

          if (!err) sendedFiles++
          else {
            console.log(message)
            return false
          }

        } catch (error) {
          console.log(error);
          return false;
        }
      }
      if (sendedFiles === qtFiles) return true
      else return false

    } else {
      console.log(message);
      return false;
    }

  } catch (error) {
    console.log(error)
    return false;
  }
}