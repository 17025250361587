import styled from "styled-components";
import Fab from '@material-ui/core/Fab';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  top : 0;
  left: 0;
  z-index: 4000;
  background: rgba(0,0,0,.6);
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: center;
`

export const Header = styled.div`
  width: 100%;
  height: 55px;
  background: #4B4B4B;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`

export const OptionButton = styled(Fab)`
  width: 40px !important;
  height: 40px !important;
  background: none !important;
  box-shadow: none !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;  
  margin-right : -4px !important;
  padding-right: 20px; 

  ${props => props.marginRight && `margin-right: 20px !important;`}
`

export const ImageContainer = styled.div`
  width: 90%;
  height: 90%;   
  margin: 20px 20px 0;

  img {
    max-width: 100%;
    max-height: 100%;
    display: block;
    margin : 0 auto;
  }
`

export const PDFContainer = styled.div`
  width: 100%;
  height: calc(100% - 40px);   

  overflow: hidden;
  position: relative;

  iframe {
    border: none;
  }
`