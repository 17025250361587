import React from 'react';
import PropTypes from 'prop-types';
import '../../styleGlobal.css';
import { 
	Body,
	Footer,
	Header,
	ModalBox,
	ModalContainer, 
	OkButton
} from './style';


const Alert = ({ show, message, title, onClose }) => {
	let close = function () { };
	if (onClose) close = onClose;
	return (
		show ? (
			<ModalContainer>
				<ModalBox>
					<Header>
						<h2>{title ? title : 'Atenção'}</h2>
					</Header>
					<Body>
						<p>{message}</p>
					</Body>
					<Footer>
						<OkButton onClick={() => close()}>Ok</OkButton>
					</Footer>
				</ModalBox>
			</ModalContainer>
		) : null
	)
};

Alert.propTypes = {
	onClose: PropTypes.func.isRequired,
	message: PropTypes.string.isRequired,
	title: PropTypes.string.isRequired,
	show: PropTypes.bool.isRequired,
}

export default Alert;
