import axios from 'axios';
import storage from '../storage/index';
import aux from '../utils/auxiliar';

const API = {

  getUserInfo: async function () {
    try {
      const header = aux.getHeaderPadrao();

      const response = await axios.get(`${storage.getUrlClient()}/DadosCadastrais`, { headers: header });

      const { err, message } = aux.trataErroAPI(response);


      if (!err) {
        return response.data
      } else {
        console.log(message)
        return null
      }

    } catch (error) {
      console.log(error);
    }
  },


  getOcorrenciaPadrao: async function (ocorrencia = 0) {
    try {

      const header = aux.getHeaderPadrao();
      let _StrRequest = '';


      if (ocorrencia > 0) {
        _StrRequest = `PadraoOcorrenciaTroca/${ocorrencia}`
      } else {
        _StrRequest = `PadraoOcorrenciaTroca`
      }


      const response = await axios.get(`${storage.getUrlClient()}/${_StrRequest}`, { headers: header });

      const { err, message } = aux.trataErroAPI(response);


      if (!err) {
        return response.data.ocorrencia
      } else {
        console.log(message)
        return null
      }

    } catch (error) {
      console.log(error);
    }
  },

  getLogradouros: async function () {
    try {
      const header = aux.getHeaderPadrao();
      const response = await axios.get(`${storage.getUrlClient()}/TiposLogradouros`, { headers: header });
      const { err, message } = aux.trataErroAPI(response);

      if (!err) {

        return response.data.tiposLogradouros.map(l => {
          return {
            text: l.nomeTpLogradouro,
            value: l.tpLogradouro
          }
        })
      } else {
        console.log(message)
        return { err: true }
      }

    } catch (error) {
      console.log(error);
      return { err: true }
    }
  },

  getUFs: async function () {
    try {
      const header = aux.getHeaderPadrao();
      const response = await axios.get(`${storage.getUrlClient()}/ListaUFs`, { headers: header });
      const { err, message } = aux.trataErroAPI(response);

      if (!err) {

        return response.data
      } else {
        console.log(message)
        return { err: true }
      }

    } catch (error) {
      console.log(error);
      return { err: true }
    }
  },


  getCidades: async function (cod) {
    try {
      const header = aux.getHeaderPadrao();
      const response = await axios.get(`${storage.getUrlClient()}/ListaLocalidades/${cod}`, { headers: header });
      const { err, message } = aux.trataErroAPI(response);

      if (!err) {

        return response.data.localidades.map(l => {
          return {
            text: l.nomeLocalidade,
            value: l.localidade
          }
        })
      } else {
        console.log(message)
        return { err: true }
      }

    } catch (error) {
      console.log(error);
      return { err: true }
    }
  },

  getAdressFromCep: async function (cep) {
    try {
      const header = aux.getHeaderPadrao();
      const response = await axios.get(`${storage.getUrlClient()}/cep/${cep}`, { headers: header });
      const { err, message } = aux.trataErroAPI(response);

      if (!err) {
        return response.data
      } else {
        console.log(message)
        return { err: true }
      }

    } catch (error) {
      console.log(error);
      return { err: true }
    }
  },


  getUserName: async function () {
    try {
      const header = aux.getHeaderPadrao();
      const response = await axios.get(`${storage.getUrlClient()}/DadosCadastrais`, { headers: header });
      const { err, message } = aux.trataErroAPI(response);

      if (!err) {
        const { nome } = response.data
        return nome
      } else {
        console.log(message)
        return ""
      }

    } catch (error) {
      console.log(error);
      return ""
    }
  },

  getPisUser: async function () {
    try {

      const header = aux.getHeaderPadrao();
      const response = await axios.get(`${storage.getUrlClient()}/pis`, { headers: header });
      const { err } = aux.trataErroAPI(response);

      if (!err) {
        return response.data.pis
      }

      return 0
    } catch (error) {
      console.log(error);
    }
  },


  getProfilePicture: async function () {
    try {

      const header = aux.getHeaderPadrao();
      const response = await axios.get(`${storage.getUrlClient()}/foto`, { headers: header });
      const { err } = aux.trataErroAPI(response);

      if (!err) {
        return response.data.foto
      }

      return null
    } catch (error) {
      console.log(error);
      return null;
    }
  },

  getDependentes: async function () {
    try {
      const header = aux.getHeaderPadrao();

      const response = await axios.get(`${storage.getUrlClient()}/Dependentes`, { headers: header });

      const { err } = aux.trataErroAPI(response);

      if (!err) return response.data.dependentes

      return null

    } catch (error) {
      console.log(error);
    }
  },

  getFerias: async function () {
    try {
      const header = aux.getHeaderPadrao();

      const response = await axios.get(`${storage.getUrlClient()}/OpcoesFerias`, { headers: header });

      const { err } = aux.trataErroAPI(response);

      if (!err) return response.data.opcoesFerias


      return []

    } catch (error) {
      console.log(error);
    }
  },

  getPhoneTypes: async function () {
    try {
      const header = aux.getHeaderPadrao();

      const response = await axios.get(`${storage.getUrlClient()}/TiposTelefone`, { headers: header });

      const { err } = aux.trataErroAPI(response);

      if (!err) {
        const result = response.data.tipotelefone.map(o => {
          return {
            value: o.tipo,
            text: o.descricao,
          }
        });
        return result
      }

      return []

    } catch (error) {
      console.log(error);
    }
  },

  getCursosHistorico: async function () {
    try {
      const header = aux.getHeaderPadrao();

      const response = await axios.get(`${storage.getUrlClient()}/CursosHistorico`, { headers: header });

      const { err, message } = aux.trataErroAPI(response);

      if (!err) return response.data.historico

      console.log(message);

      return []

    } catch (error) {
      console.log(error);
    }
  },

  getCursosDisponiveis: async function () {
    try {
      const header = aux.getHeaderPadrao();

      const response = await axios.get(`${storage.getUrlClient()}/CursosTurmas`, { headers: header });

      const { err, message } = aux.trataErroAPI(response);
      console.log(message);
      if (!err) return response.data.turmas

      return []

    } catch (error) {
      console.log(error);
    }
  },

  persistPhoneUser: async function (obj) {
    try {

      const header = aux.getHeaderPadrao();

      const body = {
        "chaveTelefone": obj.ID,
        "ddd": obj.DDD,
        "ddi": obj.DDI,
        "telefone": obj.phoneNumber,
        "tipo": obj.type
      }

      let response = null;

      if (!obj.isNewPhone) {
        response = await axios.post(`${storage.getUrlClient()}/AtualizaTelefone`, body, { headers: header });
      } else {
        response = await axios.post(`${storage.getUrlClient()}/InsereTelefone`, body, { headers: header });
      }


      const { err, message, codeErr } = aux.trataErroAPI(response);

      if (!err) return true

      console.log(message);

      return { err: true, codeErr }

    } catch (error) {
      console.log(error);
    }
  },


  getBeneficios: async function (mes, ano) {
    try {

      const header = aux.getHeaderPadrao();
      const body = { ano, mes }

      const response = await axios.post(`${storage.getUrlClient()}/Beneficios`, body, { headers: header });

      const { err, message } = aux.trataErroAPI(response);

      if (!err) return response.data.beneficios

      console.log(message);

      return []

    } catch (error) {
      console.log(error);
    }
  },

  persistAddress: async function (body, insert) {
    try {
      const header = aux.getHeaderPadrao();
      const url = storage.getUrlClient();

      const response = await axios.post(`${url}/${!insert ? 'AtualizaEndereco' : 'InsereEndereco'}`, body, { headers: header });

      const { err, message, codeErr } = aux.trataErroAPI(response);

      if (!err) return true

      return { err: true, codeErr }

    } catch (error) {
      return false
    }
  },

  getMonthLimiter: async function () {
    try {
      const header = aux.getHeaderPadrao();

      const url = storage.getUrlClient();

      const response = await axios.get(`${url}/QtdMesesRetroativosPonto`, { headers: header });

      const { err, message } = aux.trataErroAPI(response);

      if (!err) return response.data

      return {};

    } catch (error) {
      console.log(error);
      return {};
    }
  },

  getEspelhoPonto: async function (dataInicial = new Date(), dataFinal = new Date()) {
    try {
      const header = aux.getHeaderPadrao();

      const body = {
        dataInicial,
        dataFinal
      }

      const url = storage.getUrlClient();
      const response = await axios.post(`${url}/pontos`, body, { headers: header });
      const { err, message } = aux.trataErroAPI(response);

      if (!err) return response.data.ponto

      console.log(message);

      return []

    } catch (error) {
      console.log(error);
    }
  },

  alterOpAdiantamento: async function () {
    try {
      const header = aux.getHeaderPadrao();
      const url = storage.getUrlClient();

      const response = await axios.post(`${url}/AlterarOpcaoAdSalarial`, {}, { headers: header });
      const { err, message } = aux.trataErroAPI(response);

      if (!err) return true

      console.log(message);
      return []
    } catch (error) {
      console.log(error);
    }
  },

  alterCNV: async function (body) {
    try {
      const header = aux.getHeaderPadrao();
      const url = storage.getUrlClient();

      const response = await axios.post(`${url}/atualizaCNV`, body, { headers: header });
      
      if(response.data.status === 0){
        return { err: false }
      }else{
        return { err: true, message: response.data.mensagem}
      }

    } catch (error) {
      return { err: true, message: 'Algo deu errado'}
    }
  }
  



}

export default API;