import styled from "styled-components";
import { DefaultCard } from "../../global/style";

export const SearchContainer = styled(DefaultCard)`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  

  @media (min-width: 320px) and (max-width: 990px){
    justify-content: center;
  }
`

export const CardsContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;

  @media (min-width: 320px) and (max-width: 990px){
    justify-content: center;
  }
`

export const EmptyListMessage = styled.p`
  font-size: 2em;
  text-align: center !important;
  padding: 20px 0 0;
  color: rgba(139, 134, 134, 0.534);
`