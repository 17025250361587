import React, { Component } from 'react';
import PropTypes from "prop-types";
import { MenuConsumer } from "../../contexts"
import { Container, LabelContainer, RouteIcon, SubMenu, SubMenuItem } from './style';




class OptionMenu extends Component {

	state = {
		openSub: false
	}

	clickMenu = (url, styleMenu, toggleSideMenu) => {
		if (this.props.dropdown) {
			if (styleMenu === "sideClose") toggleSideMenu();
			this.setState({ openSub: !this.state.openSub })
		} else {
			this.props.onClick(url);
		}
	}

	goToLink = (url) => {
		this.setState({ openSub: !this.state.openSub })
		this.props.onClick(url);
	}

	resolveActive = (location, subpages) => {
		if (!subpages || !this.props.dropdown) return false;
		const filter = subpages.filter(s => s.link === location);
		if (filter.length > 0) return true
		else return false
	}

	render() {

		const { url, text, icon, location, hideBackGround, dropdown, subpages = [] } = this.props;
		const { openSub } = this.state;

		return (
			<div>
				<MenuConsumer>
					{
						({ toggleSideMenu, styleMenu }) => {
							return (
								<>
									<Container onClick={() => this.clickMenu(url, styleMenu, toggleSideMenu)} selected={location === url || this.resolveActive(location, subpages)}  hideBackGround={hideBackGround}>
										<LabelContainer>
											<RouteIcon name={icon} size="large" />
											{!hideBackGround ? <p style={{ transition: "all 1s" }}>{text}</p> : ""}
										</LabelContainer>
										
									</Container>
									{
										dropdown ? (
											<SubMenu isOpen={openSub && styleMenu === "sideOpen"}>
												{
													subpages.map((s, index) => {
														return <SubMenuItem key={index} onClick={() => this.goToLink(s.link)} selected={location === s.link} hideBackGround={hideBackGround} style={{ transition: "all 1s" }}>{s.linkName}</SubMenuItem>
													})
												}
											</SubMenu>
										) : null
									}
								</>
							)
						}
					}
				</MenuConsumer>
			</div>
		)
	}
}


OptionMenu.propTypes = {
	onClick: PropTypes.func.isRequired,
	url: PropTypes.string,
	text: PropTypes.string.isRequired,
	icon: PropTypes.string,
	location: PropTypes.string,
	hideBackGround: PropTypes.bool
}


export default OptionMenu;