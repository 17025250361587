import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  height: 100vh;
  position: fixed;
  left: 0;
  top: 0;
  
  z-index: 100000;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${props => props.noTransparent ? '#fff':'rgba(0,0,0,.4)'};
  padding : 10px;
`