import React from 'react';
import { Container, IconContainer } from './style';
import { Icon } from 'semantic-ui-react'


export default function FilterLabel({ label, onClick, onIconClick }){
  return(
    <Container>
      <div onClick={onClick}>
        <p>{label}</p>
      </div>

      <IconContainer onClick={onIconClick}>
        <Icon name={'close'}/>
      </IconContainer>
    
    </Container>
  )
}