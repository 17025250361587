import React, { Component } from 'react';
import { Button } from 'semantic-ui-react'
import OptionMenu from '../../components/optionmenu/OptionMenu';
import storage from "../../storage"
import APIActions from "./../../API/APIActions";
import APIAuth from "./../../API/APIAuth";
import { Route, HashRouter as Router, withRouter } from 'react-router-dom';
import routes from './Routes';
import { getConteudoArquivoAtestado } from "../../API/APIDisciplina";
import Header from '../../components/header/Header';
import Loading from "../../components/loading/Loading";
import aux from "../../utils/auxiliar";
import { MenuProvider } from "../../contexts";
import { Container, ContentContainer, LogoContainer, MainSection, OptionList, Overlay, PictureContainer, SideMenuBody, SideMenuButton, SideMenuContainer, SideMenuHeader, UserInfoContainer } from './style';
import APIUser from "../../API/APIUser";


class DefaultPage extends Component {

  constructor(props) {
    super(props);
    this.state = {
      place: true,
      logoEmpresa: "",
      styleMenu: 'sideClose',
      styleMain: 'mainClose',
      styleBtnMenu: 'btnInClose',
      displayNone: 'none',
      allRoutes: [],
      menu_active: false,
      acoes: [],
      userName: "",
      visibleHeader: true,
      isMobile: storage.isMobile(),
      loading: false,
      componentsAcoes: null
    };
  }


  async componentDidMount() {
    let isMobile = false;
    this.setState({ loading: true })

    //Aqui vamos verificar se houver a entreada dos parametros para esconder o header e gravar os tokens
    if (this.props.match.params.at && this.props.match.params.tpl && this.props.match.params.lc && this.props.match.params.urlcli) {
      isMobile = true;
      storage.setIsMobile(true);

      this.setState({ visibleHeader: false });

      storage.setUrlClient(aux.hexDecode(this.props.match.params.urlcli));

      storage.setaccess_token(aux.hexDecode(this.props.match.params.at));

      storage.setTokenLoginPlusLicenca(aux.hexDecode(this.props.match.params.tpl));
      storage.setLicenca(aux.hexDecode(this.props.match.params.lc));

      storage.setEmpresaLogada(aux.hexDecode(this.props.match.params.objempresa))

      this.setState({ isMobile: true })
    }

    if (!isMobile) {
      let acoes = [];
      const responseAcoes = await APIActions.getActions();
      if (responseAcoes.length > 0) {
        //acoes = responseAcoes.filter(a => a.id !== 18685 && a.id !== 18693 && a.id !== 18701 && a.id !== 18733 && a.id !== 18734)
        acoes = responseAcoes.filter(a => a.id === 18560 || a.id === 18561 || a.id === 18562 || a.id === 18563 || a.id === 18569
          || a.id === 18570 || a.id === 18693 || a.id === 18685 || a.id === 18701
        )
      }

      this.setState({ acoes })
    }

    const logo = await APIAuth.getLogoEmpresaLogada();

    const conteudoArquivo = await getConteudoArquivoAtestado();

    storage.setCodeDisciplaAtestado(conteudoArquivo);

    const userName = aux.getNameUser();

    const loggedUser = storage.getLoggedUser();
    const userPicture = await APIUser.getProfilePicture();

    this.setState({ allRoutes: routes, userName: userName, logoEmpresa: logo, openSub: false, loading: false, loggedUser, userPicture });
  }

  startTime = () => {
    this.timer = setTimeout(() => {
      this.props.history.push('/');
    }, this.timeOut)
  }

  stopTimeOut = () => {
    clearTimeout(this.timer);
  }



  resetTimer = () => {
    this.stopTimeOut();
  }


  toggleSideMenu = () => {
    if (this.state.styleMenu === 'sideClose') {
      this.setState({ styleMenu: 'sideOpen', styleMain: 'mainOpen', styleBtnMenu: 'btnInOPen', displayNone: '', menu_active: true });
    } else {
      this.setState({ styleMenu: 'sideClose', styleMain: 'mainClose', displayNone: 'none', styleBtnMenu: 'btnInClose', menu_active: false });
    }
  }


  navigation = (url) => {
    this.setState({ styleMenu: 'sideClose', styleMain: 'mainClose', displayNone: 'none', styleBtnMenu: 'btnInClose', menu_active: false });
    this.props.history.push(url);
  }


  render() {
    const { loggedUser, userPicture, logoEmpresa } = this.state;
    const value = {
      toggleSideMenu: this.toggleSideMenu,
      styleMenu: this.state.styleMenu
    }



    return (
      <>
        <Router>
          <Loading
            show={this.state.loading}
          />
          <Container>

            <SideMenuContainer isOpen={this.state.styleMenu === 'sideOpen'} >

              <SideMenuHeader isOpen={this.state.styleMenu === 'sideOpen'} >
                {logoEmpresa && <LogoContainer isOpen={this.state.styleMenu === 'sideOpen'}>
                  <img src={logoEmpresa} className="img-responsive" alt="Logo Azul" />
                </LogoContainer>}
                <UserInfoContainer isOpen={this.state.styleMenu === 'sideOpen'}>
                  <PictureContainer>
                    <img src={userPicture ? userPicture : "assets/user.png"} className="img-responsive" alt="Logo Azul" />
                  </PictureContainer>

                  <h1>{loggedUser ? loggedUser.name : ''}</h1>
                  <h2>{loggedUser ? loggedUser.re : ''}</h2>
                </UserInfoContainer>
                <SideMenuButton icon="bars" isOpen={this.state.styleBtnMenu === 'btnInOPen'} onClick={this.toggleSideMenu} data-testid="toggle_menu"/>
              </SideMenuHeader>

              <SideMenuBody isOpen={this.state.styleMenu === 'sideOpen'} >
                <MenuProvider value={value}>
                  {/* Renderizacao do menu */}
                  <OptionList>
                    <li>
                      <OptionMenu
                        url="/home"
                        hideBackGround={this.state.styleMenu !== 'sideOpen'}
                        text={"HOME"}
                        icon="home"
                        location={this.props.location.pathname}
                        onClick={this.navigation}
                      />
                    </li>
                    {
                      this.state.acoes.map((a, i) => {
                        return (
                          <li key={i}>
                            <OptionMenu
                              url={aux.getRouteById(a.id)}
                              hideBackGround={this.state.styleMenu !== 'sideOpen'}
                              text={a.nome.toUpperCase()}
                              icon={aux.resolveIcon(a.id)}
                              location={this.props.location.pathname}
                              onClick={this.navigation}
                              dropdown={a.id === 18562}
                              subpages={a.id === 18562 ? [
                                {
                                  link: "/cursosdisponiveis",
                                  linkName: "Cursos Agendados"
                                },
                                {
                                  link: "/historicocursos",
                                  linkName: "Histórico de Cursos"
                                }
                              ] : []}
                            />
                          </li>
                        )
                      })
                    }

                    
                    {/*
                     <li>
                      <OptionMenu
                        url="/hiperlinks"
                        hideBackGround={this.state.styleMenu !== 'sideOpen'}
                        text={"HiperLinks"}
                        icon="linkify"
                        location={this.props.location.pathname}
                        onClick={this.navigation}
                      />
                    </li> */}

                    <li className="bottom">
                      <OptionMenu
                        url="/"
                        hideBackGround={this.state.styleMenu !== 'sideOpen'}
                        text={"SAIR"}
                        icon="sign out"
                        location={this.props.location.pathname}
                        onClick={this.navigation}
                      />
                    </li>
                  </OptionList>
                </MenuProvider>
              </SideMenuBody>
            </SideMenuContainer>


            <MainSection isOpen={this.state.styleMain === 'mainOpen'}>

              <Header
                visible={this.state.visibleHeader}
                isMenuOpen={this.state.styleMenu === 'sideOpen'}
                openHeader={this.toggleSideMenu}
                active={this.state.menu_active}
                userName={this.state.userName}
                logo={this.state.logoEmpresa}
              />

              {this.state.styleMain === 'mainOpen' && <Overlay onClick={this.toggleSideMenu} />}
              <ContentContainer isMobile={this.state.isMobile}>
                {
                  this.state.allRoutes.map((route, index) => {
                    return (
                      <Route
                        key={index}
                        path={route.path}
                        exact={route.exact}
                        component={route.component}
                      />
                    )
                  })
                }
              </ContentContainer>
            </MainSection>
          </Container>
        </Router>
      </>
    );

  }

}

export default withRouter(DefaultPage);
