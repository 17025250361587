import React from 'react';
import PropTypes from "prop-types";
import AddIcon from '@material-ui/icons/Add';
import {  ButtonContainer } from './style';


const BotaoIncluir = ({text, click, classname}) => {
    return (
    <>
      <ButtonContainer onClick={()=>click()}>
          <AddIcon />
          {text}
      </ButtonContainer>
    </>
    )
};


BotaoIncluir.propTypes = {
    text : PropTypes.string.isRequired,
    click : PropTypes.func.isRequired,
    classes : PropTypes.string,
}

export default BotaoIncluir;
