import React from 'react';
import { Roller } from './style';

const Spinner = props => {
    return(
      <>
        <Roller color={props.color} height={props.height}><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></Roller>
      </>
    )
}

export default Spinner;