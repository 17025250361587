import styled, { keyframes } from "styled-components"
import { Select } from 'semantic-ui-react'
import Button from '@material-ui/core/Button';

export const CustomSelect = styled(Select)`
  width : ${({ width }) => width ? width : '100%'};
  border : none !important;
  border-bottom : 1px solid #9199A8 !important;
  border-bottom-color : #000 !important;
  color : #000 !important;
  ${({ space }) => space ? 'margin-right : 10px !important' : ''}
  
  span {
    font-size: ${props => props.theme.fontSize.small} !important;
    color : #000 !important;  
    font-family: 'Roboto', sans-serif !important;
    font-weight: 300;  
  }

  border-radius: 0 !important; 
  -webkit-border-radius : 0 !important;
  font-family: 'Roboto', sans-serif !important;
  font-weight: 300;  
  min-height: 0 !important;
  padding-left: 0 !important;
  text-align: left !important;

  .icon{
    right : 0 !important;
  }

  text{
    text-align: left !important;
  }

`


export const Container = styled.div`
  position : fixed;
  top : 0;
  left : 0;
  width : 100%;
  height : 100vh;
  display : flex;
  z-index : 1200;
  justify-content : center;
  align-items : center;

  @media(max-width: 500px) {
    width : 100%;
    height : 100vh;
    position : fixed;
    top : 0;
    left : 0;
    padding-top : 48px;
    overflow-y : auto;
    border-radius : 0;
    -webkit-border-radius : 0;
    justify-content : flex-start;
    align-items : flex-start;
  }
  
 


  background : rgba(0,0,0,.4);

`

const upFromDown = keyframes`
  from {
    opacity: 0;
    transform: translateY(100px);
  }
  to {
    opacity: 1;
    transform: translateY(0px)
  }
`

export const Label = styled.h2`
  font-family: 'Roboto', sans-serif !important;
  font-size: ${props => props.theme.fontSize.small};
  color : var(--azul);
  margin-bottom:  6px !important;
  padding: 0 !important;
  font-weight: bold;
`

export const LoadingCep = styled.div`
  position : fixed;
  top : 0;
  left : 0;
  width : 100%;
  height : 100%;
  display : flex;
  z-index : 99999999;
  justify-content : center;
  align-items : center;
  background : rgba(0,0,0,.4);
`


const BoxNotMonbile = styled.div`
  overflow-y : auto;
  display : flex;
  width  : 420px;
  background : #fff;
  border-radius : 10px;
  opacity : 0;
  padding : 12px;
  flex-direction : column;
  animation : .5s ${upFromDown}  ease-in-out forwards;
  

  @media(max-width: 500px) {
    width : 100%;
    height : 100vh;
    position : fixed;
    top : 0;
    left : 0;
    padding-top : 48px;
    overflow-y : auto;
    border-radius : 0
  }

  padding-bottom : 10px;
`

/*const BoxMobile = styled.div`
  display : flex;
  width : 100%;
  height : 100%;
  position : fixed;
  top : 0;
  left : 0;
  background : #fff;
  opacity : 0;
  padding : 20px;
  flex-direction : column;
  animation : .5s ${upFromDown}  ease-in-out forwards;
  justify-content : center;
  align-items : center;
  padding-top : 48px;
  
`*/


export const Box = BoxNotMonbile



export const Title = styled.h2`
  font-size : 14px;
  color : #9199A8;
  padding : 0;
  margin : 0;
`
export const ButtonConfirm = styled(Button)`
  background :  #2991D6 !important;
  display : flex !important;
  justify-content : center !important;
  align-items : center!important;
  animation : .5s ${upFromDown}  ease-in-out forwards;
  font-size : 14px !important;
  color : #fff !important;
  
`
export const Close = styled.div`
  width : 20px;
  height : 20px;
  border-radius : 50%;
  position : absolute;
  right: 16px;
  top : -5px;
  z-index : 9999;
`

export const ContainerPhone = styled.div`
  width : 100%;
  display : flex;
  flex-direction : row;
  justify-content : flex-start;
  margin-bottom : 18px;
`

export const SpanError = styled.span`
  font-family : Roboto;
  color : red;
  font-size : 12px;
  padding-top : 4;
  transition : all .7s;
`
export const ContainerPic = styled.div`
  position : absolute;
  flex-direction : column;
  top : 27px;
  right : 11px;
  display : flex;
  justify-content : center;
  align-items : center;
`
export const Picture = styled.div`
  
  width : 50px;
  height : 50px;
  border-radius : 50%;
  
  background : ${({ color }) => color ? color : '#cccc'};
  display : flex;
  justify-content : center;
  align-items : center;
`

export const Input = styled.input`
  text-align : ${({ align }) => align ? align : 'left'};
  width : ${({ width }) => width ? width : '100%'};
  color : ${({ disabled }) => disabled ? `#999` : `#000`};
  border : none;
  -webkit-border-radius : 0;
  border-bottom : 1px solid #9199A8;
  padding : 4px 0 4px;
  font-size : 14px;
  &:focus{
    border-bottom : 1px solid #2991D6;
    transition : all 1s
  };
  ${({ space }) => space ? `margin-right : 8px` : ''}
`

export const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

