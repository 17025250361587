export const BLUE_COLOR = "#2991D6";
export const RED_COLOR = "#DC3545";
export const GREEN_COLOR = "#2FAF9F";
export const PASTEL_COLOR = "#D7C1A8";
export const WHITE_COLOR = "#fff";


//Cores para o espelho de ponto
export const COR_ATRASO = "#438496";
export const COR_FALTA = "#CD5360";
export const COR_SEM_MARCACAO = "#B37D4E";
export const COR_AJUSTES = "#036B90";
export const COR_FERIAS = "#2FAF9F";
export const COR_TODOS = "#FFF";
export const COR_NAO_MARCA = "#3d9d9b";
export const COR_SAIDA_ANTECIPADA = "blue"