import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  padding: 0px 0px 10px;
  margin-bottom: 30px;
`

export const Body = styled.div`  
  width: 100%;
  padding: 0px 4px 0px; 
`

export const CardContainer = styled.div`
  width: 100%;
  padding: 5px;
  position: relative;
  border-radius: 5px;
  background: #fff;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.21);
  border-left: 5px solid ${props => props.borderColor};
  margin : 12px 0 16px;  
  overflow: hidden; 
  cursor: pointer;
  padding: 8px 12px 8px;
`

export const CardHeader = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 0px 0 0px;
  color : var(--azul);
  margin-bottom: 10px;

  span {
    color : #000 !important;
  }

  p {
    padding-bottom: 0 !important;
    margin-bottom: 0 !important;
  }
`

export const InfoRow = styled.div`
  display: flex;
  color : #999;
  justify-content: space-between;
  align-items: flex-start;
  margin-top: 10px;
`