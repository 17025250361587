import styled from "styled-components"


export const QuestionsContent = styled.div`
  width: 100%;
  background: #fff;
  display: flex;
  flex-direction: column;
  padding: 15px;
  max-height: 100vh;
  overflow-y: auto;  
  >h2{
    font-family: ${props => props.theme.font} !important;
    font-size: ${props => props.theme.fontSize.normal};
    font-weight: 500;
    margin: 10px 0 20px;
  }

`


export const Option = styled.div`
  width: 100%;
  padding: 10px;
  border-radius: 4px;
  background: #eee;
  cursor: pointer;
  margin-bottom: 10px;
  transition: .2s;
  ${({ active, theme }) => {
    if (active) {
      return `
        background: ${theme.colors.primary};
        color:#fff;
       `
    }
  }}

  >p{
    font-family: ${props => props.theme.font} !important;
  }

  .backQuestionBottom{
    color: #fff;
  }
`