import React, { Component } from 'react'
import { Form, Icon } from 'semantic-ui-react';
import Alert from "../alert/Alert";
import { Body, ButtonsContainer, CharCounterContainer, Header, ModalBox, ModalContainer, OptionButton } from './style';
import { Dropdown } from '../../global/style';

export default class InsertAttachment extends Component {

  constructor(props) {
    super(props);
    this.fileInputRef = React.createRef();
  }

  static defaultProps = {
    typesOfAttachment: []
  }

  state = {
    nameCurrentAnexo: "",
    catCurrentAnexo: { key: 0, texto: "" },
    openAttachament: false,
    showAlert: false,
    alertMessage: "",
    counterCaracter: 0
  }

  componentDidMount() {
    this.setState({
      nameCurrentAnexo: "",
      catCurrentAnexo: { key: 0, texto: "" },
    })
  }

  componentDidUpdate(oldProps) {
    if (this.props.show !== oldProps.show) {
      this.setState({
        nameCurrentAnexo: "",
        catCurrentAnexo: { key: 0, texto: "" },
        counterCaracter: 0
      })
    }
  }

  openDialogAnexos = () => {
    if (this.state.nameCurrentAnexo !== "" && this.state.catCurrentAnexo.key !== 0) {
      this.fileInputRef.current.click();
    } else {
      this.showMessage("Campos obrigatórios não preenchidos");
    }
  }

  handleSelectCatAnexo = (e, data) => {
    const key = data.options.filter(o => o.value === data.value)[0].key;

    this.setState({ catCurrentAnexo: { key: key, texto: e.target.textContent } });
  }

  handleNameCurrentAnexo = (e) => {
    this.setState({ nameCurrentAnexo: e.target.value, counterCaracter: e.target.value.length });
  }

  returnFileUpload = (file) => {
    this.props.getFileUpload(file, this.state.nameCurrentAnexo, this.state.catCurrentAnexo);
  }

  handleFileUpload = async (e) => {
    if (e.target.files[0].size > 20971520) {
      this.showMessage("Arquivo maior que o permitido (20mb)");
      return;
    }
    this.returnFileUpload(e);
  }

  showMessage = alertMessage => this.setState({ showAlert: true, alertMessage })
  closeMessage = () => this.setState({ showAlert: false, alertMessage: "" })


  render() {
    const { show, close } = this.props;
    const { counterCaracter } = this.state;

    if (show) {
      return (
        <>
          <Alert
            show={this.state.showAlert}
            title=""
            message={this.state.alertMessage}
            onClose={this.closeMessage}
          />
          <input type="file" accept="image/*,application/*" style={{ display: "none" }} ref={this.fileInputRef} onChange={this.handleFileUpload} />
          <ModalContainer
            open={show}
            // onClose={this.props.close}
            disableBackdropClick={true}
          >
            <ModalBox>
              <Header>
                <h2>Envio de Anexo (<strong style={{ fontSize: 14 }}>20MB por arquivo</strong>)</h2>
              </Header>
              <Body>
                <Form.Group widths='equal' style={{ width: '100%' }}>
                  <Dropdown onChange={this.handleSelectCatAnexo} options={this.props.typesOfAttachment} label="Nos diga a categoria do arquivo *" />
                  <Form.Input maxLength={30} className="inputsAnexos" fluid label='Descrição do Arquivo' value={this.state.nameCurrentAnexo} onChange={this.handleNameCurrentAnexo} placeholder='Nome do arquivo ' />
                  <CharCounterContainer><span className="spa-count-anexo">{counterCaracter}/30</span></CharCounterContainer>
                </Form.Group>
                <ButtonsContainer>
                  <OptionButton red onClick={close}>
                    cancelar
                  </OptionButton>
                  <OptionButton onClick={this.openDialogAnexos}>
                    <Icon size="large" name='attach' className="" /> Procurar Arquivo
                  </OptionButton>
                </ButtonsContainer>
              </Body>
            </ModalBox>
          </ModalContainer>
        </>
      )
    }

    return null
  }
}
