import styled from "styled-components";
import {DefaultCard} from '../../global/style';
import Skeleton from 'react-loading-skeleton';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 750px;
`

export const ShortcutContainer = styled.div`
  display: flex;
  grid-gap: 10px;
  width: 100%;

  .mobileOnly {
    display: none;

    @media (min-width: 300px) and (max-width: 1024px) {
      display: block;
    }
  }

  @media (max-width: 1152px) {
    width: 500px;
  }

  @media (max-width: 500px) {
    width: 100%;
  }
`

export const Shortcut = styled(DefaultCard)`
  text-align: center;
  padding: 20px;
  transition: background .5s;
  


  h3 {
    margin: 0 0 10px;
    font-size: ${props => props.theme.fontSize.medium};
    font-weight: 500;
  }

  p {
    font-size: ${props => props.theme.fontSize.normal}; 
  }

  :hover {
    cursor: pointer;
    background: rgba(0,0,0,.1);
  }

  @media (max-width: 1152px) {
    
    font-size: ${props => props.theme.fontSize.medium};
  }

  @media (max-width: 612px) {
    font-size: ${props => props.theme.fontSize.normal};
  }


`

export const NewsSection = styled.div`
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;

  > span {
    width: 100%;
  }

  h3 {
    font-weight: 500;
    align-self: flex-start;
    font-size: ${props => props.theme.fontSize.large};
    
    @media (max-width: 1152px) {
      align-self: center;

    }

  }

  @media (max-width: 1454px) {
    width: 750px;
  }

  @media (max-width: 1152px) {
    width: 500px;
  }

  @media (max-width: 700px) {
    width: 100%;
  }

  
`

export const NewsContainer = styled.div`
  display: flex;
  grid-gap: 10px;


  @media (max-width: 1454px) {
    justify-content: center;
    flex-direction: column;
    white-space: normal;
    width: 100%;
  }

`

export const NewsPageLink = styled.a`
  align-self: center;
  margin: 20px 0;
  font-size: ${props => props.theme.fontSize.medium};
  

  :hover{
    cursor: pointer;
    text-decoration: none;
  }
`

export const Mock = styled(Skeleton)`
  width: 100%;
  height: 345px;
  margin-right: 10px;
  
  @media (max-width: 1152px) {
    width: 100% !important;
  }
`


export const NewsList = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
  padding: 0;


`