import aux from "../utils/auxiliar"
const storage = {

	cleanALL: function () {
		try {
			sessionStorage.removeItem("@portal:token");
			sessionStorage.removeItem("@portal:ocorrencias");
			sessionStorage.removeItem("@portal:tiposocorrenca");
			sessionStorage.removeItem("@portal:access_token");
			sessionStorage.removeItem("@portal:tiposanexo");
			sessionStorage.removeItem("pc3MiOiJISyBTQVIg");
			sessionStorage.removeItem("jwuolakjhs");
			sessionStorage.removeItem("client");
			sessionStorage.removeItem("ce");
			sessionStorage.removeItem("mobile");
			sessionStorage.removeItem("user");

		} catch (error) {
			console.log(error);
		}
	},

	setTokenLoginPlusLicenca: function (token) {
		try {
			sessionStorage.removeItem('@portal:token');
			sessionStorage.setItem('@portal:token', token);
		} catch (error) {

			console.log(error);
		}
	},

	setIsMobile: function (isMobile) {
		try {
			sessionStorage.setItem("mobile", isMobile);
		} catch (error) {
			console.log(error)
		}
	},

	isMobile: function () {
		try {
			return sessionStorage.getItem("mobile");
		} catch (error) {
			console.log(error)
		}
	},

	setFirstHeader: function (header) {
		try {
			sessionStorage.removeItem('pc3MiOiJISyBTQVIg');
			sessionStorage.setItem("pc3MiOiJISyBTQVIg", JSON.stringify(header));
		} catch (error) {
			console.log(error);
		}
	},




	getFirstHeader: function () {
		try {
			return JSON.parse(sessionStorage.getItem('pc3MiOiJISyBTQVIg'));
		} catch (error) {
			console.log(error);
		}
	},


	// Token de acesso - para a api do cliente
	setaccess_token: function (access_token) {
		try {
			sessionStorage.removeItem('@portal:access_token');
			sessionStorage.setItem('@portal:access_token', JSON.stringify(access_token));
		} catch (error) {

			console.log(error);
		}
	},

	getaccess_token: function () {
		try {
			const token = sessionStorage.getItem('@portal:access_token')
			return JSON.parse(token);
		} catch (error) {
			console.log(error);
			return [];
		}
	},

	cleanToken: function () {
		try {
			sessionStorage.removeItem('@portal:token');
		} catch (error) {
			console.log(error);
		}
	},

	getTokenLoginPlusLicenca: function () {
		try {
			return sessionStorage.getItem('@portal:token');
		} catch (error) {
			console.log(error);
			return [];
		}
	},

	setOcorrencias: function (ocorrencias = []) {
		try {
			sessionStorage.setItem("@portal:ocorrencias", JSON.stringify(ocorrencias));
		} catch (error) {
			console.log(error);
		}
	},

	getOcorrencias: function () {
		try {
			return JSON.parse(sessionStorage.getItem("@portal:ocorrencias"));
		} catch (error) {
			console.log(error)
			return [];
		}
	},

	setTiposAnexo: function (tiposOcorrencia = []) {
		try {
			sessionStorage.setItem("@portal:tiposanexo", JSON.stringify(tiposOcorrencia));
		} catch (error) {
			console.log(error);
		}
	},

	setEmpresaLogada: function (empresa = {}) {
		try {

			sessionStorage.setItem("oiuyJkjhgs", aux.base64Encode(JSON.stringify(empresa)));
		} catch (error) {
			console.log(error);
		}
	},

	setCodeDisciplaAtestado: function (codeatestado) {
		try {
			sessionStorage.setItem("codeatestado", codeatestado);
		} catch (error) {
			console.log(error)
		}
	},

	getCodeDisciplaAtestado: function () {
		try {
			return sessionStorage.getItem("codeatestado");
		} catch (error) {
			console.log(error);
			return [];
		}
	},

	getEmpresaLogada: function (empresa = {}) {
		try {
			const empresa = JSON.parse(atob(sessionStorage.getItem("oiuyJkjhgs")));
			return empresa
		} catch (error) {
			console.log(error);
		}
	},


	getTiposAnexo: function () {
		try {
			return JSON.parse(sessionStorage.getItem("@portal:tiposanexo"));
		} catch (error) {
			console.log(error);
			return [];
		}
	},

	setLicenca: function (licenca = "") {
		try {

			sessionStorage.setItem("jwuolakjhs", licenca);
		} catch (error) {
			console.log(error);
		}
	},

	getLicensa: function () {
		try {
			return sessionStorage.getItem("jwuolakjhs");
		} catch (error) {
			console.log(error);
			return [];
		}
	},

	setUrlClient: function (url) {
		try {
			sessionStorage.setItem("client", url);
		} catch (error) {
			console.log(error);
		}
	},

	getUrlClient: function () {
		try {
			return sessionStorage.getItem("client");
		} catch (error) {
			console.log(error);
			return "";
		}
	},


	setCodEmpresa: function (codempresa) {
		try {

			sessionStorage.setItem("ce", codempresa);
		} catch (error) {
			console.log(error);
		}
	},

	getCodEmpresa: function () {
		try {
			return sessionStorage.getItem("ce");
		} catch (error) {
			console.log(error);
			return "";
		}
	},

	setLoggedUser: function (user) {
		try{
			sessionStorage.setItem('user', JSON.stringify(user));
		}catch{
			return;
		}
	},

	getLoggedUser: function () {
		try{
			return JSON.parse(sessionStorage.getItem('user'));
		}catch{
			return;
		}
	}


}


export default storage;