import axios from 'axios';
import aux from '../utils/auxiliar';
import storage from '../storage/index';


const APIMonitoramento = {
  getListaMonitoramento: async function (dateToFilter) {

    const urlClient = storage.getUrlClient();

    try {
      const header = aux.getHeaderPadrao();

      const endPoint = !dateToFilter ? 'MonitoramentoListaApp' : `MonitoramentoListaApp/${dateToFilter.replaceAll("/","")}`
      const response = await axios.get(`${urlClient}/${endPoint}`, { headers: header, timeout: 300000 });
      const { err, message } = aux.trataErroAPI(response);

      if (!err) {
        return response.data.monitoramentosApp;
      } else {
        return { err: true, message }
      }
    } catch (error) {
      return { err: true, message: "Ops... Algo deu errado. Não conseguimos carregar seus chamados. Vamos tentar novamente?" };
    }
  },

  getDetailMonitoramento: async function (objBody) {
    const urlClient = storage.getUrlClient();

    try {
      const header = aux.getHeaderPadrao();

      const body = {
        "enumApp": objBody.enum,
        "empresa": objBody.selectedCompany,
        "data": objBody.date ? aux.invertDateString(objBody.date.replaceAll("/", "")) : null
      }

      const response = await axios.post(`${urlClient}/MonitoramentoListaDetalheApp`, body, { headers: header, timeout: 120000 });
      
      const { err, message } = aux.trataErroAPI(response);

      if (!err) {
        return response.data.monitoramentosDetalheApp;
      } else {
        return { err: true, message }
      }
    } catch (error) {

      return { error: true, mensagem: "Ops... Algo deu errado." };
    }
  }
}


export default APIMonitoramento;