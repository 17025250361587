import storage from '../storage/index';
import aux from '../utils/auxiliar'
import APIHk from "../API/APIHk";


const APIEmpresas = {
	getEmpresas: async function () {
		try {
			
			const header = {
				'Authorization': `Bearer ${storage.getLicensa()}`,
				'Content-Type': 'application/json'
			};

			const response = await APIHk.get(`/empresas`, { headers: header });

			const { err, message } = aux.trataErroAPI(response);
			
			if(err){
				console.log(message);
				return [];
			}

			return response.data.empresas;
			
		} catch (error) {
			console.log(error);
			return [];
		}


	}
}

export default APIEmpresas; 