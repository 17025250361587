import React, { useEffect, useState, useContext } from 'react';
import Item from "./item"

import ApiAuth from "../../../API/APIAuth"
import { cloneDeep } from "lodash"
import Loading from '../../../components/loading/Loading';
import { ShowMessageContext } from "./../../../contexts/showmessage"
import { useForm } from "react-hook-form";
import Silverinput from '../../../components/silverinput';
import {
  Container,
  ContainerQuestions,
  Header,
  ButtonClose,
  QuestionsContent,
  Form,
  NextButtom,
  BackButtom,
  Bottom
} from "./style"


const ModalResetPassword = ({ close, url, empresa, cpf }) => {
  const showMessage = useContext(ShowMessageContext);
  const { register, handleSubmit, formState: { errors } } = useForm();

  const [data, setData] = useState([]);
  const [currentQuestion, setCurrentQuestion] = useState({});
  const [indexQuestion, setIndexQuestion] = useState(0);
  const [isThereAnyActive, setIsThereAnyActive] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [tokenAcesso, setTokenAcesso] = useState(null);

  const [telefone, setTelefone] = useState(null);
  const [_cpf, set_cpf] = useState(null);



  useEffect(() => {
    const { alternativas } = currentQuestion;
    if (!alternativas) return;

    for (let i = 0; i < alternativas.length; i++) {
      if (alternativas[i].active) {
        setIsThereAnyActive(true);
        break;
      } else {
        setIsThereAnyActive(false)
      }
    }
  }, [indexQuestion, currentQuestion])


  useEffect(() => {
    InitApp();
  }, [])




  async function InitApp() {
    try {
      let auxCpf = cpf.replace(/[^0-9]/g, '');

      setLoading(true)
      const response = await ApiAuth.getQuesitons(url, auxCpf);
      setLoading(false)
      const { perguntas, telefone, error, message } = response;

      if (error) {
        close();
        return showMessage.show(message, true);
      }

      setData(perguntas);
      setCurrentQuestion(perguntas[0]);
      setTelefone(telefone)
      setIsThereAnyActive(false);
      set_cpf(auxCpf)
      setLoading(false)
    } catch (error) {
      setLoading(false)
      showMessage.show("Ops... Algo deu errado: " + error);
    }
  }




  function Next() {
    const current = indexQuestion + 1;
    if (current >= data.length) return;

    setCurrentQuestion(data[current]);
    setIndexQuestion(current);
  }



  function back() {
    const current = indexQuestion - 1;
    if (current < 0) return;

    setCurrentQuestion(data[current]);
    setIndexQuestion(current);
  }



  async function handleOptionClick(index) {
    const _data = cloneDeep(data);
    const selected = _data[indexQuestion].alternativas[index].active;
    _data[indexQuestion].alternativas.map(i => i.active = false);
    _data[indexQuestion].alternativas[index].active = !selected;
    setData(_data);
    setCurrentQuestion(_data[indexQuestion]);
  }


  async function submitQuestions() {
    try {
      setLoading(true);

      const _dataToSend = data.map(i => {
        const objAlt = i.alternativas.filter(a => a.active)[0];
        const id = i.idpergunta;
        return {
          texto: objAlt.texto,
          id
        }
      });


      const response = await ApiAuth.sendQuestionsAnswers(url, { cpf: _cpf, respostas: _dataToSend });

      setLoading(false);

      if (response.error) return showMessage.show(response.message, true);

      setTokenAcesso(response.token_acesso);
      setShowPassword(true);

    } catch (error) {
      setLoading(false);
      showMessage.show("Ops... Algo deu errado: " + error, true);
    }
  }






  async function handleNewPassword(data) {
    try {
      setLoading(true);


      if (data.pass !== data.confirmPass) {
        setLoading(false);
        return showMessage.show("As senhas não conferem.", true);
      }

      const response = await ApiAuth.cadNewPassword(data.confirmPass, data.pass, _cpf, url, tokenAcesso);

      setLoading(false);
      if (!response.error) {
        showMessage.show("Sua senha foi alterada com sucesso. Por favor, faça login com sua nova senha");
        close();
      } else {
        showMessage.show("Não foi possível cadastrar sua nova senha", true)
      }

    } catch (error) {
      setLoading(false);
      showMessage.show("Ops... Algo deu errado: " + error, true)
    }
  }




  if (loading) {
    return <Loading show={true} />
  }

  return (
    <Container>
      <ContainerQuestions>
        <Header>
          <h2>{!showPassword ? "Questionário" : "Nova senha"}</h2>
          <ButtonClose onClick={close} />
        </Header>

        {!showPassword && <Item data={currentQuestion} handleOptionClick={handleOptionClick} loading={loading} />}

        {showPassword && <Form onSubmit={handleSubmit(handleNewPassword)}>
          <Silverinput type={"password"} placeholder="Digite sua nova senha *" {...register("pass", { required: true, minLength: 8 })} />
          {errors.pass ? <span>A senha deve ter no mínimo 8 caracteres</span> : null}
          <Silverinput type={"password"} placeholder="Confirme sua nova senha *" {...register("confirmPass", { required: true, minLength: 8 })} />
          {errors.confirmPass ? <span>A senha deve ter no mínimo 8 caracteres</span> : null}
          {<input type={"submit"} value="Cadastrar nova senha" />}
        </Form>}

        {!showPassword && (!loading && data.length > 0) && <Bottom>
          {indexQuestion > 0 && <BackButtom onClick={back}>Voltar</BackButtom>}
          {((indexQuestion !== data.length - 1) && isThereAnyActive) && <NextButtom onClick={Next}>Próximo</NextButtom>}

          {((indexQuestion === data.length - 1) && isThereAnyActive) && <NextButtom onClick={submitQuestions}>
            Avaliar respostas
          </NextButtom>}
        </Bottom>}
        {(!showPassword && !loading) ? <p className="infoWrongDataChandPass">Caso nenhuma alternativa esteja correta, por favor, entre em contato com sua empresa no seguinte número: <strong>{telefone}</strong></p> : null}
      </ContainerQuestions>
    </Container>
  );
};

export default ModalResetPassword;