import React, { useEffect, useState } from 'react';
import IconButton from '@material-ui/core/IconButton';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import ApiUser from "../../../../API/APIUser"
import Loading from "../../../../components/loading/Loading"
import Alert from "../../../../components/alert/Alert"
import { Container, Box, Input, ButtonConfirm, Close, ContainerPhone, CustomSelect, InputContainer, Label } from './styles';

export default function EditPhone(props) {

  const [phone, setPhone] = useState(props.Phone || "");
  const [ddi, setDDI] = useState(props.DDI || "")
  const [ddd, setDDD] = useState(props.DDD || "")
  const [tipoSelected, setTipo] = useState(props.tipo)
  const ID = props.ID;
  const [showAlert, setShowAlert] = useState(false);
  const [messageAlert, setMessageAlert] = useState("");


  const [loading, setLoading] = useState(false)
  const [phoneTypes, setPhoneTypes] = useState([]);

  useEffect(() => {
    async function getPhoneTypes() {
      try {
        setLoading(true);
        const phoneTypes = await ApiUser.getPhoneTypes();
        if (!phoneTypes.err) {
          setPhoneTypes(phoneTypes);
        }
        setLoading(false)
      } catch (error) {
        setLoading(false)
      }
    }
    getPhoneTypes();
  }, []);



  async function sendNewPhone() {
    try {


      if (!isValid()) {
        showMessage('Dados Inválidos');
        return
      }

      setLoading(true);

      const obj = {
        DDD: ddd,
        DDI: ddi,
        phoneNumber: phone,
        ID,
        type: tipoSelected,
        isNewPhone: props.isNewPhone
      };

      const response = await ApiUser.persistPhoneUser(obj);

      setLoading(false);

      if (!response.err) {
        if (!props.isNewPhone) {
          props.close('Telefone alterado com sucesso.', true);
        } else {
          props.close('Telefone incluído com sucesso.', true);
        }

      } else {
        if (response.codeErr === 137 || response.codeErr === 136) {
          return showMessage('Ops... Já existe uma alteração pendente de aprovação')
        }
        showMessage('Ops... Algo deu errado')
      }

    } catch (error) {
      setLoading(false)
    }

  }

  function isValid() {

    if (!(phone.length >= 8)) {
      return false
    }
    if (ddd.length !== 2) {
      return false
    }
    if (ddi.length !== 2) {
      return false;
    }
    return true
  }

  function handlePhone(e) {
    setPhone(e.target.value.replace(/\D+/g, ''));
  }

  function handleDDI(e) {
    setDDI(e.target.value.replace(/\D+/g, ''))
  }

  function handleDDD(e) {
    setDDD(e.target.value.replace(/\D+/g, ''))
  }

  function handlePhoneType(e, data) {
    setTipo(data.value)
  }


  function showMessage(message) {
    setMessageAlert(message);
    setShowAlert(true)
  }

  function closeAlert() {
    setMessageAlert('');
    setShowAlert(false)
  }

  return (
    <Container>
      {loading && <Loading show />}
      <Alert show={showAlert} title="" message={messageAlert} onClose={closeAlert} />

      <Box>
        <Close>
          <IconButton onClick={() => props.close(false, false)}>
            <CloseIcon style={{ color: "#000", fontSize: 16 }} />
          </IconButton>
        </Close>


        <ContainerPhone>
          <InputContainer>
            <Label>Tipo *</Label>
            <CustomSelect
              placeholder='Selecione o tipo do contato'
              options={phoneTypes}
              className="selectPhoneType"
              defaultValue={tipoSelected}
              onChange={handlePhoneType}
              width={280}
            />
          </InputContainer>
        </ContainerPhone>

        <ContainerPhone>
          <InputContainer>
            <Label>ddi *</Label>
            <Input
              space
              width={28}
              value={ddi}
              onChange={handleDDI}
              title="DDI"
              placeholder={"DDI"}
              type={'text'}
              maxLength={2}
            />
          </InputContainer>

          <InputContainer>
            <Label>ddd *</Label>
            <Input
              space
              width={28}
              value={ddd}
              onChange={handleDDD}
              title="DDD"
              placeholder={"DDD"}
              type={'text'}
              maxLength={2}
            />
          </InputContainer>

          <InputContainer>
            <Label>Telefone *</Label>
            <Input
              width={200}
              value={phone}
              onChange={handlePhone}
              title="Insira seu novo número"
              placeholder={"telefone"}
              maxLength={9}
              type={'text'}
            />
          </InputContainer>
        </ContainerPhone>

        {
          phone !== "" && ddd !== "" && ddi !== "" && tipoSelected !== 0 && <ButtonConfirm>
            <IconButton onClick={sendNewPhone}>
              <CheckIcon style={{ color: "#fff" }} />
            </IconButton>
          </ButtonConfirm>
        }

      </Box>
    </Container>
  );
}
