import React from 'react'

import { Container, InputBox } from "./style";

export default function Checkbox({ gapVertical, margin, onCheck, checked, label }){
  return (
		<Container gapVertical={gapVertical} margin={margin}>
      <InputBox type="checkbox" onChange={onCheck} />
      <label >{label}</label>
    </Container>
	)
}