import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DoneIcon from '@material-ui/icons/Done';
import EditIcon from '@material-ui/icons/Edit';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import SignaturePad from 'react-signature-pad-wrapper'
import PropTypes from "prop-types";
import { 
	BoxContainer, 
	ButtonsContainer, 
	OptionButton, 
	Overlay, 
	PadContainer
} from './style';

function Transition(props) {
	return <Slide direction="up" {...props} />;
}

class Assign extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			imageFromAssign: null
		}
		this.signaturePad = React.createRef();
	}

	componentDidMount() {
		this.setState({ imageFromAssign: null });
	}

	gravarAssinatura = () => {
		try {
			const imageFromAssign = this.signaturePad.toDataURL();
			this.props.getAssign(imageFromAssign);
		} catch (error) {

		}
	}

	resetAssign = () => {
		this.signaturePad.clear();
	}

	render() {
		return (
			<div>
				<Dialog
					fullScreen
					open={this.props.open || false}
					onClose={this.props.close}
					TransitionComponent={Transition}
					style={{zIndex : 5000}}
				>

					<Overlay>
						<BoxContainer>
							<p>Assine no campo abaixo</p>
							<PadContainer>
								<SignaturePad
									options={
										{
											minWidth: 1,
											maxWidth: 2,
											penColor: 'rgb(66, 133, 244)'
										}
									}
									ref={ref => this.signaturePad = ref}
									className="singPad"
								/>
							</PadContainer>

							<ButtonsContainer>
								<OptionButton onClick={this.gravarAssinatura}>
									<DoneIcon />
									Prosseguir
                </OptionButton>

								<OptionButton onClick={this.resetAssign}>
									<EditIcon />
									Resetar Assinatura
                </OptionButton>

								<OptionButton onClick={this.props.close}>
									<CloseIcon />
									Cancelar
                </OptionButton>
							</ButtonsContainer>
						</BoxContainer>
					</Overlay>

				</Dialog>
			</div>
		)
	}

}

Assign.propTypes = {
	open: PropTypes.bool.isRequired,
	close: PropTypes.func.isRequired,
	getAssign: PropTypes.func.isRequired
}

export default Assign;
