import styled from "styled-components";


export const Input = styled.input`
  width: 100%;
  border-radius: 1px;
  background: #EEEEEE;
  padding:10px;
  border: none;
  font-family: ${props => props.theme.font} !important;
`