import axios from 'axios';
import storage from '../storage/index';
import aux from '../utils/auxiliar';


const APIActions = {
	getActions: async function () {
		try {
			const header = aux.getHeaderPadrao();
			const response = await axios.get(`${storage.getUrlClient()}/Acoes`, { headers: header, timeout: 50000 });
			if (aux.isResponseValid(response)) {
        console.log(response.data.acoes);
				return response.data.acoes;
			} else {
				return [];
			}
		} catch (error) {
			console.log(error);
			return [];
		}
	}
}

export default APIActions;