import styled from "styled-components";



export const ModalBox = styled.div`
  width: 300px;
  background: #fff;
  border-radius: 5px;
  animation-name: message;
  animation-duration: .2s;
  animation-timing-function: ease;
  -webkit-animation-fill-mode: forwards;
  animation-delay: .2s;    
  opacity : 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 10px 0;
  position: relative;
  label {
    font-size: ${props => props.theme.fontSize.medium};
  }
`

export const Header = styled.div`
  padding: 0 0 10px;

  h1 {
    font-size: ${props => props.theme.fontSize.medium};
    font-weight: 400;
    margin: 0;
  }
`
export const CompanyPicker = styled.select`
  width: 250px;
  padding: 5px 10px;
`

export const Button = styled.button`
  border: none;
  background: #2991D6;
  color: #fff;
  margin-top: 10px;
  padding: 5px 10px;
`

export const CloseButton = styled.a`
  position: absolute;
  top: 5px;
  right: 0;
`