import styled from "styled-components";
import { DefaultCard } from "../../global/style"; 
import Skeleton from 'react-loading-skeleton';

export const News = styled(DefaultCard)`
  padding: 0;
  width: 100%;
  height: fit-content;
  
  @media (max-width: 1454px) {
    width: 100%;
  }

`


export const NewsImageContainer = styled.div`
  width: 100%;
  max-height: 500px;
  background: #f9f9f9;
  display: flex;
  justify-content: center;

  img {
    max-width: 100%;
    max-height: 100%;
  }

  @media (max-width: 700px) {  
    height: 300px;
  }
`

export const NewsHeader = styled.div`
  padding: 15px 15px 10px;
  display: flex;
  flex-direction: column;
  gap: 5px;
  border-bottom: 1px solid #eee;
  
  h1 {
    white-space: normal;

    font-weight: 500;
    font-size: ${props => props.theme.fontSize.normal};
    margin: 0;
  }

  h2 {
    font-size: ${props => props.theme.fontSize.smallernormal};
    color: #999;
    margin: 0;
  }

  a {
    align-self: flex-end;
    :hover{
      cursor: pointer;
      text-decoration: none;
    }
  }
`


export const NewsBody = styled.div`
  padding: 10px;

  p {
    white-space: pre-wrap;
  }

  a {
    font-weight: 500;
    font-size: ${props => props.theme.fontSize.smallernormal};
  }
`

export const NewsPreviewContainer = styled.div`
  width: 300px;
  height: 60px;
  margin-bottom: 10px;
  
  p {
    white-space: normal;
    margin: 20px 0;
    line-break: anywhere;
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }

  
`