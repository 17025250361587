import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;

  @media (min-width: 320px) and (max-width: 990px) {
    justify-content: center;
  }
`