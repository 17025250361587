import styled from "styled-components";
import { Button } from 'semantic-ui-react'

export const Container = styled.div`
  height: 100vh !important;
  transition: all .2s;
  position: relative;
  display: flex;
`

export const SideMenuContainer = styled.div`
  overflow: hidden;
  overflow-y: auto;
  position: fixed;
  top : 0 !important;
  height: 100vh;    
  background: #fff;
  transition: all .2s;
  z-index: 1200;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-right: 1px solid #eee;
  padding: 0 5px;

  ${props => props.isOpen ? `
    width : 350px;
    margin-left: 0 !important;
    transition: all .2s;
    padding:  0 !important;
  `: `
    width : 45px;
    transition: all .2s;
    padding: 0 4px 0 4px;
    img {
      display: none;
    }

    @media (min-width: 300px) and (max-width: 1024px) {
      width: 0px !important;
      padding: 0 !important;
      div {
        display:none;
      }
    }
  `}

  ::-webkit-scrollbar {
    width: 1px !important;
    height: 1px !important;
    background: #fff !important;
  }

  ::-webkit-scrollbar-track {
    background-color: #fff !important;
  }

  ::-webkit-scrollbar-thumb {
    background: #fff !important;
  }
`

export const SideMenuHeader = styled.div`
  position: relative;
  width: 100%;
  height: ${props => props.isOpen ? 'fit-content' : '75px !important'};
  display: flex;
  align-items: center;
  cursor: pointer;
  ${props => props.isOpen && 'padding: 10px 0;'}
  
  p {
    color : #fff;
    margin-left: 5px;
    margin-top: 10px;  
  }

  ${props => props.isOpen && `
    @media(max-width: 1024px){ 
      flex-direction: column;
    }
  `}

  
`

export const SideMenuButton = styled(Button)`

  background:none !important;
  font-size: ${props => props.theme.fontSize.large} !important; 
  color : #000 !important;
  display: flex !important;
  justify-content: center !important;
  ${props => props.isOpen ? `
    width: 55px;

    margin-top: 19px !important;
    padding: 0 !important;
    margin-right: 0 !important;
    width:  auto !important;
    align-self: flex-start;
  `: `
    width: 100%;
    transition: all .2s;
  `}

  @media (min-width: 300px) and (max-width: 1024px) {
    display: none !important;
  }
`

export const SideMenuBody = styled.div`
  margin-top : 16px;
  transition: all .2s;
  white-space: nowrap;
  width: 100%;
  height: 100%;                   
  text-overflow: ellipsis;
`

export const MainSection = styled.div`
  transition: all .2s;
  min-width: 0;
  display: block; 
  margin-left: ${props => props.isOpen ? `350px` : `45px`};
  flex: 1;
  position: relative; 

  @media (min-width: 300px) and (max-width: 1024px) {
    margin-left : 0 !important;
  }
   
`


export const OptionList = styled.ul`
  list-style-type: none;
  padding: 0;
  display: flex;
  flex-direction: column;
  height: 100%;

  li.bottom {
    margin-top: auto;
  }
`

export const ContentContainer = styled.div`
  width: 100%;    
  padding: 100px 15px 0;
  box-sizing: border-box;
  display: flex;
  justify-content: center;


  ${props => props.isMobile && `
    padding-top: 4px !important;
    margin-top: 0px !important;
  `}

  @media (min-width: 768px) and (max-width: 1024px) {
    padding-top: 80px;
  }

  @media (min-width: 300px) and (max-width: 767px) {
    padding-top: 55px;  
  }

  @media only screen and (max-width : 820px){
    width: 100%;  
  }

`

export const Overlay = styled.div`
  @media (min-width: 300px) and (max-width: 1024px) {
    width: 100%;
    top : 46px;
    position: fixed;
    height: 100vh;
    background: rgba(0,0,0, .3);
    z-index: 1100;
    transition: all .4s;
  }
 
`

export const LogoContainer = styled.div`
  width: 90px;
  max-height: 90px;
  display: none;
  justify-content: center;
  margin-bottom: 5px;
  overflow: hidden;

  img {
    max-width: 100%;
    max-height: 100%;
  }

  @media(max-width: 1024px){
    display: ${props => props.isOpen ? 'flex' : 'none'};
  }
`

export const UserInfoContainer = styled.div`
 
  text-align: center;
  width: 100%;
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  ${props => !props.isOpen && `display: none;`}
  
  h1 {
    font-size: ${props => props.theme.fontSize.medium};
    font-weight: 500;
    font-family: 'Quicksand';
  }

  h2 {
    font-size: ${props => props.theme.fontSize.medium};
    font-weight: 500;
    margin-top: 0;
  }
`

export const PictureContainer = styled.div`
  width: 90px;
  height: 90px;
  border-radius: 50%;
  overflow: hidden;
  display: flex;
  align-items: center;
`