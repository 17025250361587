import styled, { keyframes } from "styled-components"

import Button from '@material-ui/core/Button';

export const BackgroundAtestado = styled.div`
  top : 0;
  left:  0;
  display: flex;
  z-index: 909090;
  position: fixed;
  width:  100%;
  height:  100%;
  background : rgba(0,0,0,.4);
  justify-content: center;
  align-items: center;
`
const upFromDown = keyframes`
  from {
    opacity: 0;
    transform: translateY(100px);
  }
  to {
    opacity: 1;
    transform: translateY(0px)
  }
`

export const BoxAtestado = styled.div`
  position: relative;
  background: #fff;
  border: none !important;
  border-radius: 5px;
  margin: 10px;
  width: 400px;
  height: auto;
  align-items: center;
  display: flex;
  flex-direction: column;

  &:focus{
    outline: none !important;
  }


`

export const Header = styled.div`
  width: 100%;
  height: 40px;
  background: rgba(23, 158, 248, 0.993);
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #fff;
`

export const HeaderText = styled.div`
  width: 100%;
  height: 40px;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  p {
    font-size: ${props => props.theme.fontSize.normal};
    margin-left: 10px;
  }
`

export const Body = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px 10px 10px;
  background: var(--azul);
  position: relative;
  overflow: hidden;
  width: 100%;
`

const desdobrando = keyframes`
  from {
    border-width: 0 0px 0px 0;
  }
  to {
    border-width: 0 25px 25px 0;
  }
`

export const TicketContainer = styled.div`
  width: 100%;
  background: #FFFFCC;
  position: relative;
  transition: all .7s;
  padding: 5px;
  display: flex;
  flex-direction: column;

  &:before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    border-style: solid;
    border-width: 0 0px 0px 0;
    border-color: #e5e5b3 var(--azul);
    transition: all ease .5s;
    animation-name: ${desdobrando};
    animation-duration: .2s;
    -webkit-animation-fill-mode: forwards;
    animation-delay: .4s;
    transition: all 1s;
  }

  > p {
    margin-top: 10px;
  }

  button {
    align-self: flex-end;
  }

  @media (min-width: 320px) and (max-width: 990px) {
    height: 100%;
  }

`

export const TicketHeader = styled.div`
  border-bottom: 1px dotted #000;
  padding: 4px;
  font-size: ${props => props.theme.fontSize.smallernormal};
  text-align: center;
  color: rgb(139, 139, 139);

  .infoAdd {
    padding: 0;
  }

  .infoAdd h6{
    text-align: left !important;
  }

  h6 {
    font-size: ${props => props.theme.fontSize.smallernormal};
    text-align: ${props => props.alignTitle ? props.alignTitle : 'center'};
    color: rgb(139, 139, 139);
  }
`

export const CompanyInfo = styled.div`
  width: 100%;
  padding: 5px;

  #name {
    text-align: center;
    font-size: ${props => props.theme.fontSize.smallernormal};
    font-weight: bold;
    letter-spacing: .35px;
    text-transform: uppercase;
  }

  #cnpj {
    font-size: ${props => props.theme.fontSize.smallernormal};
    text-transform: uppercase;
    font-weight: bold;
    letter-spacing: .35px;
  }
`

export const EmployeeInfo = styled.div`
   h6 {
    font-size: ${props => props.theme.fontSize.small};
    padding: 0px 5px 0;
  }
  
  span {
    color: rgb(139, 139, 139);
    font-weight: bold;
    letter-spacing: .80px;
    text-transform: uppercase;
    font-size: ${props => props.theme.fontSize.small};
  }
`

export const Input = styled.input`
  text-align : ${({ align }) => align ? align : 'left'};
  width : ${({ width }) => width ? width : '100%'};
  
  border : none;
  border-bottom : 1px solid #9199A8;
  padding : 4px 0 4px;
  font-size : 14px;
  &:focus{
    border-bottom : 1px solid #2991D6;
    transition : all 1s
  };
  

  ${({ space }) => space ? `margin-right : 8px` : ''}
`

export const FormContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 10px 10px;
`;

export const DescriptionContainer = styled.div`
  width : 100%;
  display : flex;
  flex-direction : column;
  justify-content : flex-start;
  margin-bottom : 18px;
`

export const TitleAtestado = styled.h1`
  font-size : 20px;
  text-transform : uppercase;
  text-align : center;
  margin: 32px;
`

export const Close = styled.div`
  width : 22px;
  height : 22px;
  border-radius : 50%;
  position : absolute;
  right: 18px;
  top : -7px;
  z-index : 9999;
  cursor: pointer;
`

export const Picture = styled.div`
  
  width : 70px;
  height : 70px;
  border-radius : 50%;
  margin-bottom : 30px;
  background : ${({ color }) => color ? color : '#cccc'};
  display : flex;
  justify-content : center;
  align-items : center;
`

export const FileListContainer = styled.div`
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  margin-bottom: 12px;
`

export const FileContainer = styled.div`
  display :  flex;
  flex-direction: row;
  justify-content: space-between; 
  p {
    color: #999;
  }
`

export const SubmitButton = styled(Button)`
  background: var(--azul) !important;
  color: #fff !important;
  font-size: ${props => props.theme.fontSize.smallernormal} !important;
`