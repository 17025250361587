import styled from "styled-components";
import { Icon } from 'semantic-ui-react'

export const Container = styled.div`
  width: 170px;
  height: 120px;
  margin : 10px 0 10px;
  border-radius: 5px;
  box-shadow: 0 1px 4px 0 rgba(0,0,0,.14);
  margin-right: 5px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
`

export const IconContainer = styled.div`
  width: 100%;
  height: 90px;
  background: var(--azul);    
  bottom: 0;
  transition: all .8s;
  overflow: hidden;  
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center; 
  padding: 20px;
`

export const AttachIcon = styled(Icon)`
  color: #fff !important;
  margin-right: 10px  0 5px !important;
`

export const Footer = styled.div`
  width: 100%;
  position: absolute;
  height: 30px;
  background: #1a6496;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center; 
  text-align: center;
  z-index : 2; 
  p {
    color : #fff !important;
    white-space: nowrap;
    width: 100%;                   
    overflow: hidden;          
    text-overflow:    ellipsis;
 }   
`