import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import { Container, ImageContainer } from './style';

import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { Slide } from '@material-ui/core';

export default function Post({ data, closePost }){
  return(
    <Dialog 
      fullScreen 
      open={!!data}
      onClose={closePost} 
      TransitionComponent={props => <Slide direction="up" {...props}/>}>
      <Container>
        <IconButton color="inherit" aria-label="Close" onClick={closePost}>
          <CloseIcon />
        </IconButton>

        <ImageContainer>
          <img src={data.imagem}/>
        </ImageContainer>

        <h1>{data.descricao}</h1>
        <h2>{data.dataHora}</h2>

        <p>{data.txtComplementar}</p>

      </Container>  
      
    </Dialog>
  )
}