import React, { useEffect, useState } from 'react';
import { CardContainer, CardFooter, CardHeader, Container, IconButton, ListContainer } from './style';
import { Icon } from 'semantic-ui-react'
import ViewDoc from "../../components/viewdoc";

import APIDocs from '../../API/APIDocs';
import Loading from '../../components/loading/Loading';
import Alert from '../../components/alert/Alert';

export default function Documentos(){
  const [docList, setDocList] = useState([]);
  const [openDoc, setOpenDoc] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [loadingError, setLoadingError] = useState(null);
  const [visibleError, setVisibleError] = useState(null);

  useEffect(() => {
    loadDocs();
  }, []);

  async function loadDocs(){
    try{
      setIsLoading(true);
      const result = await APIDocs.getDocs();

      if(result.err){
        setLoadingError(result.message);
        return;
      }

      setDocList(result);
    }catch{
      setLoadingError('Algo deu errado no carregamento dos documentos')
    }finally{
      setIsLoading(false);
    }
  }

  async function showDoc(id){
    try{
      setIsLoading(true);
      const result = await APIDocs.getBase64(id);

      if(result.err){
        setVisibleError(result.message);
        return;
      }
      
      setOpenDoc(result);
    }catch{
      setVisibleError('Algo deu errado...');
    }finally{
      setIsLoading(false)
    }
  }

  function closeDoc(){
    setOpenDoc(null);
  }

  function closeError(){
    setVisibleError(false);
  }

  return (
    <>
    <ViewDoc
      open={!!openDoc}
      file={openDoc}
      type={'pdf'} 
      close={closeDoc}
    />

    <Loading show={isLoading}/>

    <Alert 
      show={!!visibleError}
      message={visibleError}
      onClose={closeError}
    />

    <Container>
      {!loadingError ? 
        <ListContainer>
          {docList.map(item => (
            <CardContainer rejected={item.rejeitado} onClick={() => showDoc(item.arquivo)}>
              <CardHeader>
                <h1>{item.descricao}</h1>

                {!item.assinado && !item.rejeitado && false &&
                  <Icon name={'signup'} size={20}/>
                }
                
              </CardHeader>
              
              <p>Competência: {item.dtCompetencia}</p>
              <p>Assinado em: {(item.dtAssinado||"--")}</p>


              <CardFooter>
                {item.rejeitado && <p>Rejeitado</p>}
              </CardFooter>
              
            </CardContainer>
          ))}

          {docList.length === 0 && <p>Nenhum documento a ser mostrado</p>}
        </ListContainer> 
      :<p>{loadingError}, <a>tentar novamente?</a></p>}
    </Container>
    </>
  )
}