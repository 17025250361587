import React, { Component } from 'react'
import APIUser from "../../API/APIUser";
import Loading from "../../components/loading/Loading";
import { Icon } from 'semantic-ui-react';
import GenericCard, { LineGenericCard } from "../../components/genericcard"
import aux from "../../utils/auxiliar"
import { Container, EmptyListMessage } from './styles';


export default class Ferias extends Component {

  state = { opcoesFerias: [] }

  async componentDidMount() {
    aux.answerAPP();
    this.setState({ loading: true });

    const response = await APIUser.getFerias();

    this.setState({ opcoesFerias: response, loading: false })
  }

  render() {
    const { opcoesFerias } = this.state;

    const feriasMobile = opcoesFerias.map((f, index) => {
      return (
        <GenericCard
          key={index}
          footer
          footerTitle="Situação do Período Concessivo"
          footerText={f.sitPeriodoConcessivo}
        >
          <LineGenericCard
            leftTitle={<><Icon size="small" name='clock outline' className="" /> Início das Férias</>}
            leftText={f.dtInicioFerias}

            rightTitle={<><Icon size="small" name='clock outline' className="" /> Fim das Férias</>}
            rightText={f.dtFimFerias}
            rFd
            lFd
          />

          <LineGenericCard
            leftTitle={<><Icon size="small" name='clock outline' className="" /> Início Período Concessivo</>}
            leftText={f.iniPerConcessivo}
            rFd
            lFd

            rightTitle={<><Icon size="small" name='clock outline' className="" /> Fim Período Concessivo</>}
            rightText={f.fimPerConcessivo}
          />

          <LineGenericCard
            leftTitle={<><Icon size="small" name='calendar alternate outline' className="" /> Dias de Direito</>}
            leftText={f.paDiasDireito}

          />
        </GenericCard>
      )
    })

    return (
      <>
        <Loading show={this.state.loading} />
        <Container>
          {
            feriasMobile.length === 0 ? <EmptyListMessage>Nenhum dado encontrado</EmptyListMessage> :
              (
                <div className="container-cards-beneficios">
                  {feriasMobile}
                </div>
              )
          }
        </Container>
      </>
    )
  }
}
