import React, { Component } from 'react';
import PropTypes from "prop-types";
import { Icon } from 'semantic-ui-react';
import { Body, CardContainer, CardHeader, Container, InfoRow } from './style';

import { SubTitle } from '../../global/style';

class ListChamadosMobile extends Component {

	state = { dataType: "", copydata: "" };

	handleSearch = (e) => {
		this.setState({ dataType: e.target.value }, this.props.filterList(e.target.value.toUpperCase()));
	}

	render() {
		const { openDetail, data, colorLateral } = this.props;

		return (
			<Container>
				{/* {
                    this.props.filterList ? (<SearchInput value={this.state.dataType} onChange={this.handleSearch} />) : ""
                } */}
				<Body>

					{data.map((item, index) => {
						return (
							<CardContainer key={index} onClick={() => openDetail(item.chamado)} borderColor={colorLateral||"var(--azul)"}>

									<CardHeader>
										<SubTitle>{item.ocorrencia}</SubTitle>
										<span>{item.titulo}</span>
									</CardHeader>

									<span style={{color: '#999'}}>{item.acao}</span>


									<InfoRow>
										<span>{item.numeroChamado}</span>
										<span> <Icon size="small" name='clock outline' className="" />{item.data}</span>
									</InfoRow>
							</CardContainer>
						)
					})}

				</Body>

			</Container>
		)
	}
}



ListChamadosMobile.propTypes = {
	openDetail: PropTypes.func.isRequired,
	data: PropTypes.array.isRequired

}

export default ListChamadosMobile;
