import styled from "styled-components";

import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';


export const Title = styled(DialogTitle)`
  h2 {
    font-size: ${props => props.theme.fontSize.extralarge} !important;
  }
`

export const TextContent = styled(DialogContentText)`
  font-size: ${props => props.theme.fontSize.medium} !important;
`

export const ButtonsContainer = styled(DialogActions)`
  button{
    font-weight: bold;
    color : var(--azul);
  }
`