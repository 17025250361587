import React from 'react';
import { Overlay } from '../../../global/style';
import { DatePicker, DateSelectContainer } from '../../../components/datetoselect/style';


export default function DatePickerModal({ applyFilter, currentSelectedDate, closeModal }){
  const date = new Date();
  const maxDate = new Date(date.getFullYear(), date.getMonth()+1, date.getDate() - 1); 

  let selectedDate;
  if(currentSelectedDate) {
    const splitted = currentSelectedDate.split('/');
    selectedDate = new Date(splitted[2], splitted[1]-1, splitted[0]);
  }else{
    selectedDate = new Date();
  }

  

  return(
    <Overlay onClick={closeModal}>
      <DateSelectContainer >
        <DatePicker
          dateFormat={"dd/MM/yyyy"}
          onSelect={(date) => applyFilter(date)}
          minDate={new Date()}
          maxDate={maxDate}
          selected={selectedDate}
          withPortal
          inline 
        />
      </DateSelectContainer>
    </Overlay>
  )
}