import React, { Component } from 'react'
import APIUser from "../../API/APIUser";
import Loading from "../../components/loading/Loading";
import aux from "../../utils/auxiliar"
import { Icon } from "semantic-ui-react"
import DateToSelect from "../../components/datetoselect"
import GenericCard, { LineGenericCard } from "../../components/genericcard";

import { CardsContainer, EmptyListMessage, SearchContainer } from './style';

export default class Beneficios extends Component {

  state = {
    date: aux.getFirstDayOfCurrentMonth(),
    beneficios: []
  }

  async componentDidMount() {
    aux.answerAPP();
    try {
      this.setState({ loading: true })
      const beneficios = await APIUser.getBeneficios((new Date().getMonth() + 1), new Date().getFullYear());
      this.setState({ beneficios, loading: false })
    } catch (error) {
      this.setState({ loading: false })
    }

  }


  handleDate = async (date) => {
    try {
      this.setState({ loading: true });
      const beneficios = await APIUser.getBeneficios((date.getMonth() + 1), date.getFullYear());
      this.setState({ beneficios, date, loading: false });
    } catch (error) {
      this.setState({ loading: false });
    }
  }





  render() {
    const { beneficios = [] } = this.state;

    let cardsBeneficios = beneficios.map((b, index) => {
      return (

        <GenericCard key={index} title={b.tpBeneficio} footer footerTitle="Situação do benefício" footerText={b.sitBeneficio}>
          <LineGenericCard
            leftTitle={<><Icon size="small" name='clock outline' className="" /> Início do calculo</>}
            leftText={b.dtIniCalculo}


            rightTitle={<><Icon size="small" name='clock outline' className="" /> Fim do calculo</>}
            rightText={b.dtFimCalculo}

            lFd
            rFd
          />


          <LineGenericCard
            leftTitle={<><Icon size="small" name='clock outline' className="" /> Data Previsão Crédito</>}
            leftText={b.dtEnvioArquivo}

            rightTitle={<><Icon size="small" name='calculator' className="" /> Tipo de Calculo</>}
            rightText={b.tpCalculo}
            lFd
          />


          <LineGenericCard
            leftTitle={<><Icon size="small" name='user outline' className="" /> Operadora</>}
            leftText={b.operadora}
          />


          <LineGenericCard
            leftTitle={<><Icon size="small" name='calendar alternate outline' className="" /> Tipo de Geração</>}
            leftText={b.tpGeracao}

            rightTitle={<><Icon size="small" name='money bill alternate outline' className="" /> Valor Creditado</>}
            rightText={b.valorCredito}
            moneyRight

          />

        </GenericCard>


      )
    })





    return (
      <div>
        <Loading show={this.state.loading} />
        <SearchContainer>
          <DateToSelect
            onSelected={this.handleSearch}
            date={this.state.date}
            handleDate={this.handleDate}
            labelIni="Mês e Ano"
            monthAndYearOnly={true}
            showButton={false}


            onClick={this.filterData}

            className="edtPickerBeneficios"
          />
        </SearchContainer>

        {
          cardsBeneficios.length === 0 ? <EmptyListMessage>Nenhum benefício listado na data selecionada</EmptyListMessage> :
            (
              <CardsContainer>
                {cardsBeneficios}
              </CardsContainer>
            )
        }

      </div>
    )
  }
}
