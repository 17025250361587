import React from 'react';
import { Placeholder } from 'semantic-ui-react'
import { UserInfoContainer, ProfileContainer, ProfileHeader, PictureContainer, UserName, UserRE, Grid } from './style';

const CloneDadosUser = ({ show }) => {

  if (!show) {
    return null
  } else {
    return (
      <div className="" style={{ display: !show ? "none" : "block" }}>
        <UserInfoContainer>
          <Grid>
            <div>
              <ProfileContainer>

                <ProfileHeader>
                  <PictureContainer>
                    <Placeholder fluid style={{ width: "100%", height: "100%" }} />
                    {/* Aqui */}
                  </PictureContainer>
                  <UserName> </UserName>
                  <UserRE> </UserRE>
                </ProfileHeader>



              </ProfileContainer>
            </div>


            <div  style={{ marginBottom: "60px", height: "1000px", borderRadius: "5px" }} >

              {/* AQUI */}

              <Placeholder fluid style={{ width: "100%", height: "1000px" }} />

            </div>
          </Grid>
        </UserInfoContainer>
      </div>
    )
  }
};

export default CloneDadosUser;
