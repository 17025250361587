import React, { useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router';
import APINews from '../../API/APINews';
import Loading from '../../components/loading/Loading';
import Post from '../../components/post';
import Spinner from '../../components/spinner/Spinner';
import { Card, Container, Grid, ImageContainer, NewsHeader, NewsPreviewContainer, SpinnerContainer } from './style';
import NewsCard from '../../components/newsCard';



export default function Noticias(){
  const [newsList, setNewsList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [openPost, setOpenPost] = useState(null);

  const page = useRef(1);
  const ref = useRef(null);
  
  
  useEffect(() => {
    // if(location.state) setNewsList(location.state.newsList);
    // else loadNews();

  }, []);


  useEffect(() => {
    const observer = new IntersectionObserver(entries => {
      if (entries[0].isIntersecting) loadNews();
    })

    if (ref.current) {
      observer.observe(ref.current);
    }

    return () => {
      if (ref.current) {
        observer.unobserve(ref.current);
      }
    }

  }, []);


  async function loadNews(){
    try{
      setIsLoading(true);
      const result = await APINews.getNoticias(page.current);
      
      if(result.err){
        return;
      }

      page.current++;
      setNewsList(prev => [...prev, ...result]);
    }catch(e){
      console.log(e);
    }finally{
      setIsLoading(false);
    }
  }



  function openNews(post){
    setOpenPost(post);
  }

  function closePost(){
    setOpenPost(null);
  }




  return(
    <>
    {!!openPost && 
      <Post 
        data={openPost}
        closePost={closePost}
      />
    }
    <Container>
      {newsList.length === 0 && <Loading show={isLoading}/>}
      <Grid >
      {newsList.map((item, index) => (
        <NewsCard news={item} />
      ))}

      <div ref={ref} />

      </Grid>
      {newsList.length > 0 && isLoading &&
        <SpinnerContainer>
          <Spinner color={'#000'}/>
        </SpinnerContainer> 
        
      }

    </Container>
    </>
  )
}
