import axios from 'axios';
import aux from '../utils/auxiliar';
import storage from '../storage/index';

const APINews = {
  getNoticias: async function (page) {

    const urlClient = storage.getUrlClient();

    try {
      const header = aux.getHeaderPadrao();
      const response = await axios.get(`${urlClient}/postsNoticias/${page}`, { headers: header, timeout: 60000 });


      const { err, message } = aux.trataErroAPI(response);
    
      if (!err) {
        return response.data.postsNoticias;
      } else {
        return { error: true, message}
      }

    } catch (error) {

      return { error: true };
    }
  }
}


export default APINews;