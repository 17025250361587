import React from 'react';
import PropTypes from "prop-types";
import Spinner from '../spinner/Spinner';
import { Container } from './style';

const Loading = props => {
    if (props.show) {
      return (
        <Container noTransparent={props.notransparent}>
          <Spinner />
        </Container>
      )
    }else{
      return null;
    }

}


Loading.propTypes = {
    show : PropTypes.bool
}

export default Loading;