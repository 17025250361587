import axios from 'axios';
import aux from '../utils/auxiliar';
import storage from '../storage/index';

const APIDocs = {
  getDocs: async function (relacaoEntidade) {
   const urlClient = storage.getUrlClient();
    try {
    
      const header = aux.getHeaderPadrao();

      const strRequest = `DocumentosDisponiveis/${relacaoEntidade||''}`;

      const response = await axios.get(`${urlClient}/${strRequest}`, { headers: header, timeout: 60000 });

      const {err, message} = aux.trataErroAPI(response);
      if (!err) {
        return response.data.documentos;
      } else {

        return { err: true, message}
      }
    } catch (error) {
      return { err: true };
    }
  },

  getBase64: async function (id) {

    const urlClient = storage.getUrlClient();

    try {
      const header = aux.getHeaderPadrao();

      const response = await axios.get(`${urlClient}/Documento/${id}`, { headers: header, timeout: 60000 });

      const {err, message} = aux.trataErroAPI(response)

      if (!err) {
        return response.data.base64Doc;
      } else {

        return { error: true, message }
      }
    } catch (error) {
      return { error: true };
    }
  }
}

export default APIDocs;