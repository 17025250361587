import styled from "styled-components"
import { FadeInAnimation } from "../../global/style"


export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-wrap: nowrap;
  position: relative;
  width: 100%;
  
  background: #fff;
  margin-bottom: 20px;
  border-radius: 0;
  padding: 10px 10px 0;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.21);
`

export const Header = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  border-bottom: .35px solid #A9AEB8;
  align-items: center;
  padding-bottom: 10px;

  h6 {
    text-transform: uppercase;
    letter-spacing: 0.5px;
    font-size: ${props => props.theme.fontSize.small};
    color: #343a40;
    padding: 0;
    text-align: left;
    padding-left: 10px;
  }
`

export const NavigatorContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 40px;

  i {
    color: var(--azul);
    cursor: pointer;
  }
`

export const Body = styled.div`
  width: 100%;
  padding-bottom: 5px;

  
`

export const EspelhoPontoTable = styled.table`
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;

  th {
    border-top: 0;
    border-bottom: 0;
    font-weight: 400;
    font-size: ${props => props.theme.fontSize.small};
    text-transform: uppercase;
    color: #343a40;
    letter-spacing: 0.5px;
    padding: 0.75rem;
    vertical-align: top;
  }

  td {
    padding: 0.75rem;
    vertical-align: middle;
    border-top: 1px solid #dee2e6;
    cursor: pointer;
    transition: all .4s;
  }

  td:hover {
    background: #dee2e6;
  }

  

  @media (min-width: 320px) and (max-width: 990px) {
    td{
      vertical-align: middle;
      padding: .48em;
    }
  }

`

export const TableBody = styled.tbody`
  ${props => props.fadeIn && FadeInAnimation}
`


export const TableRow = styled.tr`
  font-size: ${props => props.theme.fontSize.smallernormal} !important;
  td a {
    text-decoration: none;
  }

  .dateColumn{
    font-weight: 400;
  }

  @media (min-width: 320px) and (max-width: 1400px) {
    .dateColumn{
      font-size: ${props => props.theme.fontSize.extrasmall};
    }

  }

`