import React from 'react';
import Sac from '../sac/Sac';
import PontoPage from "../pontopage/PontoPage";
import DadosUser from "../dadosuser";
import Ferias from "../ferias";
import Beneficios from "../beneficios";
import CursosDisponiveis from "../cursosdisponiveis"
import HistoricoCursos from "../historicocursos";
import { RequireAuth } from "./RequireAuth";
import Home from '../home';
import Noticias from '../noticias';
import Documentos from '../documentos';
import Monitoramento from '../monitoramento';
import HiperLinks from '../hiperlinks';

const routes = [

    {
        path: "/home",
        exact: true,
        sidebar: () => <div>home!</div>,
        main: () => <h2>Home</h2>,
        component: RequireAuth(Home, 18560, "/home")

    },
    {
        path: "/sac",
        sidebar: () => <div></div>,
        main: () => <h2>Sac</h2>,
        component: RequireAuth(Sac, 18560, "/sac")
    },

    {
        path: "/ponto",
        component: RequireAuth(PontoPage, 18570, "/ponto")
    },
    {
        path: "/dadosuser",
        component: RequireAuth(DadosUser, 18569, "/dadosuser")
    },
    {
        path: "/ferias",
        component: RequireAuth(Ferias, 18561, "/ferias")
    },
    {
        path: "/beneficios",
        component: RequireAuth(Beneficios, 18563, "/beneficios")
    },
    {
        path: "/cursosdisponiveis",
        component: RequireAuth(CursosDisponiveis, 18562, "/cursosdisponiveis")
    },
    {
        path: "/historicocursos",
        component: RequireAuth(HistoricoCursos, 18562, "/historicocursos")
    },
    {
        path: "/noticias",
        component: RequireAuth(Noticias, 18701, "/noticias")
    },

    {
        path: "/documentos",
        component: RequireAuth(Documentos, 18693, "/documentos")
    },
    {
        path: "/monitoramentofuncionario",
        component: RequireAuth(Monitoramento, 18685, "/documentos")
    },
    // {
    //     path: "/hiperlinks",
    //     component: RequireAuth(HiperLinks, 18685, "/hiperlinks")
    // }

];



export default routes;